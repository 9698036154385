import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FallingLines } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';


export default function ForgetPasswordPhone() {

    let navigate = useNavigate()
    const [error, setError] = useState(null)
    const [logging, setLogging] = useState(false)

    async function submitLogin(value) {

        try {

            setLogging(true)

            let { data } = await axios.post(`${process.env.REACT_APP_BACK_END}auth/forgetPasswordPhone`, value)
                .catch((err) => {
                    setError(err.response.data.message)
                    setLogging(false)
                })

            console.log(data);

            if (data.message === 'success') {
                setLogging(false)

                toast.success("Changed password successfully")

                navigate('/login')
            }

        } catch (error) {

        }

    }

    let validateSchema = Yup.object({
        countryCode: Yup.string().required("country code is required"),
        phoneNumber: Yup.string().required("phone Number is required"),
        password: Yup.string().min(6, 'Password must with character and minLength 6').required('password is required'),
    })

    let formik = useFormik({
        initialValues: {
            countryCode: 'EG',
            phoneNumber: '',
            password: '',
        }, validationSchema: validateSchema,
        onSubmit: submitLogin
    })


    // to show text whe type password
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Sample country code data
    const countryCodes = [
        { code: '+20', countryCode: 'EG', country: 'Egypt' },
        { code: '+971', countryCode: 'AE', country: 'United Arab Emirates' },
        { code: '+1', countryCode: 'US', country: 'United States' },
        { code: '+7', countryCode: 'RU', country: 'Russia' },
        { code: '+33', countryCode: 'FR', country: 'France' },
        { code: '+44', countryCode: 'GB', country: 'United Kingdom' },
        { code: '+49', countryCode: 'DE', country: 'Germany' },
        { code: '+81', countryCode: 'JP', country: 'Japan' },
        { code: '+86', countryCode: 'CN', country: 'China' },
        { code: '+91', countryCode: 'IN', country: 'India' },
        { code: '+92', countryCode: 'PK', country: 'Pakistan' },
        { code: '+254', countryCode: 'KE', country: 'Kenya' },
        { code: '+355', countryCode: 'AL', country: 'Albania' },
        { code: '+213', countryCode: 'DZ', country: 'Algeria' },
        { code: '+376', countryCode: 'AD', country: 'Andorra' },
        { code: '+244', countryCode: 'AO', country: 'Angola' },
        { code: '+672', countryCode: 'AQ', country: 'Antarctica' },
        { code: '+54', countryCode: 'AR', country: 'Argentina' },
        { code: '+374', countryCode: 'AM', country: 'Armenia' },
        { code: '+297', countryCode: 'AW', country: 'Aruba' },
        { code: '+61', countryCode: 'AU', country: 'Australia' },
        { code: '+43', countryCode: 'AT', country: 'Austria' },
        { code: '+994', countryCode: 'AZ', country: 'Azerbaijan' },
        // Add more country codes as needed
    ];


    return <>

        <Helmet>
            <meta name='Forget Password Phone' content='Forget Password Phone Couponesta Page' charSet="utf-8" />
            <title>Forget Password Phone Couponesta</title>
        </Helmet>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

            <form className="space-y-6" onSubmit={formik.handleSubmit}>


                <div className="flex justify-between align-middle gap-x-2">

                    <div className='w-52'>
                        <label htmlFor="countryCode" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                            Country Code
                        </label>
                        <div className="mt-2">
                            <select
                                id="countryCode"
                                name="countryCode"
                                value={formik.values.countryCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                className="block w-full rounded-md border-0 py-1.5 font-semibold cursor-pointer text-gray-900 px-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                            >
                                <option value="" disabled>Select Country Code</option>
                                {countryCodes.map((country, index) => (
                                    <option key={index} value={country.countryCode}>{country.country} ({country.code})</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                            phone number
                        </label>
                        <div className="mt-2">
                            <input
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.phoneNumber}
                                id='phoneNumber'
                                name='phoneNumber'
                                type='text'
                                autoComplete="phoneNumber"
                                className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                        </div>
                    </div>
                </div>

                {formik.errors.phoneNumber && formik.touched.phoneNumber ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.phoneNumber}</div> : ''}
                {formik.errors.countryCode && formik.touched.countryCode ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.countryCode}</div> : ''}


                <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                        Enter New Password
                    </label>

                    <div className="mt-2 relative">

                        <i
                            className={`absolute right-2 top-2.5 ${showPassword ? "fa-solid fa-eye text-gray-700 cursor-pointer"
                                : "fa-solid fa-eye-slash cursor-pointer"}`}
                            onClick={togglePasswordVisibility}
                        ></i>

                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.password}</div> : ''}

                    </div>
                </div>

                {logging ? <button type='button' className='flex w-full justify-center rounded-md'>

                    <FallingLines
                        width="50"
                        radius="9"
                        color="#3b35b4"
                        visible={true}
                        ariaLabel="falling-circles-loading"
                    />
                </button> :
                    <div>
                        <button

                            // disabled
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="flex w-full justify-center cursor-pointer rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-indigo-400 disabled:text-gray-200 text-white shadow-sm active:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Confirm Change New Password
                        </button>
                    </div>
                }

                {error ? <div className="py-3 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}


            </form>

        </div>
    </>

}
