import React from 'react'
import notFound from '../../Assets/images/error.svg';
import { Helmet } from 'react-helmet';

export default function Notfound() {
    return <>

        <Helmet>
            <meta name='Error' content='Error Couponesta Page' charSet="utf-8" />
            <title>Error Couponesta</title>
        </Helmet>

        <div className="mx-auto mt-44 w-fit h-fit flex-row justify-center align-middle">
            <img src={notFound} alt="not found photo" className='w-100' />

            <h2 className='text-center mt-10 font-bold text-green-700'>Error You can return a Home Page use Nav Bar</h2>
        </div>
    </>
}
