import { createContext, useState } from "react";

export let UserContext = createContext()


export default function UserContextProvider(props) {

    const [refresh_token_website, setRefresh_token_website] = useState(null)
    const [userData, setUserData] = useState(null)


    return <UserContext.Provider value={{ refresh_token_website, setRefresh_token_website, setUserData, userData }}>
        {props.children}
    </UserContext.Provider>
}
