import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from 'react-query'
import { BallTriangle, MutatingDots } from 'react-loader-spinner';
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';


export default function FollowPage() {
    const { t, i18n } = useTranslation();
    let lng = cookies.get("i18next") || "en"
    let isRtl = document.documentElement.dir === 'rtl';

    async function getLoggedUser() {

        try {

            if (localStorage.getItem('refresh_token_website') != null &&
                localStorage.getItem('refresh_token_website') != undefined &&
                localStorage.getItem('refresh_token_website').trim() != '') {

                try {

                    let response = await axios.get(`${process.env.REACT_APP_BACK_END}user/loggedUser/data`, {
                        headers: {
                            authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                        }
                    });

                    return response?.data;

                } catch (error) {

                }

            } else {

                navigate('/login');
            }


        } catch (error) {

        }
    }

    let brandData = useQuery(['getLoggedUserFollow'], () => getLoggedUser(), {
        cacheTime: 9000000
    })


    let allBrand = []

    if (brandData?.data?.message === 'success') {

        allBrand = brandData?.data?.user[0]?.follow
    }

    const navigate = useNavigate();

    let tokenInUser = localStorage.getItem('refresh_token_website');
    let decoded = {};
    if (tokenInUser) {
        decoded = jwtDecode(localStorage.getItem('refresh_token_website'));
    }

    const [loadingBrands, setLoadingBrands] = useState({});

    const handleFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await brandData?.refetch();
                toast.success(t("AddFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };

    const handleRemoveFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await brandData?.refetch();
                toast.success(t("RemoveFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };

    return <>

        <Helmet>
            <meta name='Follow' content='Follow Couponesta Page' charSet="utf-8" />
            <title>Follow Couponesta</title>
        </Helmet>

        <div className="container w-full ">

            <div className="flex flex-row justify-between items-center w-full">

                <h2 className='xl:text-xl text-lg  font-bold md:text-header-5'><span className='text-teal-600 font-extrabold me-1'>{allBrand?.length}</span> {t("FavoriteStoreTitle")}</h2>

                <Link
                    to={'/store'}
                    className="flex items-center text-nowrap justify-normal text-center mx-auto cursor-pointer border-2 border-neutral-400 rounded-3xl ltr:mr-0 rtl:me-0 md:rtl:mr-6 md:ltr:mr-6 py-1 px-3  text-black bg-white hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">
                    <button className='font-semibold'>
                        {t("ExploreMoreTitle")}
                    </button>
                </Link>
            </div>

            <div>
                {
                    brandData?.isLoading ? (
                        <div className="loading">
                            <BallTriangle
                                height={100}
                                width={100}
                                radius={5}
                                color="#199A8E"
                                ariaLabel="ball-triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : allBrand.length === 0 ? (
                        <div className='flex justify-center align-middle p-12 text-teal-800'>
                            <h1 className='font-bold'>{t("NoFoundFavBrandTitle")}</h1>
                        </div>
                    ) : <div className="grid place-items-center grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 gap-x-3 gap-y-2 mt-5">

                        {allBrand.map((brand, i) => {

                            return <div key={i}
                                className="px-4 py-5 mb-6 md:flex cursor-pointer items-center justify-between rounded-3xl border border-neutral-300 bg-white w-full">
                                <Link to={`/store/${brand._id}`} className="flex flex-row justify-start items-center">

                                    <img src={`${process.env.REACT_APP_BACK_END}${brand.image}` || brand} alt="brand img" className='w-20 rounded-full border border-neutral-200 ltr:mr-3 rtl:ml-3' />

                                    <div className="ms-3">
                                        <h4 className="mb-2 font-head text-base font-bold text-neutral-1000">{isRtl ? brand?.name?.ar : brand?.name?.en}</h4>
                                        <div className="flex items-center">
                                            <p className="text-sm font-normal text-neutral-650 line-clamp-1">{isRtl ? brand?.description?.ar : brand?.description?.en}</p>
                                        </div>
                                    </div>

                                </Link>

                                {
                                    loadingBrands[brand._id] ?
                                        <MutatingDots
                                            visible={true}
                                            height="100"
                                            width="100"
                                            color="#1a56db"
                                            secondaryColor="#1a56db"
                                            radius="12.5"
                                            ariaLabel="mutating-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                        :
                                        <div className="flex items-center mt-3 md:mt-0"
                                            onClick={() => handleRemoveFollow(brand?._id)}
                                        >
                                            <div className="flex items-center justify-center md:w-40 ms-1 py-3 px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-white bg-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                <i className="fa-solid fa-star"></i>
                                                <button className='ms-1'>
                                                    {t('IsFollowTitle')}
                                                </button>
                                            </div>

                                        </div>

                                }


                            </div>
                        })}

                    </div>
                }

            </div>


        </div>
    </>
}
