import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from 'react-query'
import { BallTriangle, MutatingDots } from 'react-loader-spinner'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast'
import { Helmet } from 'react-helmet'


export default function AllStores() {

    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"


    let locDetails = JSON.parse(localStorage.getItem('locCouponesta'))

    async function getBrand() {

        let response = await axios.get(`${process.env.REACT_APP_BACK_END}brand/${lng}?location=${locDetails._id}`)
        return response
    }

    let brandData = useQuery(['brand', lng], () => getBrand(), {
        cacheTime: 9000000
    })

    let allBrand = []

    if (brandData?.data?.data?.message === "success") {

        allBrand = brandData?.data?.data?.brands
    }

    const navigate = useNavigate();

    let tokenInUser = localStorage.getItem('refresh_token_website');
    let decoded = {};
    if (tokenInUser) {
        decoded = jwtDecode(localStorage.getItem('refresh_token_website'));
    }

    const [loadingBrands, setLoadingBrands] = useState({});

    const handleFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await brandData?.refetch();
                toast.success(t("AddFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };

    const handleRemoveFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await brandData?.refetch();
                toast.success(t("RemoveFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };


    return <>

        <Helmet>
            <meta name='All Store' content='All Store Couponesta Page' charSet="utf-8" />
            <title>All Store Couponesta</title>
        </Helmet>

        <div className="container px-5 md:px-10 xl:px-40 mt-2 mb-4 py-10 ">

            <div className="flex flex-row justify-between items-center w-full">

                <h2 className='xl:text-xl text-lg  font-bold md:text-header-5'>{t("DiscoverMoreStoresTitle")}</h2>

                <Link
                    to={'/store'}
                    className="flex items-center text-nowrap justify-normal text-center mx-auto cursor-pointer border-2 border-neutral-400 rounded-3xl ltr:mr-6 rtl:me-6 py-1 px-3  text-black bg-white hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">
                    <button className='font-semibold'>
                        {t("ViewAllTitle")}
                    </button>
                </Link>
            </div>

            <div>
                {
                    brandData?.isLoading ? (
                        <div className="loading">
                            <BallTriangle
                                height={100}
                                width={100}
                                radius={5}
                                color="#199A8E"
                                ariaLabel="ball-triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : allBrand?.length === 0 ? (
                        <div className='flex justify-center align-middle p-12 text-teal-800'>
                            <h1 className='font-bold'>{t("NoFoundBrandTitle")}</h1>
                        </div>
                    ) : <div className="grid place-items-center px-5 xl:px-0 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-2 mt-5">

                        {allBrand?.map((brand, i) => {

                            return <div key={i}
                                className="px-4 py-5 mb-6 flex cursor-pointer items-center justify-between rounded-3xl border border-neutral-300 bg-white w-full">
                                <Link to={`/store/${brand._id}`} className="flex flex-row justify-start items-center">

                                    <img src={brand.image || brand} alt="brand img" className='w-[60px] basis-1/2 rounded-full border border-neutral-100 ltr:mr-3 rtl:ml-3' />

                                    <div className="ms-3">
                                        <h4 className="mb-2 font-head text-base font-bold text-neutral-1000">{brand?.name}</h4>
                                        <div className="flex items-center">
                                            <p className="text-sm font-normal text-neutral-650 line-clamp-2">{brand?.description}</p>
                                        </div>
                                    </div>

                                </Link>

                                {
                                    loadingBrands[brand._id] ?
                                        <MutatingDots
                                            visible={true}
                                            height="100"
                                            width="100"
                                            color="#1a56db"
                                            secondaryColor="#1a56db"
                                            radius="12.5"
                                            ariaLabel="mutating-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                        :
                                        brand?.userFollowed.includes(decoded?.id) ?
                                            <div className="flex items-center"
                                                onClick={() => handleRemoveFollow(brand?._id)}
                                            >
                                                <div className="flex items-center justify-center w-40 ms-1 py-3 px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-white bg-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                    <i className="fa-solid fa-star"></i>
                                                    <button className='ms-1'>
                                                        {t('IsFollowTitle')}
                                                    </button>
                                                </div>

                                            </div>
                                            :
                                            <div className="flex items-center"
                                                onClick={() => handleFollow(brand?._id)}
                                            >
                                                <div className="flex items-center justify-center ms-2 py-2.5 px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                    <i className="fa-regular fa-star"></i>
                                                    <button className='ms-1'>
                                                        {t('FollowTitle')}
                                                    </button>
                                                </div>

                                            </div>

                                }


                            </div>
                        })}

                    </div>
                }

            </div>


        </div>
    </>
}
