import React from 'react'
import { Link, useParams } from 'react-router-dom'

export default function ConfirmationEmail() {

    let { email } = useParams()

    return <>

        <div className="px-10 md:px-2 w-full h-screen bg-neutral-100 container flex align-middle justify-center items-center">

            <div className="">
                <div className="mx-auto text-center w-full pb-10 xl:pb-14 pt-1">
                    <Link to={'/forgetPassword'} className='cursor-pointer px-2 py-1 border-2 border-rose-600 hover:border-rose-700 rounded-lg hover:text-orange-800 text-orange-700 text-2xl font-bold'>Back</Link>
                </div>

                <div className="flex justify-center items-center text-center">
                    <div className='font-extrabold text-base text-balance md:text-2xl text-teal-600 rounded-lg py-4 px-5 border-2 border-dashed border-teal-600'>

                        <h1 className='mb-3'>Plz Check Your Email to continue Change Password</h1>

                        <Link to={`https://mail.google.com/mail/${email}`} target='_blank' className='text-blue-600 hover:text-blue-800 focus:text-blue-800 underline'>Can U click Hear to Go Email</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}
