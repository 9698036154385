import React, { useEffect } from 'react'
import logo from '../../Assets/images/couponesta.jpg'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import LoginWithPhone from '../LoginWithPhone/LoginWithPhone.jsx';
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Login() {

    const { t, i18n } = useTranslation();

    if (cookies.get('i18next') === "dev") {
        cookies.set('i18next', 'en');
    }

    let lng = cookies.get("i18next") || "en"

    useEffect(() => {
        window.document.dir = i18n.dir()
    }, [lng])

    // language 
    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language || 'en')
    };

    const navigate = useNavigate();

    const location = useLocation();

    const isActiveEmail = location.pathname === '/login/loginWithEmail';

    let isRtl = document.documentElement.dir === 'rtl';

    async function LoginOrSignUpWithGoogle() {
        try {
            window.location.href = `${process.env.REACT_APP_BACK_END}auth/google`;
        } catch (error) {
            navigate('/Login')
        }
    }


    return <>

        <Helmet>
            <meta name='Login' content='Login Couponesta Page' charSet="utf-8" />
            <title>Login Couponesta</title>
        </Helmet>

        <div className="relative grid max-w-full grid-cols-1 md:grid-cols-2 overflow-x-hidden ">

            {/* one side */}
            <div className="w-full py-12 bg-white container px-8 xl:px-20">

                <header className='flex justify-between items-center'>
                    <Link to={'/'}><img src={logo} alt="" className='w-16 rounded-md' /></Link>
                    {
                        lng === "en" ?
                            <button
                                onClick={() => handleLanguageChange('ar')}
                                className='py-1.5 px-2.5 bg-gray-200 rounded-md border-2 border-gray-300 items-center flex'
                            >
                                <span className={`fi fi-sa text-xl rounded-md me-2`}></span>
                                <span>{t("languageAr")}</span>
                            </button>
                            :
                            <button
                                onClick={() => handleLanguageChange('en')}
                                className='py-1.5 px-2.5 bg-gray-200 rounded-md border-2 border-gray-300 flex items-center'
                            >
                                <span className={`fi fi-um text-xl rounded-md me-2`}></span>
                                <span>{t("languageEn")}</span>
                            </button>
                    }

                </header>

                <div className="mt-20 md:mt-32 text-center md:text-start">
                    <h1 className='text-xl xl:text-3xl font-bold md:font-extrabold'>{t("GetStartedTitle")}</h1>
                    <p className='mt-1'>{t("SelectedTitle")}</p>
                </div>

                {/* register with Email or Phone */}
                <div className="my-9">
                    <Outlet>
                        <LoginWithPhone />
                    </Outlet>
                </div>

                <div className="my-9 flex flex-row items-center justify-center">
                    <div className="w-[50%] border-[1px] border-solid border-neutral-300"></div>
                    <div className="px-1 text-body-5 text-neutral-500">{isRtl ? 'أو' : 'Or'}</div>
                    <div className="w-[50%] border-[1px] border-solid border-neutral-300"></div>
                </div>

                {/* buttons */}
                <div className="max-full flex flex-col items-center justify-center gap-4">


                    <Link to={`${isActiveEmail ? '/login' : '/login/loginWithEmail'}`}
                        className='relative flex items-center justify-center text-center w-full border-[1px] border-neutral-400 rounded-3xl py-3 font-bold'
                    >
                        {
                            isActiveEmail ?
                                <i className="absolute left-5 fa-solid fa-mobile-screen-button text-2xl"></i>
                                :
                                <i className="absolute left-5 fa-regular fa-envelope text-2xl"></i>
                        }

                        {lng === "en"
                            ?
                            (isActiveEmail ? 'Continue With Phone' : 'Continue With Email')
                            :
                            (isActiveEmail ? 'متابعة باستخدام رقم الهاتف' : 'متابعة باستخدام البريد الإلكتروني')
                        }

                    </Link>

                    {/* button google */}
                    <button
                        onClick={LoginOrSignUpWithGoogle}
                        className='relative flex items-center justify-center text-center w-full border-[1px] border-neutral-400 rounded-3xl py-3 font-bold'
                    >
                        <i className="absolute left-5 fa-brands fa-google text-2xl bg-gradient-to-r from-teal-500 via-green-400 to-blue-600 inline-block text-transparent bg-clip-text"></i>
                        {t("loginGoogle")}
                    </button>

                    {/* button Apple */}
                    {/* <button
                        className='relative flex items-center justify-center text-center w-full border-[1px] border-neutral-400 rounded-3xl py-3 font-bold'
                    >
                        <i className="absolute left-5 fa-brands fa-apple text-2xl bg-gradient-to-r from-teal-900 via-green-500 to-blue-400 inline-block text-transparent bg-clip-text"></i>
                        {t("loginApple")}
                    </button> */}

                </div>
                {/* end buttons */}

                <div className="w-full flex items-center justify-center my-8">
                    <h1>{t("DonotHaveAcc")} </h1>
                    <Link to={'/register'} className='font-bold text-yellow-300 ms-1'>{t("RegisterTitle")}</Link>
                </div>

                <div className="w-full text-center text-balance mt-16">
                    {lng === "en" ? 'By creating an account, I agree to the ' : 'من خلال إنشاء حساب، فإنني أوافق علي '}
                    <span><Link to={'/privacy-policy'} className='font-bold text-yellow-300 ms-1'>{lng === "en" ? ' Privacy Policy' : 'سياسة الخصوصية '} </Link></span>

                    <br />
                    {lng === "en" ? 'and the' : 'و'}
                    <span><Link to={'/terms-and-conditions'} className='font-bold text-yellow-300 ms-1'>{lng === "en" ? 'Terms and Conditions' : 'شروط وأحكام'} </Link></span>
                    {lng === "en" ? 'of Couponesta' : 'كوبونستا'}
                </div>

            </div>

            {/* tow side */}
            <div className="hidden md:block container px-8 xl:px-20 bac-img-register">

                <div className="w-full text-center mt-[50%]">
                    <h1 className='text-3xl font-extrabold'>{t("TowSideTitle1")}</h1>

                    <p className='mt-4 text-balance'>{t("TowSideTitle2")}</p>
                </div>

            </div>

        </div>
    </>
}
