import React from 'react'
import about from '../../Assets/images/about.jpg'
import google from '../../Assets/images/google icon.png'
import apple from '../../Assets/images/apple.jpg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

export default function About() {

    const { i18n } = useTranslation();

    let lng = i18n.language

    return <>

        <Helmet>
            <meta name='About' content='About Couponesta Page' charSet="utf-8" />
            <title>About Couponesta</title>
        </Helmet>

        <div className="flex w-full max-w-[100vw] flex-col gap-20 overflow-x-hidden bg-[#F2F3F7] pb-[100px] pt-[100px]">
            <div className="relative z-0 w-full">
                <div className="absolute -top-32 left-1/2 -translate-x-1/2">
                    <svg className="hidden -translate-y-24 sm:block" width="1920" height="770" viewBox="0 0 1920 770" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2416 -314C2416 290.2 1764.13 770 960 770C155.873 770 -496 290.2 -496 -314L960 -314L2416 -314Z" fill="#2761FF"></path></svg>
                    <svg className="block sm:hidden" width="590" height="427" viewBox="0 0 430 427" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M616 3.60637e-05C616 238.001 436.69 427 215.5 427C-5.69005 427 -185 238.001 -185 3.60637e-05L215.5 0L616 3.60637e-05Z" fill="#2761FF"></path></svg>
                </div>

                <div className="container relative z-10 mx-auto flex flex-col items-center justify-center gap-10 pt-20">
                    <h1 className="mb-10 text-center text-4xl text-white md:text-[68px] px-1">{lng != "en" ? "كوبونستا .. وفر وقتك وفلوسك!" : "Couponesta .. Saves Time & Money"}</h1>
                    <img src={about} className='w-3/4 h-3/4 rounded-3xl rounded-t-full' />
                </div>

                <div className="mt-8">
                    <h6 className="mb-8 text-center text-xl xl:text-2xl font-semibold">Get The App</h6>
                    <div className="flex flex-col items-center justify-center gap-6 md:flex-row">
                        <Link>
                            <img src={google} alt="" className='h-14 hover:shadow-2xl rounded-2xl border-2 border-teal-800 ' />
                        </Link>

                        <Link>
                            <img src={apple} alt="" className='h-14 hover:shadow-2xl rounded-2xl border-2 border-teal-800 ' />
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    </>
}
