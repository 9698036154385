import React from 'react'
import Ads from '../Ads/Ads.jsx'
import CouponsFromOtherStores from '../CouponsFromOtherStores/CouponsFromOtherStores.jsx'
import FeaturedStores from '../FeaturedStores/FeaturedStores.jsx'
import RecentlyAdded from '../RecentlyAdded/RecentlyAdded.jsx'
import HighestDiscountsCoupons from '../HighestDiscountsCoupons/HighestDiscountsCoupons.jsx'
import DiscoverCategories from '../DiscoverCategories/DiscoverCategories.jsx'
import AllCoupons from '../AllCoupons/AllCoupons.jsx'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Home() {

    const { t, i18n } = useTranslation();


    const location = useLocation();
    let navigate = useNavigate()
    // Parse the query string
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('refresh_token')) {

        const refreshToken = queryParams.get('refresh_token');
        localStorage.setItem('refresh_token_website', refreshToken);

        navigate('/');  // Redirect to the home page or another page
    }


    return <>

        <Helmet>
            <meta name='Couponesta' content='Couponesta Page' charSet="utf-8" />
            <title>Couponesta</title>
        </Helmet>

        <div className=" container">
            {/* Ads */}
            <Ads />

            <div className="mt-2">


                {/* most use coupon */}
                <CouponsFromOtherStores nameContent={t("MostCouponTitle")} />

                {/* Featured Stores */}
                <FeaturedStores />

                {/* Recently Added Coupon */}
                <RecentlyAdded nameContent={t('RecentlyAddedTitle')} />

                {/* Highest Discounts Coupons */}
                <HighestDiscountsCoupons nameContent={t("DiscountCouponTitle")} />

                {/* Discover By Categories */}
                <DiscoverCategories />

                {/* All Coupons */}
                <AllCoupons />

            </div>
        </div>

    </>
}
