import React, { useEffect, useRef, useState } from 'react'
import brand from '../../Assets/images/brand1.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { BallTriangle, FallingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';

import {
    FacebookMessengerShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { Helmet } from 'react-helmet';


export default function AllCoupons() {

    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    // copy to clipboard
    const [copied, setCopied] = useState(false);

    const handleCopy = async (couponId) => {
        try {
            await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/addUseCount`);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        } catch (error) {
            // console.error("Error copying coupon code:", error);
        }
    };

    // icons
    const [showIcons, setShowIcons] = useState(false);

    const toggleIcons = () => {
        setShowIcons(!showIcons);
    };


    async function getCoupons() {

        return await axios.get(`${process.env.REACT_APP_BACK_END}coupon/${lng}`)
    }

    let { isLoading, data, refetch } = useQuery(['AllCoupons', lng], () => getCoupons(), {
        cacheTime: 9000000
    })

    let allCoupons = []

    if (data?.data?.message === "success") {

        allCoupons = data?.data?.coupons
    }


    const navigate = useNavigate();

    let tokenInUser = localStorage.getItem('refresh_token_website');
    let decoded = {};
    if (tokenInUser) {
        decoded = jwtDecode(localStorage.getItem('refresh_token_website'));
    }

    // user favorite coupon
    const [selectedCoupon, setSelectedCoupon] = useState(null)
    const [addFavorite, setAddFavorite] = useState(false);
    const [addFavoriteLoading, setAddFavoriteLoading] = useState(false);
    const [userFavorites, setUserFavorites] = useState([]);

    // user Like coupon
    const [addLike, setAddLike] = useState(false);
    const [addDisLike, setAddDisLike] = useState(false);
    const [addLikeLoading, setAddLikeLoading] = useState(false);
    const [addDisLikeLoading, setAddDisLikeLoading] = useState(false);
    const [userLiked, setUserLiked] = useState([]);
    const [userDisLiked, setUserDisLiked] = useState([]);
    const [countLiked, setCountLiked] = useState(0);
    const [countDisLiked, setCountDisLiked] = useState(0);

    useEffect(() => {
        const fetchSingleCoupon = async () => {
            if (selectedCoupon) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACK_END}coupon?_id=${selectedCoupon._id}`);
                    // console.log(response?.data?.coupons[0]);
                    const favorites = response?.data?.coupons[0]?.userFavorite || [];
                    const Likes = response?.data?.coupons[0]?.userLiked || [];
                    const DisLikes = response?.data?.coupons[0]?.userDisLiked || [];
                    setUserFavorites(favorites);
                    setUserLiked(Likes)
                    setUserDisLiked(DisLikes)
                    setCountLiked(response?.data?.coupons[0]?.likeCount)
                    setCountDisLiked(response?.data?.coupons[0]?.dislikeCount)

                } catch (error) {
                    // console.error("Error fetching single coupon data:", error);
                }
            }
        };

        fetchSingleCoupon();
    }, [selectedCoupon, userDisLiked, userLiked]);

    useEffect(() => {
        if (userFavorites.includes(decoded?.id)) {
            setAddFavorite(true);
        } else {
            setAddFavorite(false);
        }

        if (userLiked.includes(decoded?.id)) {
            setAddLike(true);
        } else {
            setAddLike(false);
        }

        if (userDisLiked.includes(decoded?.id)) {
            setAddDisLike(true);
        } else {
            setAddDisLike(false);
        }
    }, [userFavorites, userLiked, userDisLiked, decoded]);

    const handleFavorite = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFavoriteLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/favorite/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserFavorites((prev) => [...prev, decoded.id]);
                await refetch();
                toast.success(t("AddFavoriteTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to favorite", error);
        } finally {
            setAddFavoriteLoading(false);
        }
    };

    const handleRemoveFavorite = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFavoriteLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/favorite/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserFavorites((prev) => prev.filter((id) => id != decoded.id));
                await refetch();
                toast.success(t("RemoveFavoriteTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while removing from favorite", error);
        } finally {
            setAddFavoriteLoading(false);
        }

    };

    // user Like coupon
    const handleLike = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddLikeLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/like/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserLiked((prev) => prev.filter((id) => id != decoded.id));
                await refetch();
                toast.success(t("AddLikedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to favorite", error);
        } finally {
            setAddLikeLoading(false);
        }
    };

    const handleDisLike = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddDisLikeLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/like/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserDisLiked((prev) => [...prev, decoded.id]);
                await refetch();
                toast.success(t("AddDisLikedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to favorite", error);
        } finally {
            setAddDisLikeLoading(false);
        }
    };


    // console.log(countLiked);
    // console.log(countDisLiked);


    // user followed brand
    const [addFollowed, setAddFollowed] = useState(false);
    const [addFollowedLoading, setAddFollowedLoading] = useState(false);
    const [userFollowed, setUserFollowed] = useState([]);


    useEffect(() => {
        const fetchSingleBrand = async () => {
            if (selectedCoupon) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACK_END}brand?_id=${selectedCoupon?.brand[0]?._id}`);
                    const followed = response?.data?.brands[0]?.userFollowed || [];
                    // console.log(response?.data?.brands[0]);
                    setUserFollowed(followed);
                } catch (error) {
                    // console.error("Error fetching single coupon data:", error);
                }
            }
        };

        fetchSingleBrand();
    }, [selectedCoupon]);

    useEffect(() => {
        if (userFollowed.includes(decoded?.id)) {
            setAddFollowed(true);
        } else {
            setAddFollowed(false);
        }
    }, [userFollowed, decoded]);

    const handleFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFollowedLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });


            if (response?.data?.message === 'success') {
                setUserFollowed((prev) => [...prev, decoded?.id]);
                await refetch();
                toast.success(t("AddFollowedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to followed", error);
        } finally {
            setAddFollowedLoading(false);
        }
    };

    const handleRemoveFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFollowedLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserFollowed((prev) => prev.filter((id) => id != decoded.id));
                await refetch();
                toast.success(t("RemoveFollowedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while removing from favorite", error);
        } finally {
            setAddFollowedLoading(false);
        }
    };

    let isRtl = document.documentElement.dir === 'rtl';


    // model coupons
    const toggleModalFromAllCoupons = (coupon) => {

        setSelectedCoupon(coupon)

        const modal = document.getElementById('crud-modal-from-All-Coupons');

        modal?.classList.toggle('hidden');
        modal?.setAttribute('aria-hidden', modal?.classList?.contains('hidden'));

    };

    // Function to close modal if ESC key is pressed
    const closeModalOnEscFromAS = (event) => {
        const modal = document.getElementById('crud-modal-from-All-Coupons');

        if (event?.key === 'Escape' && !modal?.classList?.contains('hidden')) {
            toggleModalFromAllCoupons();
            setSelectedCoupon()
        }
    };

    // Adding event listener to the document for keydown events
    useEffect(() => {
        document.addEventListener('keydown', closeModalOnEscFromAS);
        return () => {
            document.removeEventListener('keydown', closeModalOnEscFromAS);
        };
    }, []);


    const formatTimeDifference = (createdAt) => {
        // Convert ISO string to Date object
        const createdAtDate = new Date(createdAt);

        // Get current time
        const currentTime = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - createdAtDate;

        // Convert milliseconds to hours
        const hoursDifference = Math.round(timeDifference / (1000 * 60 * 60));

        // Define the display message based on the time difference
        let displayMessage;

        if (hoursDifference === 1) {
            displayMessage = t("LessAnHourTitle");
        } else {
            displayMessage = t("AnHourTitle");
        }

        return displayMessage;
    }

    const shareUrl = "http://github.com";
    const title = isRtl ? selectedCoupon?.description + " من خلال :" + selectedCoupon?.code : selectedCoupon?.description + " Code: " + selectedCoupon?.code;


    return <>

        {/* <Helmet>
            <meta name='All Coupons' content='All Coupons Couponesta Page' charSet="utf-8" />
            <title>All Coupons</title>
        </Helmet> */}

        <div className="container px-5 md:px-10 xl:px-40 mt-2 mb-8 py-10 ">

            <div className="flex flex-row justify-between items-center w-full">

                <h2 className='xl:text-xl text-lg  font-bold md:text-header-5'>{t("AllCouponsTitle")}</h2>

                <Link
                    to={'/coupons/All-Coupons'}
                    className="flex items-center text-nowrap justify-normal text-center mx-auto cursor-pointer border-2 border-blue-700 rounded-3xl ltr:mr-6 rtl:me-6 py-1 px-3  text-white bg-blue-700 hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">
                    <button className='font-semibold'>
                        {t("ViewAllTitle")}
                    </button>
                </Link>
            </div>

            <div>
                {
                    isLoading ? (
                        <div className="loading">
                            <BallTriangle
                                height={100}
                                width={100}
                                radius={5}
                                color="#199A8E"
                                ariaLabel="ball-triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : allCoupons?.length === 0 ? (
                        <div className='flex justify-center align-middle p-12 text-teal-800'>
                            <h1 className='font-bold'>{t("NoFoundCouponTitle")}</h1>
                        </div>
                    ) :
                        <div className="grid place-items-center px-8 xl:px-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-1 gap-y-4 mt-5">

                            {
                                allCoupons?.map((coupon, ind) => {
                                    {/* coupon */ }

                                    return <div
                                        key={ind}
                                        className="bg-white border-2 border-neutral-300 w-[98%] rounded-3xl px-1 py-4 cursor-pointer flex flex-col items-center"
                                        onClick={() => toggleModalFromAllCoupons(coupon)}
                                        data-modal-target="crud-modal-from-All-Coupons"
                                        data-modal-toggle="crud-modal-from-All-Coupons"
                                    >

                                        {/* one side */}
                                        <div className="w-full flex flex-col items-center py-8">
                                            <img src={`${process.env.REACT_APP_BACK_END}${coupon?.brand[0]?.image}` || brand} alt="brand img" className='w-20 rounded-full border-2 border-neutral-200 ltr:mr-3 rtl:ml-3' />
                                            {
                                                isRtl ? <>
                                                    <h6 className="mt-3 mb-3 text-xl font-extrabold">{coupon?.brand[0]?.name?.ar}</h6>
                                                </>
                                                    : <>

                                                        <h6 className="mt-3 mb-3 text-xl font-extrabold">{coupon?.brand[0]?.name?.en}</h6>
                                                    </>

                                            }
                                        </div>

                                        {/* tow side */}

                                        <div className="relative mx-6 w-full h-auto border-b-4 border-dashed border-neutral-200">
                                            <div className="absolute -left-[16px] -top-3 h-6 w-12 -rotate-90 transform rounded-bl-full rounded-br-full border-l-3 border-r-3 border-t-3 border-neutral-50 bg-gray-200"></div>
                                            <div className="absolute -right-[16px] -top-3 h-6 w-12 rotate-90 transform rounded-bl-full rounded-br-full border-l-3 border-r-3 border-t-3 border-neutral-50 bg-gray-200"></div>
                                        </div>


                                        {/* three side */}

                                        {/* discount */}
                                        <div
                                            className={`my-2 flex w-fit max-w-[122px] rounded-3xl bg-opacity-25 p-2 text-sm font-semibold ${coupon.status === 'discount' ? 'bg-[#1B998B]  text-[#1B998B]' : 'bg-[#E58B66] text-[#E58B66] '}`}>
                                            {
                                                coupon.status === 'discount' ?

                                                    <div className=" flex items-center">
                                                        <img src="	https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fdiscount.svg&w=1536&q=100" className='w-6' />

                                                        <span className='ms-1'> {coupon.status}</span>
                                                    </div>

                                                    :
                                                    <div className=" flex items-center">
                                                        <img src="https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fcashback.svg&w=1536&q=100" className='w-6' />

                                                        <span className='ms-1'> {coupon.status}</span>
                                                    </div>
                                            }


                                        </div>

                                        {/* amount */}
                                        <p className='font-extrabold text-2xl mb-2'>{coupon?.amount} %</p>

                                        {/* description */}
                                        <p className="mb-5 line-clamp-1 max-w-[150px]">
                                            {coupon?.description}
                                        </p>

                                    </div>
                                })
                            }

                        </div>
                }

            </div>


        </div>



        {/* coupon model */}
        {
            <div
                id="crud-modal-from-All-Coupons"
                tabIndex="-1"
                aria-hidden="true"
                className="hidden fixed z-50 inset-0 pb-7 flex items-center justify-center bg-gray-900 bg-opacity-50"
            >

                <div className="relative overflow-y-auto bg-white pt-2 pb-7 px-2 border-1 border-neutral-200 rounded-3xl shadow-lg mt-9 w-full max-w-sm sm:max-w-md xl:max-w-xl max-h-full">

                    {/* Modal content */}
                    <div className="relative bg-white dark:bg-gray-700">

                        {/* Modal header */}
                        <button
                            onClick={() => toggleModalFromAllCoupons(null)}
                            type="button"
                            className="absolute top-0 right-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal-from-All-Coupons"
                        >
                            <i className="fa-solid fa-xmark text-xl"></i>
                        </button>

                        <div className="flex items-center justify-between pt-8 px-2 pb-5">

                            <div className="flex flex-row justify-start items-center">
                                <img src={`${process.env.REACT_APP_BACK_END}${selectedCoupon?.brand[0]?.image}` || brand} alt="brand img" className='w-20 rounded-full border border-neutral-200 ltr:mr-3 rtl:ml-3' />
                                <h4 className="ms-2 font-head font-extrabold text-xl text-neutral-1000">{isRtl ? selectedCoupon?.brand[0]?.name?.ar : selectedCoupon?.brand[0]?.name?.en}</h4>

                            </div>

                            {
                                addFollowedLoading ?
                                    <FallingLines
                                        color="#1a56db"
                                        width="60"
                                        visible={true}
                                        ariaLabel="falling-circles-loading"
                                    />
                                    :
                                    addFollowed ?
                                        <div className="flex items-center ms-4"
                                            onClick={() => handleRemoveFollow(selectedCoupon?.brand[0]?._id)}
                                        >
                                            <div className="flex items-center justify-center ms-3 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl py-2.5 px-5 text-white bg-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                <i className="fa-solid fa-star"></i>
                                                <button className='ms-1'>
                                                    {t('IsFollowTitle')}
                                                </button>
                                            </div>

                                        </div>
                                        :
                                        <div className="flex items-center ms-4"
                                            onClick={() => handleFollow(selectedCoupon?.brand[0]?._id)}
                                        >
                                            <div className="flex items-center justify-center ms-3 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl py-2.5 px-5 text-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                <i className="fa-regular fa-star"></i>
                                                <button className='ms-1'>
                                                    {t('FollowTitle')}
                                                </button>
                                            </div>

                                        </div>


                            }


                        </div>

                        {/* Modal body */}
                        <div className=" bg-white px-5 pb-4">

                            {/* section 1 */}
                            <div className="border-y-2 border-neutral-200 bg-white p-4 text-lg font-medium">

                                {/* discount */}
                                <div className="flex flex-col items-center justify-center">
                                    <div
                                        className={`mb-2 mt-2 flex w-fit max-w-[122px] rounded-3xl bg-opacity-25 p-2 text-sm font-semibold ${selectedCoupon?.status === 'discount' ? 'bg-[#1B998B]  text-[#1B998B]' : 'bg-[#E58B66] text-[#E58B66] '}`}
                                    >
                                        {
                                            selectedCoupon?.status === 'discount' ?

                                                <div className=" flex items-center">
                                                    <img src="	https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fdiscount.svg&w=1536&q=100" className='w-6' />

                                                    <span className='ms-1'> {selectedCoupon?.status}</span>
                                                </div>

                                                :
                                                <div className=" flex items-center">
                                                    <img src="https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fcashback.svg&w=1536&q=100" className='w-6' />

                                                    <span className='ms-1'> {selectedCoupon?.status}</span>
                                                </div>
                                        }

                                    </div>

                                    <span
                                        className="mb-1 mt-3 flex items-center text-[26px] font-bold">
                                        {
                                            isRtl ? <>
                                                <span className="pr-2 text-lg text-gray-400 ltr:pr-2 rtl:pl-2">
                                                    اعلي من
                                                </span>
                                                {selectedCoupon?.amount}
                                                <span className="pr-1">
                                                    %
                                                </span>
                                            </>
                                                :
                                                <>
                                                    <span className="pr-2 text-lg text-gray-400 ltr:pr-2 rtl:pl-2">
                                                        Up To
                                                    </span>
                                                    {selectedCoupon?.amount}
                                                    <span className="pl-1">
                                                        %
                                                    </span>
                                                </>
                                        }
                                    </span>

                                </div>
                            </div>

                            {/* section 2 */}
                            <div className="border-b-2 border-neutral-200 bg-white py-5 text-lg text-neutral-800">
                                <h6 className="mb-2.5 font-medium">{t("DetailsTitle")}</h6>

                                <p
                                    className="font-normal ps-2">
                                    {selectedCoupon?.description}
                                </p>


                                <div className="truncate text-caption-1 flex items-center mt-4 ">
                                    {
                                        isRtl ? <>
                                            <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                                            <p className="truncate text-caption-1 text-wrap ms-1 text-green-700" > <span className='text-black'>اخر استخدام:</span> {formatTimeDifference(selectedCoupon?.createdAt)}</p>
                                        </>
                                            :
                                            <>
                                                <i className="fa-solid fa-arrow-up-right-from-square me-1"></i>
                                                <p className="truncate text-caption-1 text-wrap ms-1 text-green-700"> <span className='text-black'>Last Used:</span> {formatTimeDifference(selectedCoupon?.createdAt)}</p>

                                            </>
                                    }
                                </div>
                            </div>

                            {/* section 3 */}
                            <div className="cursor-pointer bg-white py-2 xl:p-5 xl:pb-6 text-lg font-medium text-neutral-800">

                                <div className="flex items-center justify-between">

                                    {/* Share */}
                                    <div onClick={toggleIcons}
                                        className="flex flex-col items-center hover:text-blue-700 align-middle justify-center">

                                        {/* icon */}
                                        <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                            <i className='fa-solid fa-share-from-square text-xl p-4'></i>
                                        </div>

                                        <h3>{t("ShareTitle")}</h3>
                                    </div>

                                    {/* favorite */}
                                    {
                                        addFavoriteLoading ?
                                            <FallingLines
                                                color="#1a56db"
                                                width="60"
                                                visible={true}
                                                ariaLabel="falling-circles-loading"
                                            />
                                            :
                                            addFavorite ?
                                                <div
                                                    onClick={() => handleRemoveFavorite(selectedCoupon?._id)}
                                                    className="flex flex-col items-center text-blue-700 align-middle justify-center">

                                                    {/* icon */}
                                                    <div className={`relative rounded-full bg-neutral-200 text-blue-700`}>
                                                        <i className='fa-solid fa-heart text-xl p-4 text-blue-700'></i>
                                                    </div>

                                                    <h3>{t("FavoriteTitle")}</h3>
                                                </div>
                                                :
                                                <div
                                                    onClick={() => handleFavorite(selectedCoupon?._id)}
                                                    className="flex flex-col items-center hover:text-blue-700 align-middle justify-center">

                                                    {/* icon */}
                                                    <div className={`relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300`}>
                                                        <i className='fa-regular fa-heart text-xl p-4'></i>
                                                    </div>

                                                    <h3>{t("FavoriteTitle")}</h3>
                                                </div>


                                    }

                                    {/* Shop */}
                                    <Link
                                        target='_blank'
                                        to={selectedCoupon?.brand[0]?.link}
                                        className="flex flex-col items-center hover:text-blue-700 align-middle justify-center">

                                        {/* icon */}
                                        <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                            <i className='fa-solid fa-shop text-xl p-4'></i>
                                        </div>

                                        <h3>{t("ShopTitle")}</h3>
                                    </Link>

                                    {
                                        addLikeLoading ?
                                            <FallingLines
                                                color="#1a56db"
                                                width="60"
                                                visible={true}
                                                ariaLabel="falling-circles-loading"
                                            />
                                            :
                                            addLike ?

                                                <div className="flex flex-col items-center text-blue-700 align-middle justify-center" onClick={() => handleLike(selectedCoupon._id)}>
                                                    {/* Like Icon */}
                                                    <div className="relative rounded-full bg-neutral-200 text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-solid fa-thumbs-up text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("LikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countLiked || selectedCoupon?.likeCount}</span></h3>
                                                </div>
                                                :

                                                <div className="flex flex-col items-center hover:text-blue-700 align-middle justify-center" onClick={() => handleLike(selectedCoupon._id)}>
                                                    {/* Like Icon */}
                                                    <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-regular fa-thumbs-up text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("LikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countLiked || selectedCoupon?.likeCount}</span></h3>
                                                </div>
                                    }

                                    {
                                        addDisLikeLoading ?
                                            <FallingLines
                                                color="#1a56db"
                                                width="60"
                                                visible={true}
                                                ariaLabel="falling-circles-loading"
                                            />
                                            :
                                            addDisLike ?

                                                <div className="flex flex-col items-center hover:text-blue-700 align-middle justify-center" onClick={() => handleDisLike(selectedCoupon._id)}>
                                                    {/* Dislike Icon */}
                                                    <div className="relative rounded-full bg-neutral-200 text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-solid fa-thumbs-down text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("DislikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countDisLiked || selectedCoupon?.dislikeCount}</span></h3>
                                                </div>
                                                :

                                                <div className="flex flex-col items-center hover:text-blue-700 align-middle justify-center" onClick={() => handleDisLike(selectedCoupon._id)}>
                                                    {/* Dislike Icon */}
                                                    <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-regular fa-thumbs-down text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("DislikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countDisLiked || selectedCoupon?.dislikeCount}</span></h3>
                                                </div>
                                    }

                                </div>
                            </div>

                            {/* icons */}
                            {showIcons && (
                                <div className="cursor-pointer bg-white px-4 pt-1 pb-2 text-lg font-medium text-neutral-800">
                                    <div className="flex items-center justify-between">

                                        {/* whats app */}
                                        <WhatsappShareButton title={title} url={window.location.href}
                                        >
                                            <div
                                                className='flex justify-center items-center w-12 h-12  rounded-full text-white bg-green-500 hover:bg-green-600'
                                            >
                                                <i className='fa-brands fa-whatsapp text-2xl'></i>
                                            </div>
                                        </WhatsappShareButton>

                                        {/* telegram*/}
                                        <TelegramShareButton title={title} url={window.location.href}>
                                            <div
                                                className='flex justify-center items-center w-12 h-12  rounded-full text-white bg-[#26A5E4] hover:bg-teal-500'
                                            >
                                                <i className='fa-brands fa-telegram text-2xl'></i>
                                            </div>
                                        </TelegramShareButton>

                                        {/* twitter*/}
                                        <TwitterShareButton title={title} url={window.location.href}>
                                            <div className="flex justify-center items-center w-12 h-12  rounded-full text-white bg-neutral-900 hover:bg-neutral-950">
                                                <i className='fa-brands fa-x-twitter text-2xl'></i>
                                            </div>
                                        </TwitterShareButton>

                                        {/* facebook */}
                                        <FacebookMessengerShareButton title={title} url={window.location.href}>
                                            <div className="flex justify-center items-center w-12 h-12  rounded-full text-white bg-blue-500 hover:bg-blue-600">
                                                <i className='fa-brands fa-facebook-messenger text-2xl'></i>
                                            </div>
                                        </FacebookMessengerShareButton>

                                        {/* Tiktok */}
                                        <Link
                                            to={`https://www.tiktok.com/@`}
                                            target='_blank'
                                        >
                                            <div className="flex justify-center items-center w-12 h-12  rounded-full text-white bg-rose-500 hover:bg-rose-600">
                                                <i className='fa-brands fa-tiktok text-2xl'></i>
                                            </div>
                                        </Link>

                                    </div>
                                </div>
                            )}

                            {/* section 4 */}
                            <div className="relative flex flex-row ms-3 me-5 mb-4">

                                {/* border */}
                                <div className="my-2 w-full border-t-4 border-dashed border-neutral-200"></div>

                                {/* left curve */}
                                <div className="absolute -left-[52px] h-6 w-12 -translate-y-0.5 -rotate-90 transform rounded-bl-full rounded-br-full border-b-2 border-l-2 border-r-2 border-neutral-200 ltr:-ml-[21px] rtl:-mr-[21px] bg-neutral-400"></div>

                                {/* right curve */}
                                <div className="absolute -right-[60px] h-6 w-12 -translate-y-0.5 rotate-90 transform rounded-bl-full rounded-br-full border-b-2 border-l-2 border-r-2 border-neutral-200 ltr:-ml-[21px] rtl:-mr-[21px] bg-neutral-400"></div>
                            </div>

                            {/* section 5 */}
                            <div className=" rounded-full bg-neutral-200 text-2xl py-2 font-bold">

                                <div className="flex justify-between items-center">
                                    <h6 className="my-auto flex-grow text-center tracking-[0.2rem] sm:tracking-[0.4rem]">
                                        {selectedCoupon?.code ? selectedCoupon?.code : ""}
                                    </h6>

                                    {
                                        selectedCoupon?.code === "" ?

                                            <div className='w-full text-start'>
                                                <Link
                                                    target='_blank'
                                                    to={`${selectedCoupon?.link ? selectedCoupon?.link : selectedCoupon?.brand[0]?.link}`}
                                                    className='text-white text-sm xl:text-xl ltr:ms-2 rtl:me-2 underline bg-blue-700 font-serif rounded-3xl py-2 px-5 transform transition-all ease-out hover:translate-y-1 hover:shadow-[0_1.5px_0px_rgb(0,0,0)] active:scale-x-75 font-bold'>
                                                    {/* className='text-white underline bg-blue-700  font-bold w-full'> */}
                                                    {
                                                        isRtl ? <>
                                                            {t("ShopNowTitle")}
                                                            <i className={` fa-solid ${isRtl ? 'fa-arrow-left rotate-45 me-1' : 'fa-arrow-right -rotate-45 ms-1'}`}></i>
                                                        </>
                                                            :
                                                            <>
                                                                {t("ShopNowTitle")}
                                                                <i className={` fa-solid ${isRtl ? 'fa-arrow-left rotate-45 me-1' : 'fa-arrow-right -rotate-45 ms-1'}`}></i>
                                                            </>
                                                    }
                                                </Link>

                                            </div>
                                            :
                                            <Link
                                                target='_blank'
                                                to={`${selectedCoupon?.link ? selectedCoupon?.link : selectedCoupon?.brand[0]?.link}`}
                                                className='me-2'>
                                                <CopyToClipboard text={selectedCoupon?.code ? selectedCoupon?.code : "No Code"} onCopy={() => handleCopy(selectedCoupon?._id)}>
                                                    <button
                                                        className={`rounded-3xl py-2 px-5 transform transition-all ease-out hover:translate-y-1 hover:shadow-[0_1.5px_0px_rgb(0,0,0)] active:scale-x-75 font-bold ${copied ? 'bg-green-500 text-white' : 'bg-[#2761FF] text-white'}`}
                                                    >
                                                        {copied ? t("CopiedTitle") : t("CopyTitle")}
                                                    </button>
                                                </CopyToClipboard>
                                            </Link>
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div >

        }

    </>
}
