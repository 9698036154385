import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {

    const { i18n } = useTranslation();

    let lng = i18n.language


    return <>

        <Helmet>
            <meta name='Privacy & Policy' content='Privacy & Policy Couponesta Page' charSet="utf-8" />
            <title>Privacy & Policy Couponesta</title>
        </Helmet>

        <div className="container mx-auto py-20 bg-[#F6F6F6] pt-20 mb-8">
            <div className="text-center">
                <h2 className="mb-4 text-xl md:text-3xl font-bold">{lng != "en" ? "سياسة الخصوصية" : "Privacy Policy"}</h2>
            </div>
        </div>

        {
            lng != "en" ?

                <div className="container mx-auto w-full -translate-y-14 transform rounded-2xl border-2 border-solid border-neutral-100 bg-white p-8 shadow-xl md:w-3/6">
                    <p><strong>كوبونستا</strong> ملتزمة بحماية واحترام خصوصيتك.</p>
                    <p>تحدد هذه السياسة (مع أي مستندات أخرى مشار إليها فيها) الأساس الذي ستتم عليه معالجة أي بيانات شخصية نجمعها منك، أو التي تقدمها لنا. يرجى قراءة ما يلي بعناية لفهم وجهات نظرنا وممارساتنا فيما يتعلق ببياناتك الشخصية وكيف سنتعامل معها.</p>
                    <p>إذا كنت تحت سن 18، يرجى عدم إرسال أي بيانات شخصية عن نفسك إلينا.</p>

                    <h3 className="my-3 text-[28px] font-normal">1. جمع المعلومات</h3>
                    <p>1.1 من أجل تقديم خدمة أفضل لك، قد نقوم بجمع ومعالجة البيانات التالية عنك:</p>
                    <p>(أ) المعلومات التي تقدمها بملء النماذج على موقعنا Couponesta.co ("موقعنا") و/أو تطبيقنا "كوبونستا". يشمل ذلك المعلومات المقدمة عند التسجيل لاستخدام موقعنا/تطبيقنا، الاشتراك في خدمتنا، نشر المواد أو طلب خدمات إضافية. قد نطلب منك أيضًا معلومات في أوقات أخرى، على سبيل المثال في إطار الترويج أو عندما تبلغ عن مشكلة في موقعنا؛</p>
                    <p>(ب) إذا اتصلت بنا، قد نحتفظ بسجل لتلك المراسلات؛</p>
                    <p>(ج) قد نطلب منك أيضًا إكمال استبيانات نستخدمها لأغراض البحث، على الرغم من أنك لست مضطرًا للرد عليها؛</p>
                    <p>(د) تفاصيل المعاملات التي تقوم بها من خلال موقعنا و/أو تطبيقنا وتنفيذ طلباتك؛</p>
                    <p>(هـ) تفاصيل زياراتك لموقعنا و/أو تطبيقنا والموارد التي تصل إليها.</p>
                    <p>1.2 نحن نحتفظ بالبيانات الشخصية فقط طالما كانت ضرورية. قد يتم أرشفة البيانات طالما كان الغرض الذي استخدمت من أجله البيانات لا يزال موجودًا.</p>

                    <h3 className="my-3 text-[28px] font-normal">2. استخدام المعلومات</h3>
                    <p>2.1 الأغراض التي قد نستخدم المعلومات من أجلها في داخل وخارج المملكة العربية السعودية تشمل:</p>
                    <p>(أ) ضمان أن يتم تقديم المحتوى من موقعنا وتطبيقنا بأكثر الطرق فعالية لك ولجهاز الكمبيوتر أو أي جهاز آخر قد تستخدمه للوصول إلى موقعنا أو تطبيقنا؛</p>
                    <p>(ب) تزويدك بالتنبيهات، النشرة الإخبارية، المواد التعليمية أو المعلومات التي طلبتها أو اشتركت فيها؛</p>
                    <p>(ج) تنفيذ التزاماتنا الناشئة عن أي عقود مبرمة بينك وبيننا؛</p>
                    <p>(د) السماح لك بالمشاركة في الميزات التفاعلية لخدمتنا عندما تختار القيام بذلك؛</p>
                    <p>(هـ) تصميم وإجراء استبيانات/استبيانات لتوصيف العملاء/التقسيم، التحليل الإحصائي، تحسين وتقديم منتجاتنا وخدماتنا بشكل أفضل؛</p>
                    <p>(و) الامتثال للقوانين واللوائح المطبقة علينا أو أي من الشركات التابعة لنا داخل أو خارج المملكة العربية السعودية؛</p>
                    <p>(ز) الإجراءات القانونية، بما في ذلك جمع المبالغ المستحقة والسعي للحصول على مشورة مهنية؛</p>
                    <p>(ح) البحث وتصميم وإطلاق خدمات أو منتجات بما في ذلك الندوات/الأحداث/المنتديات؛</p>
                    <p>(ط) الترويج والتسويق للخدمات والمنتجات بشرط أن تمارس حقك في الانسحاب (يرجى الاطلاع على مزيد من التفاصيل في البند 2.2 أدناه)؛ أو</p>
                    <p>(ي) الأغراض المتعلقة مباشرة أو عرضياً بما سبق.</p>
                    <p>2.2 نعتزم استخدام بياناتك في التسويق المباشر ونطلب موافقتك (التي تشمل عدم الاعتراض) لهذا الغرض. في هذا السياق، يرجى ملاحظة:</p>
                    <p>(أ) قد يتم استخدام اسمك، تفاصيل الاتصال (بما في ذلك العنوان، رقم الاتصال، البريد الإلكتروني)، معلومات المنتجات والخدمات، نمط المعاملات والسلوك، البيانات الخلفية والديموغرافية التي نحتفظ بها من وقت لآخر في التسويق المباشر؛</p>
                    <p>(ب) فئات الخدمات والمنتجات والمواضيع التالية قد يتم تسويقها في التسويق المباشر:</p>
                    <p>(1) الخدمات والمنتجات المتعلقة بموقعنا و/أو الشركات التابعة لنا (بما في ذلك البرامج التابعة للتسويق التي نشارك فيها)؛</p>
                    <p>(2) برامج المكافآت أو الولاء أو الامتيازات، العروض الترويجية والخدمات ذات الصلة؛ و</p>
                    <p>(3) دعوات لحضور أحداث مثل الندوات/الندوات عبر الإنترنت/الندوات الهاتفية، المؤتمرات، البرامج أو الأحداث المباشرة.</p>
                    <p>(ج) قد نجري التسويق المباشر عبر البريد الإلكتروني، الرسائل القصيرة ووسائل الاتصال الأخرى أو نرسل النشرات الإلكترونية أو الإخطارات إليك. يمكنك اختيار عدم تلقي المواد الترويجية، بإبلاغنا ببساطة (انظر أدناه للحصول على تفاصيل الاتصال)، وسنتوقف عن القيام بذلك، بدون رسوم.</p>

                    <h3 className="my-3 text-[28px] font-normal">3. الكشف عن معلوماتك</h3>
                    <p>3.1 سنحافظ على سرية البيانات الشخصية التي نحتفظ بها، ولكن قد نقدم المعلومات إلى:</p>
                    <p>(أ) الموظفين، الوكلاء، المستشارين، المراجعين، المقاولين، المؤسسات المالية، ومقدمي الخدمات فيما يتعلق بعملياتنا أو خدماتنا؛</p>
                    <p>(ب) مكاتبنا الخارجية، الشركات التابعة لنا، الشركاء التجاريين والنظراء (إن وجد)؛</p>
                    <p>(ج) الأشخاص الذين يتحملون واجب السرية تجاهنا؛</p>
                    <p>(د) الأشخاص الذين يتعين علينا الكشف لهم بموجب القوانين واللوائح المطبقة داخل أو خارج المملكة العربية السعودية؛ أو</p>
                    <p>(هـ) المشاركين أو المتقدمين المحتملين لخدماتنا داخل أو خارج المملكة العربية السعودية.</p>

                    <h3 className="my-3 text-[28px] font-normal">4. الكوكيز</h3>
                    <p>4.1 يستخدم موقعنا ملفات تعريف الارتباط (الكوكيز) لتمييزك عن المستخدمين الآخرين لموقعنا. يساعدنا هذا في تقديم تجربة جيدة عند تصفح موقعنا وأيضًا يسمح لنا بتحسين موقعنا. من خلال الاستمرار في تصفح الموقع، فإنك توافق على استخدامنا لملفات تعريف الارتباط.</p>
                    <p>4.2 ملف تعريف الارتباط هو ملف صغير من الأحرف والأرقام الذي نخزنه على متصفحك أو القرص الصلب لجهاز الكمبيوتر الخاص بك إذا كنت توافق. تحتوي ملفات تعريف الارتباط على معلومات يتم نقلها إلى القرص الصلب لجهاز الكمبيوتر الخاص بك أو أي جهاز آخر قد تستخدمه للوصول إلى موقعنا.</p>
                    <p>4.3 نستخدم ملفات تعريف الارتباط التالية:</p>
                    <p>(أ) ملفات تعريف الارتباط الضرورية للغاية. هذه هي ملفات تعريف الارتباط المطلوبة لتشغيل موقعنا. وتشمل، على سبيل المثال، ملفات تعريف الارتباط التي تمكنك من تسجيل الدخول إلى المناطق الآمنة من موقعنا.</p>
                    <p>(ب) ملفات تعريف الارتباط التحليلية/الأداء. تسمح لنا هذه الملفات بالتعرف على عدد الزوار وحسابهم ورؤية كيف يتنقل الزوار في موقعنا عند استخدامه. يساعدنا ذلك في تحسين طريقة عمل موقعنا، على سبيل المثال، عن طريق التأكد من أن المستخدمين يجدون ما يبحثون عنه بسهولة.</p>
                    <p>(ج) ملفات تعريف الارتباط الوظيفية. تستخدم هذه الملفات للتعرف عليك عندما تعود إلى موقعنا. يمكننا ذلك من تخصيص محتوى موقعنا لك، والترحيب بك باسمك وتذكر تفضيلاتك (على سبيل المثال، اختيارك للغة أو المنطقة).</p>
                    <p>(د) ملفات تعريف الارتباط الاستهدافية. تسجل هذه الملفات زيارتك لموقعنا، والصفحات التي زرتها والروابط التي اتبعتها. سنستخدم هذه المعلومات لجعل موقعنا والإعلانات المعروضة عليه أكثر ملاءمة لاهتماماتك. قد نشارك أيضًا هذه المعلومات مع أطراف ثالثة لهذا الغرض.</p>
                    <p>4.4 يرجى ملاحظة أن الأطراف الثالثة (بما في ذلك، على سبيل المثال، شبكات الإعلان ومقدمي الخدمات الخارجية مثل خدمات تحليل حركة المرور على الويب) قد تستخدم أيضًا ملفات تعريف الارتباط، التي ليس لدينا سيطرة عليها. من المحتمل أن تكون هذه الملفات ملفات تعريف الارتباط التحليلية/الأداء أو ملفات تعريف الارتباط الاستهدافية.</p>
                    <p>4.5 يمكنك حظر ملفات تعريف الارتباط عن طريق تفعيل الإعداد على متصفحك الذي يسمح لك برفض إعداد كل أو بعض ملفات تعريف الارتباط. ومع ذلك، إذا كنت تستخدم إعدادات متصفحك لحظر جميع ملفات تعريف الارتباط (بما في ذلك ملفات تعريف الارتباط الضرورية) فقد لا تتمكن من الوصول إلى جميع أو أجزاء من موقعنا.</p>

                    <h3 className="my-3 text-[28px] font-normal">5. الأمان</h3>
                    <p>5.1 يتم تخزين جميع المعلومات التي تقدمها لنا على خوادمنا الآمنة. سيتم تشفير أي معاملات دفع باستخدام تكنولوجيا SSL. حيث قمنا بإعطائك (أو حيث اخترت) كلمة مرور تمكنك من الوصول إلى أجزاء معينة من موقعنا، فإنك تكون مسؤولاً عن الحفاظ على سرية هذه الكلمة. نطلب منك عدم مشاركة كلمة المرور مع أي شخص.</p>
                    <p>5.2 للأسف، نقل المعلومات عبر الإنترنت ليس آمنًا تمامًا. على الرغم من أننا سنبذل قصارى جهدنا لحماية بياناتك الشخصية، لا يمكننا ضمان أمان بياناتك المرسلة إلى موقعنا أو تطبيقنا؛ أي نقل يتم على مسؤوليتك الخاصة وأنت توافق على عدم تحميلنا المسؤولية عن أي خرق للأمن أثناء الوصول إلى الإنترنت الذي يكون خارج عن سيطرتنا. بمجرد استلامنا لمعلوماتك، سنستخدم إجراءات صارمة وميزات الأمان لمحاولة منع الوصول غير المصرح به.</p>

                    <h3 className="my-3 text-[28px] font-normal">6. التطبيقات والخدمات الطرف الثالث ومشاركة البيانات</h3>
                    <h2>الخدمات الطرف الثالث المتكاملة</h2>
                    <p>يُدمج تطبيقنا العديد من خدمات الطرف الثالث لتعزيز الوظائف وتجربة المستخدم. فيما يلي قائمة مفصلة بهذه الخدمات وكيفية تعاملها مع بياناتك:</p>
                    <ul>
                        <li><strong>إعلانات Google Mobile</strong>
                            <p><strong>الغرض:</strong> الإعلانات والتحليلات</p>
                            <p><strong>البيانات المستخدمة:</strong> معرف الجهاز (لأغراض الإعلان والتسويق) بيانات الاستخدام للتحليلات والإعلانات الخارجية</p>
                            <p><strong>ملاحظة الخصوصية:</strong> قد تستخدم Google هذه البيانات لتخصيص الإعلانات وإجراء التحليلات. يتم مشاركة البيانات بموجب اتفاقيات سرية صارمة.</p>
                        </li>
                        <li><strong>OneSignal</strong>
                            <p><strong>الغرض:</strong> الإشعارات الفورية والرسائل</p>
                            <p><strong>البيانات المستخدمة:</strong> محددات الموقع التقريبية مثل معرف المستخدم (لوظائف التطبيق والتحليلات)</p>
                            <p><strong>ملاحظة الخصوصية:</strong> يستخدم لإرسال الإشعارات وتحليل أنماط استخدام التطبيق. لا تستخدم البيانات للتحليلات أو أغراض التسويق.</p>
                        </li>
                        <li><strong>MoEngage</strong>
                            <p><strong>الغرض:</strong> التحليلات، تخصيص المنتج، ووظائف التطبيق</p>
                            <p><strong>البيانات المستخدمة:</strong> معرف المستخدم، بيانات الاستخدام</p>
                            <p><strong>ملاحظة الخصوصية:</strong> تستخدم التحليلات العميقة لتخصيص العروض وتحسين وظائف التطبيق.</p>
                        </li>
                        <li><strong>Firebase (Crashlytics and Messaging)</strong>
                            <p><strong>الغرض:</strong> وظائف التطبيق، التشخيصات</p>
                            <p><strong>البيانات المستخدمة:</strong> بيانات التشخيص المتعلقة بأداء التطبيق وتقارير الأعطال</p>
                            <p><strong>ملاحظة الخصوصية:</strong> تستخدم أدوات Firebase لضمان الاستقرار، تسليم الرسائل، ومعالجة تقارير الأعطال دون استخدام البيانات للتحليلات أو التسويق.</p>
                        </li>
                        <li><strong>Adjust</strong>
                            <p><strong>الغرض:</strong> التحليلات، التخصيص، والتسويق</p>
                            <p><strong>البيانات المستخدمة:</strong> بيانات الإعلان، معرف الجهاز</p>
                            <p><strong>ملاحظة الخصوصية:</strong> يتتبع Adjust تفاعل المستخدم لتوفير رؤى تسويقية وفعالية الحملات.</p>
                        </li>
                    </ul>

                    <h2>موافقة المستخدم وخيارات الانسحاب</h2>
                    <p>لدى المستخدمين القدرة على التحكم في جمع واستخدام بياناتهم من قبل هذه الخدمات الطرف الثالث:</p>
                    <ul>
                        <li><strong>الانسحاب:</strong> يمكنك الانسحاب من جمع البيانات لأغراض التسويق من خلال تكوين تفضيلاتك عبر إعدادات التطبيق أو مباشرة عبر إعدادات خدمة الطرف الثالث حيثما كان ذلك ممكنًا.</li>
                        <li><strong>إدارة ملفات تعريف الارتباط والمعرفات:</strong> قم بضبط إعدادات جهازك أو متصفحك لإدارة أو رفض ملفات تعريف الارتباط والمعرفات الأخرى المستخدمة من قبل هذه الخدمات الطرف الثالث.</li>
                    </ul>

                    <h2>سياسات الخصوصية الخاصة بالطرف الثالث</h2>
                    <p>نشجعك على مراجعة سياسات الخصوصية لهذه الخدمات الطرف الثالث لفهم ممارساتهم في التعامل مع البيانات. يدمج تطبيقنا هذه الخدمات بموجب اتفاقيات صارمة تتماشى مع التزاماتنا المتعلقة بالخصوصية؛ ومع ذلك، نحن لا نتحكم في ممارساتهم النهائية للبيانات.</p>

                    <h3 className="my-3 text-[28px] font-normal">7. تغييرات على سياسة الخصوصية الخاصة بنا</h3>
                    <p>أي تغييرات قد نجريها على سياسة الخصوصية الخاصة بنا في المستقبل سيتم نشرها على هذه الصفحة. يشكل استمرارك في استخدام خدماتنا موافقتك على سياسة الخصوصية هذه وأي تحديثات.</p>

                    <h3 className="my-3 text-[28px] font-normal">8. نقل البيانات</h3>
                    <p>قد نحتفظ ببياناتك على خوادم في المملكة العربية السعودية وأي مناطق أخرى نراها مناسبة من وقت لآخر. قد نقوم أيضًا بنقل بياناتك إلى مكاتبنا الخارجية أو إلى أي من الأشخاص المدرجين في البند 3.1 أعلاه، والذين قد يكونون موجودين داخل أو خارج المملكة العربية السعودية.</p>

                    <h3 className="my-3 text-[28px] font-normal">9. موافقتك وحقوقك</h3>
                    <p>9.1 باستخدامك لخدمتنا، تقديم طلب أو زيارة موقعنا أو تطبيقنا، فإنك توافق على جمع واستخدام معلوماتك وغيرها من الأنشطة كما هو موضح في هذه السياسة.</p>
                    <p>9.2 بموجب قانون حماية البيانات الشخصية ("PDPA")، للأفراد الحق في:</p>
                    <p>(أ) التحقق مما إذا كنا نحتفظ ببيانات شخصية عنك والوصول إلى تلك البيانات؛</p>
                    <p>(ب) طلب منا تصحيح أي بيانات غير دقيقة تتعلق بك في أقرب وقت ممكن؛</p>
                    <p>(ج) التأكد من سياساتنا وممارساتنا فيما يتعلق بالبيانات الشخصية ونوع البيانات الشخصية التي نحتفظ بها؛ و</p>
                    <p>(د) الاعتراض على استخدام بياناتك الشخصية لأغراض التسويق ونحن لن نستخدم بياناتك الشخصية لأغراض التسويق بعد إبلاغك باعتراضك لنا.</p>
                    <p>9.3 يمكنك ممارسة حقك في الانسحاب بإبلاغنا إذا كنت ترغب في الاعتراض على استخدام بياناتك الشخصية لأغراض التسويق المباشر. يرجى إرسال طلبات مثل هذه الاعتراضات، الوصول إلى البيانات، تصحيح البيانات، المعلومات بشأن السياسات والممارسات وأنواع البيانات المحتفظ بها، الأسئلة أو الشكاوى إلى <a href="mailto:support@Couponesta.co">Support@Couponesta.co</a></p>
                    <p>9.4 يمكنك ممارسة "حقك في النسيان" بإبلاغنا إذا كنت ترغب في طلب حذف بياناتك الشخصية من النظام. يرجى إرسال طلب إلى <a href="mailto:support@Couponesta.co">Support@Couponesta.co</a> بخصوص هذا الأمر.</p>
                </div>

                :

                <div className="container mx-auto w-full -translate-y-14 transform rounded-2xl border-2 border-solid border-neutral-100 bg-white p-8 shadow-xl md:w-3/6">
                    <p><strong>Couponesta</strong> is committed to protecting and respecting your privacy.</p>
                    <p>This policy (together with any other documents referred to in it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
                    <p>If you are under 18, please do not send any personal data about yourself to us.</p>

                    <h3 className="my-3 text-[28px] font-normal">1. Information collection</h3>
                    <p>1.1 In order to provide a better service to you, we may collect and process the following data about you:</p>
                    <p>(a) Information that you provide by filling in forms on our site Couponesta.co (“our site”) and/or our Application "كوبونستا". This includes information provided at the time of registering to use our Website/Application, subscribing to our service, posting material or requesting further services. We may also ask you for information at other times, for example in connection with a promotion or when you report a problem with our site;</p>
                    <p>(b) If you contact us, we may keep a record of that correspondence;</p>
                    <p>(c) We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them;</p>
                    <p>(d) Details of transactions you carry out through our Website and/or Application and of the fulfillment of your orders;</p>
                    <p>(e) Details of your visits to our Website and/or Application and the resources that you access.</p>
                    <p>1.2 We only retain personal data for so long as it is necessary. Data may be archived as long as the purpose for which the data was used still exists.</p>

                    <h3 className="my-3 text-[28px] font-normal">2. Uses made of the information</h3>
                    <p>2.1 The purposes for which information may be used by us in and outside Saudi Arabia include:</p>
                    <p>(a) Ensuring that content from our Website and Application is presented in the most effective manner for you and for your computer or any other device you may use to access our Website or Application;</p>
                    <p>(b) Providing you with alerts, newsletter, education materials or information that you requested or signed up to;</p>
                    <p>(c) Carrying out our obligations arising from any contracts entered into between you and us;</p>
                    <p>(d) Allowing you to participate in interactive features of our service when you choose to do so;</p>
                    <p>(e) Designing and conducting surveys/questionnaires for client profiling/segmentation, statistical analysis, improving and furthering the provision of our products and services;</p>
                    <p>(f) Complying with laws and regulations applicable to us or any of our affiliates in or outside Saudi Arabia;</p>
                    <p>(g) Legal proceedings, including collecting overdue amounts and seeking professional advices;</p>
                    <p>(h) Researching, designing and launching services or products including seminars/events/forums;</p>
                    <p>(i) Promoting and marketing services and products subject to your exercise of the opt-out right (please see further details in Clause 2.2 below); or</p>
                    <p>(j) Purposes directly related or incidental to the above.</p>
                    <p>2.2 We intend to use your data in direct marketing and we require your consent (which includes an indication of no objection) for that purpose. In this connection, please note that:</p>
                    <p>(a) Your name, contact details (including address, contact number, email address), products and services information, transaction pattern and behavior, background and demographic data held by us from time to time may be used by us in direct marketing;</p>
                    <p>(b) The following classes of services, products, and subjects may be marketed in direct marketing:</p>
                    <p>(i) Services and products related to our site and/or our affiliates (including marketing affiliates programs we are a part of);</p>
                    <p>(ii) Reward, loyalty or privileges programmes, promotional offers and related services; and</p>
                    <p>(iii) Invitations to events such as seminars/webinars/teleseminars, conferences, live programs or events.</p>
                    <p>(c) We may conduct direct marketing via email, SMS messages and other means of communication or send e-newsletters or notifications to you. You may choose not to receive promotional materials, by simply telling us (see below for contact details), and we will cease to do so, without charge.</p>

                    <h3 className="my-3 text-[28px] font-normal">3. Disclosure of your information</h3>
                    <p>3.1 We will keep the personal data we hold confidential but may provide information to:</p>
                    <p>(a) personnel, agents, advisers, auditors, contractors, financial institutions, and service providers in connection with our operations or services;</p>
                    <p>(b) our overseas offices, affiliates, business partners and counterparts (if any);</p>
                    <p>(c) persons under a duty of confidentiality to us;</p>
                    <p>(d) persons to whom we are required to make disclosure under applicable laws and regulations in or outside Saudi Arabia; or</p>
                    <p>(e) actual or proposed transferees or participants of our services in or outside Saudi Arabia.</p>

                    <h3 className="my-3 text-[28px] font-normal">4. Cookies</h3>
                    <p>4.1 Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.</p>
                    <p>4.2 A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive or any other device you may use to access our Website.</p>
                    <p>4.3 We use the following cookies:</p>
                    <p>(a) Strictly necessary cookies. These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</p>
                    <p>(b) Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>
                    <p>(c) Functionality cookies. These are used to recognize you when you return to our website. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                    <p>(d) Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p>
                    <p>4.4 Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
                    <p>4.5 You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>

                    <h3 className="my-3 text-[28px] font-normal">5. Security</h3>
                    <p>5.1 All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
                    <p>5.2 Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Website or Application; any transmission is at your own risk and you agree not to hold us responsible for any breach of security while accessing the internet that is out of our control. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</p>

                    <h3 className="my-3 text-[28px] font-normal">6. Third-Party Applications and Data Sharing</h3>
                    <h2>Integrated Third-Party Services</h2>
                    <p>Our application integrates various third-party services to enhance functionality and user experience. Below is a detailed list of these services and how they interact with your data:</p>
                    <ul>
                        <li><strong>Google Mobile Ads</strong>
                            <p><strong>Purpose:</strong> Advertising and Analytics</p>
                            <p><strong>Data Used:</strong> Device ID (for advertising and marketing purposes) usage data for analytics and third-party advertising</p>
                            <p><strong>Privacy Note:</strong> Google may use this data to tailor ads and perform analytics. The data is shared under strict confidentiality agreements.</p>
                        </li>
                        <li><strong>OneSignal</strong>
                            <p><strong>Purpose:</strong> Push Notifications and Messaging</p>
                            <p><strong>Data Used:</strong> Coarse location identifiers such as User ID (for app functionality and analytics)</p>
                            <p><strong>Privacy Note:</strong> Used for sending notifications and analyzing app usage patterns. Does not use data for analytics or marketing purposes.</p>
                        </li>
                        <li><strong>MoEngage</strong>
                            <p><strong>Purpose:</strong> Analytics, Product Personalization, and App Functionality</p>
                            <p><strong>Data Used:</strong> User ID, usage data</p>
                            <p><strong>Privacy Note:</strong> Engages in deep analytics to personalize product offerings and improve app functionality.</p>
                        </li>
                        <li><strong>Firebase (Crashlytics and Messaging)</strong>
                            <p><strong>Purpose:</strong> App Functionality, Diagnostics</p>
                            <p><strong>Data Used:</strong> Diagnostics data related to app performance and crash reports</p>
                            <p><strong>Privacy Note:</strong> Firebase tools are used to ensure stability, deliver messages, and handle crash reporting without using the data for analytics or marketing.</p>
                        </li>
                        <li><strong>Adjust</strong>
                            <p><strong>Purpose:</strong> Analytics, Attribution, and Marketing</p>
                            <p><strong>Data Used:</strong> Advertising data, device ID</p>
                            <p><strong>Privacy Note:</strong> Adjust tracks user interaction to provide marketing insights and campaign effectiveness.</p>
                        </li>
                    </ul>

                    <h2>User Consent and Opt-Out Options</h2>
                    <p>Users have the ability to control the collection and use of their data by these third-party services:</p>
                    <ul>
                        <li><strong>Opting Out:</strong> You can opt-out of data collection for marketing purposes by configuring your preferences through our app settings or directly via third-party service settings where applicable.</li>
                        <li><strong>Managing Cookies and Identifiers:</strong> Adjust your device or browser settings to manage or refuse cookies and other identifiers used by these third-party services.</li>
                    </ul>

                    <h2>Third-Party Privacy Policies</h2>
                    <p>We encourage you to review the privacy policies of these third-party services to understand their data handling practices. Our app integrates these services under strict agreements that align with our privacy commitments; however, we do not control their ultimate data practices.</p>

                    <h3 className="my-3 text-[28px] font-normal">7. Changes to our privacy policy</h3>
                    <p>Any changes we may make to our Privacy Policy in the future will be posted on this page. Your continued use of our services constitutes your agreement to this Privacy Policy and any updates.</p>

                    <h3 className="my-3 text-[28px] font-normal">8. Data transfers</h3>
                    <p>We may hold your data on servers in Saudi Arabia and any other territories as we see fit from time to time. We may also transfer your data to our overseas offices or to any people listed at Clause 3.1 above, who may be located in or outside of Saudi Arabia.</p>

                    <h3 className="my-3 text-[28px] font-normal">9. Your consent and rights</h3>
                    <p>9.1 By using our service, making an application or visiting our Website or Application, you consent to the collection and use of your information and other activities as outlined in this policy.</p>
                    <p>9.2 Under the Personal Data Protection Act (the “PDPA“), individuals have the right:</p>
                    <p>(a) To check whether we hold personal data about you and to access such data;</p>
                    <p>(b) To require us to correct as soon as reasonably practicable any data relating to you that is inaccurate;</p>
                    <p>(c) To ascertain our policies and practices in relation to personal data and the kind of personal data held by us; and</p>
                    <p>(d) To object to the use of your personal data for marketing purposes and we shall not use your personal data for marketing purposes after you communicate your objection to us.</p>
                    <p>9.3 You may exercise your opt-out right by notifying us if you wish to object to the use of your personal data for direct marketing purposes. Please send requests for such objections, access to data, correction of data, information regarding policies and practices and kinds of data held, questions or complaints to <a href="mailto:support@Couponesta.co">Support@Couponesta.co</a></p>
                    <p>9.4 You may exercise your “right to be forgotten” by notifying us if you wish to request the deletion of your personal data from the system. Please send a request to <a href="mailto:support@Couponesta.co">Support@Couponesta.co</a> regarding this matter.</p>
                </div>

        }


    </>
}
