import React from 'react'
import NavBar from '../NavBar/NavBar.jsx'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer/Footer.jsx'

export default function Layout() {
    return <>
        <div className="container">
            <NavBar />

            <div className="mt-[76px] xl:mt-20">

                <Outlet></Outlet>
            </div>

            <Footer />
        </div>
    </>
}
