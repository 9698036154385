import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FallingLines } from 'react-loader-spinner'
import { UserContext } from '../../Context/UserContext.js';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';

export default function LoginWithPhone() {

    const { t, i18n } = useTranslation();
    let lng = cookies.get("i18next") || "en"
    let isRtl = document.documentElement.dir === 'rtl';

    let { setRefresh_token_website, setUserData } = useContext(UserContext)
    let navigate = useNavigate()
    const [error, setError] = useState(null)
    const [logging, setLogging] = useState(false)

    async function submitLogin(value) {

        try {

            setLogging(true)

            let { data } = await axios.post(`${process.env.REACT_APP_BACK_END}auth/loginPhone`, value)
                .catch((err) => {
                    setError(err.response.data.message)
                    setLogging(false)
                })

            if (data.message === 'success') {
                setLogging(false)
                localStorage.setItem('refresh_token_website', data.refresh_token)
                setRefresh_token_website(data.refresh_token)
                setUserData(data.refresh_token)

                toast.success(t("LoginSuccessTitle"))

                navigate('/')
            }

        } catch (error) {

        }

    }

    let validateSchema = Yup.object({
        countryCode: Yup.string().required("country code is required"),
        phoneNumber: Yup.string().required("phone Number is required"),
        password: Yup.string().min(6, 'Password must with character and minLength 6').required('password is required'),
    })

    let formik = useFormik({
        initialValues: {
            countryCode: 'EG',
            phoneNumber: '',
            password: '',
        }, validationSchema: validateSchema,
        onSubmit: submitLogin
    })


    // to show text whe type password
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };




    // Sample country code data
    const countryCodes = [
        { code: '+20', countryCode: 'EG', country: 'Egypt' },
        { code: '+971', countryCode: 'AE', country: 'United Arab Emirates' },
        { code: '+1', countryCode: 'US', country: 'United States' },
        { code: '+7', countryCode: 'RU', country: 'Russia' },
        { code: '+33', countryCode: 'FR', country: 'France' },
        { code: '+44', countryCode: 'GB', country: 'United Kingdom' },
        { code: '+49', countryCode: 'DE', country: 'Germany' },
        { code: '+81', countryCode: 'JP', country: 'Japan' },
        { code: '+86', countryCode: 'CN', country: 'China' },
        { code: '+91', countryCode: 'IN', country: 'India' },
        { code: '+92', countryCode: 'PK', country: 'Pakistan' },
        { code: '+254', countryCode: 'KE', country: 'Kenya' },
        { code: '+355', countryCode: 'AL', country: 'Albania' },
        { code: '+213', countryCode: 'DZ', country: 'Algeria' },
        { code: '+376', countryCode: 'AD', country: 'Andorra' },
        { code: '+244', countryCode: 'AO', country: 'Angola' },
        { code: '+672', countryCode: 'AQ', country: 'Antarctica' },
        { code: '+54', countryCode: 'AR', country: 'Argentina' },
        { code: '+374', countryCode: 'AM', country: 'Armenia' },
        { code: '+297', countryCode: 'AW', country: 'Aruba' },
        { code: '+61', countryCode: 'AU', country: 'Australia' },
        { code: '+43', countryCode: 'AT', country: 'Austria' },
        { code: '+994', countryCode: 'AZ', country: 'Azerbaijan' },
        // Add more country codes as needed
    ];


    return <>

        <Helmet>
            <meta name='description' content='Login With Phone Page' charSet="utf-8" />
            <title>Login With Phone</title>
        </Helmet>

        <div className="px-2">

            <form className="space-y-5" onSubmit={formik.handleSubmit}>


                <div className="flex justify-between align-middle row-span-2 gap-x-2">

                    <div className='w-1/8 md:w-1/2'>
                        <label htmlFor="countryCode" className="block text-sm font-bold  leading-6 text-gray-900 cursor-pointer">
                            {t("CountryCodeTitle")} <span className='text-red-600 font-extrabold'>*</span>
                        </label>
                        <div className="mt-2">
                            <select
                                id="countryCode"
                                name="countryCode"
                                value={formik.values.countryCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="block w-full cursor-pointer rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 pe-s pe-1 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"

                            >
                                <option value="" disabled>{t("SelectCountryTitle")}</option>
                                {countryCodes.map((country, index) => (
                                    <option key={index} value={country.countryCode}>{country.country} ({country.code})</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='w-full'>
                        <label htmlFor="phoneNumber" className="block text-sm font-bold  leading-6 text-gray-900 cursor-pointer">
                            {t("PhoneNumberTitle")} <span className='text-red-600 font-extrabold'>*</span>
                        </label>
                        <div className="mt-2">
                            <input
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.phoneNumber}
                                id='phoneNumber'
                                name='phoneNumber'
                                type='text'
                                autoComplete="phoneNumber"
                                className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                            />

                        </div>
                    </div>
                </div>

                {formik.errors.phoneNumber && formik.touched.phoneNumber ? <div className="py-3 mt-1.5 px-2 md:px-6 bg-red-400 text-red-100 rounded-3xl">{formik.errors.phoneNumber}</div> : ''}
                {formik.errors.countryCode && formik.touched.countryCode ? <div className="py-3 mt-1.5 px-2 md:px-6 bg-red-400 text-red-100 rounded-3xl">{formik.errors.countryCode}</div> : ''}


                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-bold  leading-6 text-gray-900 cursor-pointer">
                            {t("PasswordTitle")} <span className='text-red-600 font-extrabold'>*</span>
                        </label>
                        <div className="text-sm">
                            <Link to={'/forgetPassword/withPhone'} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                {t("ForgotPassTitle")}
                            </Link>
                        </div>
                    </div>
                    <div className="mt-2 relative">

                        <i
                            className={`absolute ltr:right-3 rtl:left-4 top-4 ${showPassword ? "fa-solid fa-eye text-gray-700 cursor-pointer"
                                : "fa-solid fa-eye-slash cursor-pointer"}`}
                            onClick={togglePasswordVisibility}
                        ></i>

                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                        />
                        {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 md:px-6 bg-red-400 text-red-100 rounded-3xl">{formik.errors.password}</div> : ''}

                    </div>
                </div>

                {logging ? <button type='button' className='flex w-full justify-center rounded-3xl'>

                    <FallingLines
                        width="50"
                        radius="9"
                        color="#3b35b4"
                        visible={true}
                        ariaLabel="falling-circles-loading"
                    />
                </button> :
                    <div>
                        <button

                            // disabled
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="hover:translate-y-1 border-[1px] border-gray-300 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] flex w-full justify-center cursor-pointer rounded-3xl bg-indigo-600 p-3 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-indigo-400 disabled:text-gray-200 text-white shadow-sm active:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {t("SingInTitle")}
                        </button>
                    </div>
                }

                {error ? <div className="py-3 px-2 bg-red-400 text-red-50 rounded-3xl text-center mx-auto">{error}</div> : ''}



            </form>

        </div>
    </>

}
