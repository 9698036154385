import axios from "axios";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";

// Follow function
export async function Follow(brandId, navigate) {
    try {
        if (localStorage.getItem('refresh_token_website') != null && localStorage.getItem('refresh_token_website') != undefined) {
            let response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                },
            });

            if (response?.data?.message === 'success') {
                toast.success("Added Success To Followed Stores");
            }
        } else {
            navigate('/login');
        }
    } catch (error) {
        // console.error("An error occurred while following the brand", error);
    }
}

// RemoveFollow function
export async function RemoveFollow(brandId, navigate) {
    try {
        if (localStorage.getItem('refresh_token_website') != null && localStorage.getItem('refresh_token_website') != undefined) {
            let response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                },
            });

            if (response?.data?.message === 'success') {
                toast.success("Removed Success from Followed Stores");
            }
        } else {
            navigate('/login');
        }
    } catch (error) {
        // console.error("An error occurred while removing the follow", error);
    }
}

// Favorite function
export async function Favorite(couponId, navigate) {
    try {
        if (localStorage.getItem('refresh_token_website') != null && localStorage.getItem('refresh_token_website') != undefined) {
            let response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/favorite/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                },
            });

            if (response?.data?.message === 'success') {
                return toast.success("Added Success To Favorite Coupons");
            }
        } else {
            navigate('/login');
        }
    } catch (error) {
        // console.error("An error occurred while following the brand", error);
    }
}

// RemoveFavorite function
export async function RemoveFavorite(couponId, navigate) {
    try {
        if (localStorage.getItem('refresh_token_website') != null && localStorage.getItem('refresh_token_website') != undefined) {
            let response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/favorite/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                },
            });

            if (response?.data?.message === 'success') {
                return toast.success("Removed Success from Favorite Coupons");
            }
        } else {
            navigate('/login');
        }
    } catch (error) {
        // console.error("An error occurred while removing the follow", error);
    }
}


export default function ProtectedRoute(props) {

    if (localStorage.getItem('refresh_token_website') && localStorage.getItem('refresh_token_website').trim() != '' && localStorage.getItem('refresh_token_website') != null && localStorage.getItem('refresh_token_website') != undefined) {

        return props.children

    } else {

        return <Navigate to={'/login'} />
    }
}
