import React, { useState } from 'react';
import store from '../../Assets/images/stores.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { BallTriangle, FallingLines } from 'react-loader-spinner';
import Slider from 'react-slick';
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

export default function Stores() {

    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('Most used'); // State for selected filter
    const [selectedCategory, setSelectedCategory] = useState('All Categories');
    const [searchQuery, setSearchQuery] = useState(""); // State for search query

    const handleMouseEnterFilter = () => {
        setIsOpenFilter(true);
    };

    const handleMouseLeaveFilter = () => {
        setIsOpenFilter(false);
    };

    const ToggleClickFilter = () => {
        setIsOpenFilter(!isOpenFilter);
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
        setIsOpenFilter(false);
    };

    let locDetails = JSON.parse(localStorage.getItem('locCouponesta'));

    async function getCategory() {
        return await axios.get(`${process.env.REACT_APP_BACK_END}category/${lng}?location=${locDetails._id}`);
    }

    let { data } = useQuery(['Category', lng], getCategory, {
        cacheTime: 9000000
    });

    let allCategory = [];
    if (data?.data?.message === "success") {
        allCategory = data?.data?.categories;
    }

    async function getData() {
        return await axios.get(`${process.env.REACT_APP_BACK_END}coupon/allCount/database`);
    }

    let { data: allDataDataBase } = useQuery(['allData'], getData, {
        cacheTime: 9000000
    });

    let allData = [];
    if (allDataDataBase?.data?.message === "success") {
        allData = allDataDataBase?.data?.data[2];
    }

    // console.log(selectedFilter);

    async function getBrands(searchQuery, selectedCategory, selectedFilter) {
        const categoryParam = selectedCategory === "All Categories" ? "" : `&category=${selectedCategory?._id}`;

        let sortParam = ""
        if (selectedFilter === "Most used") {
            sortParam = "&sort=-mostUsed"
        }
        else if (selectedFilter === "Most followed") {
            sortParam = "&sort=-mostFollowed"
        }
        else if (selectedFilter === "alphabet A-Z") {
            sortParam = "&sort=name"
        }
        else if (selectedFilter === "alphabet Z-A") {
            sortParam = "&sort=-name"
        }
        else if (selectedFilter === "Recently added") {
            sortParam = "&sort=-createdAt"
        }

        return await axios.get(`${process.env.REACT_APP_BACK_END}brand/${lng}?location=${locDetails._id}&search=${searchQuery}${categoryParam}${sortParam}`);
    }

    let brandData = useQuery(['Brand', searchQuery, selectedCategory, selectedFilter, lng], () => getBrands(searchQuery, selectedCategory, selectedFilter), {
        cacheTime: 9000000
    });

    // console.log(brandData?.data?.data?.brandCount);

    let allBrands = [];
    if (brandData.data?.data?.message === "success") {
        allBrands = brandData?.data?.data?.brands;
    }

    let isRtl = document.documentElement.dir === 'rtl';


    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: false,
        centerMode: false,
        speed: 800,
        slidesToShow: isRtl ? 6 : 5,
        slidesToScroll: 2,
        variableWidth: false,
        rtl: isRtl
    };


    // user followed brand
    const navigate = useNavigate();

    let tokenInUser = localStorage.getItem('refresh_token_website');
    let decoded = {};
    if (tokenInUser) {
        decoded = jwtDecode(localStorage.getItem('refresh_token_website'));
    }

    const [loadingBrands, setLoadingBrands] = useState({});

    const handleFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await brandData?.refetch();
                toast.success(t("AddFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };

    const handleRemoveFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await brandData?.refetch();
                toast.success(t("RemoveFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };



    return <>

        <Helmet>
            <meta name='Store' content='Store Couponesta Page' charSet="utf-8" />
            <title>Store Couponesta</title>
        </Helmet>

        <div className="store-bac w-full pt-8 xl:pt-14 px-20 xl:px-36 pb-6">

            <div className="flex flex-wrap w-full items-center justify-center md:justify-between">
                <div>
                    <h2 className="mb-4 text-header-2 font-bold text-5xl">{t("StoreTitle")}</h2>
                    <p className="w-[350px] xl:w-96 text-xl font-normal">{t("StoreTitleHeader1")} <span>{allData?.count}</span> {t("StoreTitleHeader2")}</p>
                </div>

                <img src={store} alt="stores-logos" className="my-auto w-auto mt-5 rounded-lg" />
            </div>

        </div>

        <div className="hidden xl:block container mx-auto mt-8 px-20 pb-6 overflow-auto">

            <div className="flex flex-row justify-between items-start gap-x-14">

                <div className="basis-1/4 bg-[#d5c9c948] py-3 ps-4 rounded-xl border border-neutral-300">
                    <ul className='space-y-2 text-sm transition delay-150 duration-300 ease-in-out'>
                        <li
                            className={`ps-2 border-r-4 cursor-pointer rounded-b-x rounded-l-0 mb-2 ml-2 flex h-12 items-center rounded-l-xl py-3.5 ltr:pl-3 rtl:pr-3 
                                ${selectedCategory === 'All Categories' ?
                                    'border-blue-600 text-blue-600 bg-white' :
                                    'hover:border-blue-600 hover:text-blue-600 hover:bg-white text-blue-500 border-transparent'
                                }`}
                            onClick={() => setSelectedCategory('All Categories')}
                        >
                            <img
                                src="https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fcategory.svg&w=1536&q=100"
                                alt=""
                            />
                            <h5 className="ltr:ml-3 rtl:mr-3 text-base font-medium ms-2">{t("AllCategoriesTitle")}</h5>
                        </li>

                        {allCategory.map((category, ind) => (
                            <li
                                key={ind}
                                className={`ps-2 cursor-pointer mb-2 ml-2 flex h-12 items-center rounded-l-xl py-3.5 ltr:pl-3 rtl:pr-3
                                    ${selectedCategory.name === category.name
                                        ? 'border-r-4 border-blue-600 text-blue-600 bg-white'
                                        : 'hover:border-blue-600 hover:text-blue-600 hover:bg-white text-blue-500 border-transparent'
                                    }`}
                                onClick={() => setSelectedCategory(category)}
                            >
                                <div className='' dangerouslySetInnerHTML={{ __html: category.icon }}></div>
                                <h5 className="ltr:ml-3 rtl:mr-3 text-base font-medium ms-2">{category.name}</h5>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="basis-3/4 py-3 ps-4">
                    <div className="flex justify-between items-center">
                        <div className="relative mt-1">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                            </div>
                            <input type="search"
                                id="table-search"
                                className="block py-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-xl w-52 sm:w-56 lg:w-96 bg-[#f6f6f67a] focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                                placeholder={t("SearchTitle") || "Search for Brands..."}
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="relative flex items-center align-middle text-left"
                            onMouseEnter={handleMouseEnterFilter}
                            onMouseLeave={handleMouseLeaveFilter}
                            onClick={ToggleClickFilter}
                        >
                            <button
                                id="dropdownDividerButton"
                                type="button"
                                className="text-start font-medium text-sm inline-flex items-center cursor-pointer rounded-full border-2 bg-[#ffffff2f] border-neutral-300 px-4 py-2"
                            >
                                <i className='fa-solid fa-filter fa-lg '></i>
                                <span className='ms-2'>{t("SortedByTitle")}: {t(selectedFilter)}</span>
                            </button>

                            {isOpenFilter && (
                                <div
                                    id="dropdownDivider"
                                    className="z-10 absolute top-9 mt-0.5 w-52 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                    onMouseEnter={handleMouseEnterFilter}
                                    onMouseLeave={handleMouseLeaveFilter}
                                    onClick={ToggleClickFilter}
                                >
                                    <ul
                                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownDividerButton"
                                    >
                                        {['Recently added', 'Most followed', 'Most used', 'alphabet A-Z', 'alphabet Z-A'].map((filter) => (
                                            <li key={filter}>
                                                <button
                                                    className="w-full text-start flex justify-start align-middle px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                    onClick={() => handleFilterClick(filter)}
                                                >
                                                    {t(filter)}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                    </div>

                    {brandData.isLoading ? (
                        <div className="loading">
                            <BallTriangle
                                height={100}
                                width={100}
                                radius={5}
                                color="#199A8E"
                                ariaLabel="ball-triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : allBrands.length === 0 ? (
                        <div className='flex justify-center align-middle p-12 text-teal-800'>
                            <h1 className='font-bold'>{t("NoFoundBrandTitle")}</h1>
                        </div>
                    ) : (
                        <div className="flex flex-wrap justify-between items-center mt-14 gap-x-1">

                            {allBrands.map((brand, ind) => (
                                <div key={ind}
                                    className="basis-[100%] xl:basis-[49%] px-4 py-5 mb-6 flex -translate-y-8 transform cursor-pointer items-center justify-between rounded-3xl border border-neutral-400 bg-white p-5 w-96 md:w-[49%]"
                                >
                                    <Link to={`/store/${brand._id}`} className="flex flex-row justify-start items-center w-full">
                                        <img
                                            src={brand.image || brand}
                                            alt="brand img"
                                            className='w-[30px] basis-1/6 rounded-full border border-neutral-100 ltr:mr-3 rtl:ml-3' />
                                        <div className="flex justify-between items-center w-full">

                                            <div className="ms-3">
                                                <h4 className="mb-2 font-head text-base font-bold text-neutral-1000">{brand.name}</h4>
                                                <div className="flex items-center">
                                                    <p className="text-sm font-normal text-neutral-650 line-clamp-2">{brand?.description}</p>
                                                </div>
                                            </div>


                                        </div>
                                    </Link>

                                    {
                                        loadingBrands[brand._id] ?
                                            <FallingLines
                                                color="#1a56db"
                                                width="60"
                                                visible={true}
                                                ariaLabel="falling-circles-loading"
                                            />
                                            :
                                            brand?.userFollowed.includes(decoded?.id) ?
                                                <div className="flex items-center"
                                                    onClick={() => handleRemoveFollow(brand?._id)}
                                                >
                                                    <div className="flex items-center justify-center w-40 ms-1 py-3 px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-white bg-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                        <i className="fa-solid fa-star"></i>
                                                        <button className='ms-1'>
                                                            {t('IsFollowTitle')}
                                                        </button>
                                                    </div>

                                                </div>
                                                :
                                                <div className="flex items-center"
                                                    onClick={() => handleFollow(brand?._id)}
                                                >
                                                    <div className="flex items-center justify-center ms-2 py-2.5 px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                        <i className="fa-regular fa-star"></i>
                                                        <button className='ms-1'>
                                                            {t('FollowTitle')}
                                                        </button>
                                                    </div>

                                                </div>
                                    }

                                </div>
                            ))}

                        </div>
                    )}

                </div>

            </div>

        </div>

        <div className="block xl:hidden container slikkk overflow-hidden pb-6" >

            {/* all category */}
            <div className="px-2 my-2 mt-3">
                <Slider
                    {...settings}
                >
                    <div
                        className={`me-5 ${selectedCategory === 'All Categories' ?
                            'text-blue-600 font-extrabold' :
                            'focus:text-blue-600 text-black font-bold'
                            }`}
                        onClick={() => setSelectedCategory('All Categories')}
                    >
                        <img
                            src="https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fcategory.svg&w=1536&q=100"
                            alt=""
                            className={`w-12 p-1 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center
                                 ${selectedCategory === 'All Categories' ?
                                    'bg-gray-300' :
                                    'focus:text-blue-600 bg-gray-200 focus:bg-gray-200 text-black'
                                }`}
                        />
                        <p className='font-serif mt-1 ms-1.5 w-fit'>{t("AllTitle")}</p>

                    </div>

                    {/* category */}
                    {allCategory.map((category, ind) => {
                        {/* category */ }
                        return <div key={ind}
                            className={`flex me-3 flex-col justify-center items-center
                                 ${selectedCategory.name === category.name ?
                                    'text-blue-600 font-extrabold' :
                                    'focus:text-blue-600 text-black font-bold'
                                }`}
                            onClick={() => setSelectedCategory(category)}

                        >
                            <div
                                className={`w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center
                                 ${selectedCategory.name === category.name ?
                                        'bg-gray-300' :
                                        'focus:text-blue-600 bg-gray-200 focus:bg-gray-200 text-black'
                                    }`}
                                dangerouslySetInnerHTML={{ __html: category.icon }}></div>

                            <p className='font-serif mt-1 -ms-1 text-center w-fit'>{category.name}</p>

                        </div>
                    })}

                </Slider>
            </div>

            <div className="w-full bg-neutral-200 rounded-lg py-3 my-4 flex justify-between items-center px-2">

                <p className='text-xl font-semibold'><span className='text-teal-600 font-extrabold'>{brandData?.data?.data?.brandCount}</span> {t("StoreTitle")}</p>

                <div className="relative flex items-center align-middle text-left"
                    onMouseEnter={handleMouseEnterFilter}
                    onMouseLeave={handleMouseLeaveFilter}
                    onClick={ToggleClickFilter}
                >
                    <button
                        id="dropdownDividerButton"
                        type="button"
                        className="text-start font-medium text-sm inline-flex items-center cursor-pointer rounded-full border-2 bg-[#ffffff2f] border-neutral-300 px-4 py-2"
                    >
                        <i className='fa-solid fa-filter fa-lg '></i>
                        <span className='ms-2'>{t("SortedByTitle")}: <span className='text-teal-600 font-bold'>{t(selectedFilter)}</span></span>
                    </button>

                    {isOpenFilter && (
                        <div
                            id="dropdownDivider"
                            className="z-10 absolute top-10 left-3 w-40 bg-white divide-y-2 divide-gray-300 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                            onMouseEnter={handleMouseEnterFilter}
                            onMouseLeave={handleMouseLeaveFilter}
                            onClick={ToggleClickFilter}
                        >
                            <ul
                                className="py-1 text-sm text-gray-700 dark:text-gray-200"
                                aria-labelledby="dropdownDividerButton"
                            >
                                {['Recently added', 'Most followed', 'Most used', 'alphabet A-Z', 'alphabet Z-A'].map((filter) => (
                                    <li key={filter}>
                                        <button
                                            className="w-full text-start flex justify-start align-middle px-4 py-2 focus:bg-gray-300 dark:focus:bg-gray-600 dark:focus:text-white  hover:bg-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                            onClick={() => handleFilterClick(filter)}
                                        >
                                            {t(filter)}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

            </div>


            <div className="px-3 mb-8">

                {brandData.isLoading ? (
                    <div className="loading">
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#199A8E"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : allBrands.length === 0 ? (
                    <div className='flex justify-center align-middle p-12 text-teal-800'>
                        <h1 className='font-bold'>{t("NoFoundBrandTitle")}</h1>
                    </div>
                ) : (
                    <div className="flex flex-wrap justify-between items-center mt-14 px-5 gap-1">

                        {allBrands.map((brand, ind) => (
                            <div key={ind}
                                className="basis-[100%] xl:basis-[49%] px-4 py-3.5 mb-6 flex -translate-y-8 transform cursor-pointer items-center justify-between rounded-3xl border border-neutral-400 bg-white p-5 w-96 md:w-[49%]"
                            >
                                <Link to={`/store/${brand._id}`} className="flex flex-row justify-start items-center w-full">
                                    <img
                                        src={brand.image || brand}
                                        alt="brand img"
                                        className='w-[40px] basis-1/8 rounded-full border border-neutral-100 ltr:mr-3 rtl:ml-3' />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="ms-3 basis-3/4">
                                            <h4 className="mb-2 font-head text-base font-bold text-neutral-1000">{brand.name}</h4>
                                            <div className="flex items-center">
                                                <p className="text-sm font-normal text-neutral-650 line-clamp-2">{brand?.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>


                                {
                                    loadingBrands[brand._id] ?
                                        <FallingLines
                                            color="#1a56db"
                                            width="60"
                                            visible={true}
                                            ariaLabel="falling-circles-loading"
                                        />
                                        :
                                        brand?.userFollowed.includes(decoded?.id) ?
                                            <div className="flex items-center"
                                                onClick={() => handleRemoveFollow(brand?._id)}
                                            >
                                                <div className="flex items-center justify-center w-32 ms-1 py-3 px-2 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-white bg-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                    <i className="fa-solid fa-star"></i>
                                                    <button className='ms-1 text-sm'>
                                                        {t('IsFollowTitle')}
                                                    </button>
                                                </div>

                                            </div>
                                            :
                                            <div className="flex items-center"
                                                onClick={() => handleFollow(brand?._id)}
                                            >
                                                <div className="flex items-center justify-center ms-1 py-2.5 px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                    <i className="fa-regular fa-star"></i>
                                                    <button className='ms-1'>
                                                        {t('FollowTitle')}
                                                    </button>
                                                </div>

                                            </div>

                                }



                            </div>
                        ))}

                    </div>
                )}

            </div>

        </div >

    </>
}

