import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import logo from '../../Assets/images/couponesta.jpg'
import icon from '../../Assets/images/icon Coupon.png'
import category from '../../Assets/images/categories.svg'
import eg from '../../Assets/images/eg.jpg'
import account from '../../Assets/images/account.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/UserContext.js';
import cookies from 'js-cookie'


export default function NavBar() {

    const { t, i18n } = useTranslation();

    if (cookies.get('i18next') === "dev") {
        cookies.set('i18next', 'en');
    }

    let lng = cookies.get("i18next") || "en"

    useEffect(() => {
        window.document.dir = i18n.dir()
    }, [lng])

    async function getLocation() {

        return await axios.get(`${process.env.REACT_APP_BACK_END}location`)
    }

    let { isLoading, data, refetch } = useQuery(['Location'], () => getLocation(), {
        cacheTime: 9000000
    })

    let allLocation = []

    if (data?.data?.message === "success") {

        allLocation = data?.data?.location

        if (localStorage.getItem('locCouponesta') === null || localStorage.getItem('locCouponesta') === "") {

            localStorage.setItem('locCouponesta', JSON.stringify(allLocation[0]));
        }

    }


    function sendLocation(location) {

        const newLocation = location || JSON.parse(localStorage.getItem('locCouponesta')); // Use localStorage value if location is not provided

        localStorage.setItem('locCouponesta', JSON.stringify(newLocation));


        window.location.reload();

    }

    const [locDetails, setLocDetails] = useState(() => {
        try {
            const storedValue = localStorage?.getItem('locCouponesta');
            return JSON.parse(storedValue) || allLocation[0]
        } catch (e) {
            // console.error("Error parsing locCouponesta from localStorage:", e);
            // Clear the invalid value from localStorage
            localStorage.removeItem('locCouponesta');
            return allLocation[0]
        }
    });



    useEffect(() => {
        const handleStorageChange = () => {
            const updatedLocDetails = JSON?.parse(localStorage?.getItem('locCouponesta')) || {};
            setLocDetails(updatedLocDetails);
        };

        // Add event listener for storage changes
        window?.addEventListener('storage', handleStorageChange);

        // Clean up the event listener on component unmount
        return () => {
            window?.removeEventListener('storage', handleStorageChange);
        };
    }, []);



    // dropdown Category
    const [isOpenCategory, setIsOpenCategory] = useState(false);

    const handleMouseEnterCategory = () => {
        setIsOpenCategory(true);
    };

    const handleMouseLeaveCategory = () => {
        setIsOpenCategory(false);
    };

    const ToggleClickCategory = () => {
        setIsOpenCategory(!isOpenCategory);
    };


    async function getCategory() {

        return await axios.get(`${process.env.REACT_APP_BACK_END}category/${lng}?location=${locDetails._id}`)
    }

    let categoryData = useQuery(['Category', lng], () => getCategory(), {
        cacheTime: 9000000
    })


    let allCategory = []

    if (categoryData?.data?.data?.message === "success") {

        allCategory = categoryData?.data?.data?.categories
    }

    // console.log(allCategory);


    // search

    // State for search query
    const [searchQuery, setSearchQuery] = useState("");

    // State for dropdown search
    const [isOpenSearch, setIsOpenSearch] = useState(false);

    // Function to handle input change
    const handleInputChange = (event) => {
        const value = event?.target?.value;

        setSearchQuery(value);

        // Open dropdown if there's a search query, otherwise close it
        if (value) {
            setIsOpenSearch(true);
        } else {
            setIsOpenSearch(false);
        }
    };

    // Function to fetch brands
    async function getBrands(searchQuery) {
        const response = await axios.get(`${process.env.REACT_APP_BACK_END}brand/${lng}?location=${locDetails._id}&search=${searchQuery}`);

        return response.data;
    }

    // Use React Query to fetch data
    const { data: brandsData, isLoading: loadingBrand } = useQuery(['Brands', searchQuery, lng], () => getBrands(searchQuery), {
        cacheTime: 9000000,
        enabled: !!searchQuery, // Only fetch when there is a search query
    });

    // Extract brand data if available
    const allBrands = brandsData?.message === "success" ? brandsData?.brands : [];

    // end search



    // dropdown country
    const [isOpenCountry, setIsOpenCountry] = useState(false);

    const handleMouseEnterCountry = () => {
        setIsOpenCountry(true);
    };

    const handleMouseLeaveCountry = () => {
        setIsOpenCountry(false);
    };

    const ToggleClickCountry = () => {
        setIsOpenCountry(!isOpenCountry);
    };


    // dropdown lang
    const [isOpenLang, setIsOpenLang] = useState(false);

    const handleMouseEnterLang = () => {
        setIsOpenLang(true);
    };

    const handleMouseLeaveLang = () => {
        setIsOpenLang(false);
    };


    const ToggleClickLang = () => {
        setIsOpenLang(!isOpenLang);
    };

    // dropdown Account
    const [isOpenAcc, setIsOpenAcc] = useState(false);

    const handleMouseEnterAcc = () => {
        setIsOpenAcc(true);
    };

    const handleMouseLeaveAcc = () => {
        setIsOpenAcc(false);
    };

    const ToggleClickAcc = () => {
        setIsOpenAcc(!isOpenAcc);
    };


    // small screen navigation
    const location = useLocation();

    const isActiveHome = location.pathname === '/';
    const isActiveExplore = location.pathname === '/store';
    const isActiveFavorite = location.pathname === '/favorite';
    const isActiveProfile = location.pathname === '/profile';
    const isActiveStore = location.pathname === '/store';


    // language 
    const [selectedLanguage, setSelectedLanguage] = useState(lng || 'en'); // State to store the selected language

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language || 'en')
    };

    const isArabicSelected = selectedLanguage === 'ar';
    const isEnglishSelected = selectedLanguage === 'en';


    // Function to fetch coupon

    const [categoryId, setCategoryId] = useState(allCategory[0]?._id)

    async function getCoupons(categoryId = "66154cce5b8c0301ee22b5de") {

        const response = await axios.get(`${process.env.REACT_APP_BACK_END}coupon?category=${categoryId}`);
        return response;
    }

    let couponCount = useQuery(['CouponNaveBar', categoryId], () => getCoupons(categoryId), {
        cacheTime: 9000000
    })

    let numberOfCoupon = 0

    if (couponCount?.data?.data?.message === "success") {

        numberOfCoupon = couponCount?.data?.data?.couponCount
    }


    // log out

    let { setRefresh_token_website } = useContext(UserContext)
    let navigate = useNavigate()

    function logOut() {
        localStorage.removeItem('refresh_token_website');
        setRefresh_token_website(null)
        // setUserData(null)
        navigate('/login')
    }

    // Account
    const [toggleAccount, setToggleAccount] = useState(false)

    let tokenInUser = localStorage.getItem('refresh_token_website')

    async function getLoggedUser() {

        try {

            if (localStorage.getItem('refresh_token_website') &&
                localStorage.getItem('refresh_token_website') != null &&
                localStorage.getItem('refresh_token_website') != undefined &&
                localStorage.getItem('refresh_token_website').trim() != '') {

                setToggleAccount(true);

                try {

                    let response = await axios.get(`${process.env.REACT_APP_BACK_END}user/loggedUser/data`, {
                        headers: {
                            authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                        }
                    });

                    return response?.data;

                } catch (error) {

                }

            } else {

                setToggleAccount(false);
                // navigate('/login');
            }


        } catch (error) {

        }
    }

    let userDataLogged = useQuery(['userLogged', tokenInUser], () => getLoggedUser(), {
        cacheTime: 900,
        refetchInterval: 1000
    })

    let userAccount = {}

    if (userDataLogged?.data?.message === "success") {

        userAccount = userDataLogged?.data?.user[0]
    }


    return <>

        {/* large screen */}
        <nav className="z-50 container hidden xl:block bg-white pt-4 pb-2 xl:px-6 2xl:px-24 mx-auto fixed top-0 left-0 right-0 border-b-2 border-gray-300">

            <div className="w-full h-full gap-x-6 flex flex-row justify-center items-center align-middle">

                <Link to={'/'}>
                    <img src={logo} alt="couponesta" className='w-20 rounded-md cursor-pointer' />
                </Link>

                {/* category */}
                <div className="relative flex justify-center align-middle">
                    <Link
                        to={'/'}
                        className="flex justify-center align-middle"
                        onMouseEnter={handleMouseEnterCategory}
                        onMouseLeave={handleMouseLeaveCategory}
                        onClick={ToggleClickCategory}
                    >
                        <img src={category} alt="couponesta" className='w-6 me-1' />
                        <button
                            className='text-base font-medium text-neutral-800 hover:text-gray-800'>
                            {t("CategoryTitle")}
                        </button>
                    </Link>

                    {/*hear category display  */}
                    {/* Dropdown menu */}
                    {
                        isOpenCategory && (

                            <div
                                id="dropdownDivider"
                                className="z-20 absolute top-[34px] ltr:left-6 rtl:right-6 w-[600%] bg-white divide-y divide-gray-100  rounded-xl shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                onMouseEnter={handleMouseEnterCategory}
                                onMouseLeave={handleMouseLeaveCategory}
                            >
                                <div className="p-2 px-4 relative border-2 border-gray-300 rounded-xl grid grid-cols-3 gap-x-4 gap-y-2">
                                    {/* one Category */}

                                    <div className="absolute -top-3 ltr:-left-0.5 px-6 py-2.5 border-t-2 ltr:border-l-2 border-gray-300 rounded-t-xl bg-white"></div>

                                    {
                                        allCategory?.map((category, i) => <Link

                                            // onMouseMove={() => setCategoryId(category?._id)}
                                            to={`/categories/${category._id}`}
                                            key={i}
                                            className='flex mt-1 items-center bg-white'
                                        >
                                            {/* icon */}
                                            <div className='p-1 bg-neutral-100 rounded-full' dangerouslySetInnerHTML={{ __html: category.icon }}></div>

                                            <div className="flex flex-col align-middle justify-start items-start ms-3">
                                                <h2 className='font-semibold'>{category?.name}</h2>
                                                {/* <p className='text-neutral-400 text-nowrap'>+ {numberOfCoupon} coupons</p> */}
                                            </div>

                                        </Link>)
                                    }


                                </div>

                            </div>
                        )
                    }

                </div>


                <Link to={'/'}
                    className={`text-base flex items-center font-medium ${isActiveHome ? 'text-blue-600 border-b-2 border-blue-600' : 'text-neutral-800'} hover:text-blue-600 hover:border-b-2 hover:border-blue-600`}
                >
                    {t("HomeTitle")}

                </Link>

                <Link to={'/store'}
                    className={`text-base flex items-center font-medium ${isActiveStore ? 'text-blue-600 border-b-2 border-blue-600' : 'text-neutral-800'} hover:text-blue-600 hover:border-b-2 hover:border-blue-600`}
                >
                    {t("StoreTitle")}
                </Link>

                <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>

                {/* search */}
                <div className="relative">

                    {/* Search input */}
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className="fa fa-search w-4 text-gray-500 dark:text-gray-400" aria-hidden="true"></i>
                    </div>
                    <input
                        type="search"
                        id="table-search"
                        className="block py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-xl w-52 sm:w-56 xl:w-80 2xl:w-96 bg-gray-100 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                        placeholder={t("SearchTitle") || "Search for Brands..."}
                        value={searchQuery}
                        onChange={handleInputChange}
                    />

                    {/* Dropdown menu */}
                    {isOpenSearch && !loadingBrand && (

                        <div
                            id="dropdownDivider"
                            className="z-20 absolute sm:px-2 xl:px-3 top-12 pt-3 left-0 right-0 bg-white rounded-md shadow-lg dark:bg-gray-700 dark:divide-gray-600"

                        >
                            <h1 className='mb-2 font-serif text-neutral-600 font-semibold'><span>{allBrands?.length}</span> {t("FoundTitle")}</h1>

                            <div
                                className='divide-y divide-gray-200'
                            >

                                {allBrands.length > 0 ? (
                                    allBrands.map((brand, i) => (

                                        <Link
                                            to={`/store/${brand._id}`}
                                            key={i}
                                            className="flex mt-1 items-center bg-white"
                                            onClick={() => setIsOpenSearch(false)}
                                        >
                                            <div className="flex items-center w-full py-2">

                                                <img src={brand?.image} alt={brand?.name} className='row-span-2 w-12' />


                                                <div className="flex justify-between w-full items-center">

                                                    <div className="flex flex-col align-middle ms-3">
                                                        <span className='font-extrabold'>{brand?.name}</span>
                                                        {/* <span className='text-neutral-500 text-sm font-semibold'>Up to <span>2</span> Coupons</span> */}
                                                    </div>

                                                    <i className="text-neutral-500 fa-solid fa-arrow-right -rotate-45"></i>

                                                </div>

                                            </div>


                                        </Link>

                                    ))

                                ) : (

                                    <p className='font-serif text-neutral-600'>{t("NoFoundBrandTitle")}</p>
                                )}
                            </div>

                        </div>

                    )}

                </div>

                <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>

                {/* location */}
                <div className="flex flex-row justify-around align-middle">

                    <div className="flex flex-col justify-center align-middle">
                        <img src={locDetails?.image || eg} alt={locDetails?.name} className='w-12 h-8 rounded-lg' />
                    </div>

                    <div className="relative inline-block text-left"
                        onMouseEnter={handleMouseEnterCountry}
                        onMouseLeave={handleMouseLeaveCountry}
                        onClick={ToggleClickCountry}
                    >
                        <button
                            id="dropdownDividerButton"
                            type="button"
                            className="text-start font-medium rounded-lg text-sm ps-5 pe-3 py-2.5 inline-flex items-center"
                        >
                            <span className='text-gray-400'>
                                {t("CountryTitle")}
                                <h2 className='text-black font-bold'>{locDetails?.locationCode}</h2>
                            </span>
                            <svg
                                className="w-2 h-2 ms-8 text-gray-400 hover:text-black"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 4 4 4-4"
                                />
                            </svg>
                        </button>
                        {/* Dropdown menu */}
                        {isOpenCountry && (
                            <div
                                id="dropdownDivider"
                                className="z-10 absolute w-44 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                onMouseEnter={handleMouseEnterCountry}
                                onMouseLeave={handleMouseLeaveCountry}
                            >
                                <ul
                                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                    aria-labelledby="dropdownDividerButton"
                                >
                                    {

                                        allLocation.map((location, ind) => <li key={ind}>
                                            <button
                                                onClick={() => sendLocation(location)}
                                                className="w-full text-start flex justify-start items-center align-middle px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                {/* <span className={`fi fi-${location.locationCode || 'eg'} text-xl rounded-md me-2`}></span> */}
                                                {/* <span className={`fi fi-${code} text-xl rounded-md me-2`}></span> */}

                                                <img src={location?.image || eg} alt={location?.name} className='w-8 h-6 rounded-md me-2' />

                                                <span>{location?.name}</span>
                                            </button>
                                        </li>)
                                    }

                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>


                {/* language */}
                <div className="relative flex items-center align-middle text-left"
                    onMouseEnter={handleMouseEnterLang}
                    onMouseLeave={handleMouseLeaveLang}
                    onClick={ToggleClickLang}
                >
                    <button
                        id="dropdownDividerButton"
                        type="button"
                        className="text-start font-medium rounded-lg text-sm inline-flex items-center"
                    >
                        <i className='fa-solid fa-globe fa-2x'></i>
                    </button>

                    {/* Dropdown menu */}
                    {isOpenLang && (
                        <div
                            id="dropdownDivider"
                            className="z-10 absolute top-6 ltr:-left-3 rtl:-right-3 mt-0.5 w-42 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                            onMouseEnter={handleMouseEnterLang}
                            onMouseLeave={handleMouseLeaveLang}
                        >
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">
                                <li>
                                    <button
                                        onClick={() => handleLanguageChange('ar')}
                                        disabled={isArabicSelected}
                                        className={`w-full text-start flex justify-start align-middle px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${isArabicSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        <span className={`fi fi-sa text-xl rounded-md me-2`}></span>
                                        <span>{t("languageAr")}</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() => handleLanguageChange('en')}
                                        disabled={isEnglishSelected}
                                        className={`w-full flex justify-start align-middle text-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${isEnglishSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        <span className={`fi fi-um text-xl rounded-md me-2`}></span>
                                        <span>{t("languageEn")}</span>
                                    </button>
                                </li>
                            </ul>

                        </div>
                    )}
                </div>

                <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>

                {/*   Account */}
                {
                    toggleAccount ?

                        <div className="relative flex items-center align-middle text-left text-[#061322]"
                            onMouseEnter={handleMouseEnterAcc}
                            onMouseLeave={handleMouseLeaveAcc}
                            onClick={ToggleClickAcc}
                        >
                            <button
                                id="dropdownDividerButton"
                                type="button"
                                className='group relative ml-1 px-3 py-1 cursor-pointer rounded-full border-2 bg-[#F3F3F4] border-neutral-300'
                            >
                                {/* <Link to={'/profile'} className="flex align-middle justify-between items-center"> */}
                                <div className="flex align-middle justify-between items-center">

                                    <img src={userAccount?.image || account} alt="account" className='flex object-cover h-10 w-10 items-center rounded-full p-1 ltr:mr-1 rtl:ml-1' />

                                    <span className='m-0 ms-1.5 line-clamp-1 flex-1 font-bold text-base'>{userAccount?.fullName?.split(' ')?.slice(0, 1)?.join(' ') || "Couponesta"}</span>
                                </div>

                            </button>

                            {/* Dropdown menu */}
                            {isOpenAcc && (
                                <div
                                    id="dropdownDivider"
                                    className="z-10 absolute left-4 top-10 mt-2 overflow-hidden w-56 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                    onMouseEnter={handleMouseEnterAcc}
                                    onMouseLeave={handleMouseLeaveAcc}
                                >
                                    <ul
                                        className="py-2 px-1 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownDividerButton"
                                    >
                                        <li>
                                            <Link
                                                to={'/profile'}

                                                className="cursor-pointer w-full text-start flex justify-start align-middle items-center px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                <img src={userAccount?.image || account} alt="Account Couponesta" className='rounded-full object-cover h-16 w-14 border-2 p-0.5' />

                                                <span className='font-bold text-teal-900 ms-2'>{userAccount?.fullName || "Couponesta"}</span>
                                            </Link>
                                        </li>
                                        <hr className='mx-3' />
                                        <li>
                                            <Link to={'/profile/notification'}

                                                className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >

                                                <i className='fa-solid fa-bell fa-xl relative'>
                                                    <span className='absolute -top-[18px] ltr:-left-[11px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.notification?.length || 0}</span>
                                                </i>

                                                <span className='ms-2'>{t("NotificationsTitle")}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/profile/favorite'}

                                                className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >

                                                <i className='fa-solid fa-heart fa-xl relative'>
                                                    <span className='absolute -top-[18px] ltr:-left-[11px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.favorite?.length || 0}</span>
                                                </i>

                                                <span className='ms-2'>{t("FavoriteTitle")}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/profile/follow'}

                                                className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >

                                                <i className='fa-solid fa-user-plus fa-xl relative'>
                                                    <span className='absolute -top-[18px] ltr:-left-[11px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.follow?.length || 0}</span>
                                                </i>

                                                <span className='ms-2'>{t("FollowTitle")}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/profile'}

                                                className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >

                                                <i className='fa-solid fa-user fa-xl'></i>

                                                <span className='ms-2'>{t("ProfileTitle")}</span>
                                            </Link>
                                        </li>
                                        <hr className='mx-3 my-2' />
                                        <li>
                                            <Link to={'/login'}
                                                onClick={logOut}
                                                className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >

                                                <i className="fa-solid fa-right-from-bracket fa-xl"></i>
                                                <span className='text-sm text-danger-800 ms-2'>{t("LogoutTitle")}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}

                        </div>

                        :
                        // not register or login
                        <button
                            id="NoRegisterButton"
                            type="button"
                            className='group relative ml-3 cursor-pointer rounded-full border-2 bg-[#ffffff2f] border-neutral-300 px-4 py-2'
                        >

                            <Link to={'/login'} className="flex align-middle justify-between">
                                <img src={account} alt="account" className='flex h-6 w-6 items-center rounded-full p-1 ltr:mr-3 rtl:ml-3' />

                                <span className='m-0 ltr:mr-1 rtl:ml-1 line-clamp-1 flex-1 text-base'>{t("LoginTitle")}</span>
                            </Link>
                        </button>

                }

            </div>
        </nav >

        {/* small screen */}
        <div className="block xl:hidden container">

            <nav className="z-50  bg-white pt-4 pb-2 px-0.5 sm:px-0 mx-auto fixed -top-0.5 left-0 right-0 border-b-2 border-gray-300">

                <div className="w-full h-full gap-x-2.5 md:gap-x-4 flex flex-row justify-center items-center align-middle">

                    <Link to={'/'} >
                        <img src={icon} alt="couponesta" className='w-9 md:w-12 rounded-md cursor-pointer' />
                    </Link>

                    <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>


                    {/* search */}
                    <div className="relative">

                        {/* Search input */}
                        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-1.5 sm:ps-3 pointer-events-none">
                            <i className='fa fa-search text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                        </div>

                        <input
                            type="search"
                            id="table-search"
                            className="block py-2.5 ps-6 sm:ps-9 text-sm text-gray-900 border border-gray-300 rounded-xl w-[165px] sm:w-44 md:w-48 bg-gray-100 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder={t("SearchTitle") || "Search for Brands..."}
                            value={searchQuery}
                            onChange={handleInputChange}
                        />

                        {/* Dropdown menu */}
                        {isOpenSearch && !loadingBrand && (

                            <div
                                id="dropdownDivider"
                                className="z-20 absolute px-2 top-12 pt-3 ltr:-left-10 ltr:-right-32 rtl:-right-4 rtl:-left-10 bg-white rounded-md shadow-lg dark:bg-gray-700 dark:divide-gray-600"

                            >
                                <h1 className='mb-2 font-serif text-neutral-600 font-semibold'><span>{allBrands?.length}</span> {t("FoundTitle")}</h1>

                                <div
                                    className='divide-y divide-gray-200'
                                >

                                    {allBrands.length > 0 ? (
                                        allBrands.map((brand, i) => (

                                            <Link
                                                to={`/store/${brand._id}`}
                                                key={i}
                                                className="flex mt-1 items-center bg-white"
                                                onClick={() => setIsOpenSearch(false)}
                                            >
                                                <div className="flex items-center w-full py-2">

                                                    <img src={brand?.image} alt={brand?.name} className='row-span-2 w-12' />

                                                    <div className="flex justify-between w-full items-center">

                                                        <div className="flex flex-col align-middle ms-3">
                                                            <span className='font-extrabold'>{brand?.name}</span>
                                                            {/* <span className='text-neutral-500 text-sm font-semibold'>Up to <span>2</span> Coupons</span> */}
                                                        </div>

                                                        <i className="text-neutral-500 fa-solid fa-arrow-right -rotate-45"></i>

                                                    </div>
                                                </div>
                                            </Link>

                                        ))

                                    ) : (

                                        <p className='font-serif text-neutral-600'>{t("NoFoundBrandTitle")}</p>
                                    )}
                                </div>

                            </div>

                        )}

                    </div>

                    <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>


                    {/* location */}
                    <div className="flex flex-row justify-around align-middle">

                        <div className="relative inline-block text-left"
                            onMouseEnter={handleMouseEnterCountry}
                            onMouseLeave={handleMouseLeaveCountry}
                            onClick={ToggleClickCountry}
                        >
                            <button
                                id="dropdownDividerButton"
                                type="button"
                                className="text-start font-medium rounded-lg text-sm py-2.5 inline-flex items-center"
                            >
                                <img src={locDetails?.image || eg} alt="eg" className='w-12 h-8 rounded-lg' />

                            </button>
                            {/* Dropdown menu */}
                            {isOpenCountry && (
                                <div
                                    id="dropdownDivider"
                                    className="z-10 absolute ltr:right-0 rtl:left-0 w-44 md:w-48 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                    onMouseEnter={handleMouseEnterCountry}
                                    onMouseLeave={handleMouseLeaveCountry}
                                    onClick={ToggleClickCountry}
                                >
                                    <ul
                                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownDividerButton"
                                    >
                                        {
                                            allLocation.map((location, ind) => <li key={ind}>
                                                <button
                                                    onClick={() => sendLocation(location)}
                                                    className="w-full text-start flex justify-start items-center align-middle px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >
                                                    {/* <span className={`fi fi-${location.locationCode || 'eg'} text-xl rounded-md me-2`}></span> */}
                                                    {/* <span className={`fi fi-${code} text-xl rounded-md me-2`}></span> */}

                                                    <img src={location?.image} alt={location?.name} className='w-8 h-6 rounded-md me-2' />

                                                    <span className='text-sm'>{location?.name}</span>
                                                </button>
                                            </li>)
                                        }
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>

                    <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>


                    {/* language */}
                    <div className="relative flex items-center align-middle text-left"
                        onMouseEnter={handleMouseEnterLang}
                        onMouseLeave={handleMouseLeaveLang}
                        onClick={ToggleClickLang}
                    >

                        <button
                            id="dropdownDividerButton"
                            type="button"
                            className="text-start font-medium rounded-lg text-sm inline-flex items-center"
                        >
                            <i className='fa-solid fa-globe fa-xl sm:fa-2xl '></i>
                        </button>

                        {/* Dropdown menu */}
                        {isOpenLang && (
                            <div
                                id="dropdownDivider"
                                className="z-10 absolute top-3 ltr:-right-3 rtl:-right-24 mt-0.5 w-42 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                onMouseEnter={handleMouseEnterLang}
                                onMouseLeave={handleMouseLeaveLang}
                            >


                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">
                                    <li>
                                        <button
                                            onClick={() => handleLanguageChange('ar')}
                                            disabled={isArabicSelected}
                                            className={`w-full text-start flex justify-start align-middle px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${isArabicSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            <span className={`fi fi-sa text-xl rounded-md me-2`}></span>
                                            <span>{t("languageAr")}</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={() => handleLanguageChange('en')}
                                            disabled={isEnglishSelected}
                                            className={`w-full flex justify-start align-middle text-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${isEnglishSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            <span className={`fi fi-um text-xl rounded-md me-2`}></span>
                                            <span>{t("languageEn")}</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <span className='border-e-2 h-7 border-neutral-400 my-auto'></span>

                    {/*   Account */}
                    {
                        toggleAccount ?
                            <div className="relative flex items-center align-middle text-left text-[#061322]"
                                onMouseEnter={handleMouseEnterAcc}
                                onMouseLeave={handleMouseLeaveAcc}
                                onClick={ToggleClickAcc}
                            >
                                <button
                                    id="dropdownDividerButton"
                                    type="button"
                                    className='group relative cursor-pointer rounded-full border-2 bg-[#ffffff2f] border-neutral-300 sm:p-1'
                                >

                                    <img src={userAccount?.image || account} alt="account" className='flex h-8 w-8 sm:h-10 sm:w-10 object-contain items-center rounded-full p-0.5 ' />

                                </button>

                                {/* Dropdown menu */}
                                {isOpenAcc && (
                                    <div
                                        id="dropdownDivider"
                                        className="z-10 absolute overflow-hidden ltr:right-0 rtl:left-0 top-7 mt-2 w-52 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                        onMouseEnter={handleMouseEnterAcc}
                                        onMouseLeave={handleMouseLeaveAcc}
                                        onClick={ToggleClickAcc}
                                    >
                                        <ul
                                            className="py-2 px-1 text-sm text-gray-700 dark:text-gray-200"
                                            aria-labelledby="dropdownDividerButton"
                                        >
                                            <li>
                                                <Link to={'/profile'}

                                                    className="w-full text-start flex justify-start align-middle items-center px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >
                                                    <img src={userAccount?.image || account} alt="Account" className='rounded-full h-10 w-12 border-2 p-0.5' />

                                                    <span className='font-bold text-teal-900 ms-2'>{userAccount?.fullName || "Couponesta"}</span>
                                                </Link>
                                            </li>
                                            <hr className='mx-3' />
                                            <li>
                                                <Link to={'/notification'}

                                                    className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >

                                                    <i className='fa-solid fa-bell relative'>
                                                        <span className='absolute -top-[7px] ltr:-left-[11px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.notification?.length || 0}</span>
                                                    </i>

                                                    <span className='ms-2'>{t("NotificationsTitle")}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/favorite'}

                                                    className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >

                                                    <i className='fa-solid fa-heart relative'>
                                                        <span className='absolute -top-[7px] ltr:-left-[11px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.favorite?.length || 0}</span>
                                                    </i>

                                                    <span className='ms-2'>{t("FavoriteTitle")}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/follow'}

                                                    className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >

                                                    <i className='fa-solid fa-user-plus relative'>
                                                        <span className='absolute -top-[7px] ltr:-left-[11px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.follow?.length || 0}</span>
                                                    </i>

                                                    <span className='ms-2'>{t("FollowTitle")}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/profile'}

                                                    className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >

                                                    <i className='fa-solid fa-user'></i>

                                                    <span className='ms-2'>{t("ProfileTitle")}</span>
                                                </Link>
                                            </li>
                                            <hr className='mx-3 my-2' />
                                            <li>
                                                <Link to={'/login'}
                                                    onClick={logOut}
                                                    className="w-full flex justify-start align-middle text-start items-center mt-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >

                                                    <i className="fa-solid fa-right-from-bracket"></i>
                                                    <span className='text-sm text-danger-800 ms-2'>{t("LogoutTitle")}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            :
                            <button
                                id="dropdownDividerButton"
                                type="button"
                                className='group relative cursor-pointer rounded-full border-2 bg-[#ffffff2f] border-neutral-300 p-0.5 sm:p-1'
                            >

                                <Link to={'/login'} className="flex align-middle justify-between text-sm">
                                    <img src={account} alt="account" className='flex h-5 w-5 sm:h-6 sm:w-6 object-contain items-center rounded-full p-0.5' />

                                </Link>

                            </button>

                    }

                </div>
            </nav >

            {/* navbar bottom */}
            <nav className='bod-s z-50 w-full bg-white mx-auto fixed bottom-0 left-0 right-0 rounded-t-md'>

                <div className="w-full flex justify-evenly items-center">

                    {/* Home */}
                    <Link to={'/'}
                        className={`flex flex-col justify-center items-center px-3 pt-2.5 pb-1 ${isActiveHome ? `text-blue-800 border-t-2 border-blue-800` : 'text-neutral-700'}`}>

                        <i className='fa-solid fa-home'></i>

                        <span className='font-semibold'>{t("HomeTitle")}</span>
                    </Link>

                    {/* explore */}
                    <Link to={'/store'}
                        className={`flex flex-col justify-center items-center px-3 pt-1.5 pb-1 ${isActiveExplore ? `text-blue-800 border-t-2 border-blue-800` : 'text-neutral-700'}`}>

                        <i className='fa-solid fa-grip text-xl'></i>

                        <span className='font-semibold'>{t("ExploreTitle")}</span>
                    </Link>

                    {/* favorite */}
                    <Link to={'/favorite'}
                        className={`flex flex-col justify-center items-center px-3 pt-2.5 pb-1 ${isActiveFavorite ? `text-blue-800 border-t-2 border-blue-800` : 'text-neutral-700'}`}>

                        <i className='fa-solid fa-heart'></i>

                        <span className='font-semibold'>{t("FavoriteTitle")}</span>
                    </Link>

                    {/* profile */}
                    <Link to={toggleAccount ? '/profile' : 'login'}

                        className={`flex flex-col justify-center items-center px-3 pt-2.5 pb-1 ${isActiveProfile ? `text-blue-800 border-t-2 border-blue-800` : 'text-neutral-700'}`}>

                        <i className='fa-solid fa-user'></i>

                        <span className='font-semibold'>{t("ProfileTitle")}</span>
                    </Link>
                </div>
            </nav >

        </div >

    </>
}
