import React, { useEffect, useState } from 'react'
import logo from '../../Assets/images/couponesta.jpg'
import brand from '../../Assets/images/brand1.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CouponsFromOtherStores from '../CouponsFromOtherStores/CouponsFromOtherStores.jsx';
import AllStores from '../AllStores/AllStores.jsx';
import axios from 'axios';
import { useQuery } from 'react-query';
import { BallTriangle, FallingLines, MutatingDots } from 'react-loader-spinner';
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { jwtDecode } from 'jwt-decode';
import {
    FacebookMessengerShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { Helmet } from 'react-helmet';


export default function StoreDetails() {


    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    // Get the id from the URL parameters
    const { id } = useParams();

    // copy to clipboard
    const [copied, setCopied] = useState(false);

    const handleCopy = async (couponId) => {
        try {
            await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/addUseCount`);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        } catch (error) {
            // console.error("Error copying coupon code:", error);
        }
    };


    // brand display
    let [singleBrand, setSingleBrand] = useState({});
    let [loadingBrands, setLoadingBrands] = useState({});
    let isRtl = document.documentElement.dir === 'rtl';

    async function getBrand() {
        return await axios.get(`${process.env.REACT_APP_BACK_END}brand/${lng}?_id=${id}`);
    }

    let { data: brandData, refetch } = useQuery(['singleBrand', lng, singleBrand, loadingBrands], getBrand, {
        cacheTime: 9000000
    });

    // Update singleBrand state when brandData changes
    useEffect(() => {
        if (brandData?.data?.message === "success") {
            setSingleBrand(brandData?.data?.brands[0]);
        }
    }, [brandData, loadingBrands, singleBrand]);

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    // all coupons
    let locDetails = JSON.parse(localStorage.getItem('locCouponesta'))
    const [selectedCoupon, setSelectedCoupon] = useState(null)

    async function getCoupon(id) {

        let response = await axios.get(`${process.env.REACT_APP_BACK_END}coupon/${lng}?location=${locDetails._id}&brand=${id}`)
        return response
    }

    let couponData = useQuery(['coupon', id, lng], () => getCoupon(id), {
        cacheTime: 9000000
    })

    let allCoupon = []

    if (couponData?.data?.data?.message === "success") {

        allCoupon = couponData?.data?.data?.coupons
    }



    const navigate = useNavigate();

    let tokenInUser = localStorage.getItem('refresh_token_website');
    let decoded = {};
    if (tokenInUser) {
        decoded = jwtDecode(localStorage.getItem('refresh_token_website'));
    }


    const handleFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await refetch();
                toast.success(t("AddFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };

    const handleRemoveFollow = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setLoadingBrands(prev => ({ ...prev, [brandId]: true }));
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                await refetch();
                toast.success(t("RemoveFollowedTitle"));
            }
        } catch (error) {
            // handle error
        } finally {
            setLoadingBrands(prev => ({ ...prev, [brandId]: false }));
        }
    };



    // user favorite coupon
    const [addFavorite, setAddFavorite] = useState(false);
    const [addFavoriteLoading, setAddFavoriteLoading] = useState(false);
    const [userFavorites, setUserFavorites] = useState([]);

    // user Like coupon
    const [addLike, setAddLike] = useState(false);
    const [addDisLike, setAddDisLike] = useState(false);
    const [addLikeLoading, setAddLikeLoading] = useState(false);
    const [addDisLikeLoading, setAddDisLikeLoading] = useState(false);
    const [userLiked, setUserLiked] = useState([]);
    const [userDisLiked, setUserDisLiked] = useState([]);
    const [countLiked, setCountLiked] = useState(0);
    const [countDisLiked, setCountDisLiked] = useState(0);

    useEffect(() => {
        const fetchSingleCoupon = async () => {
            if (selectedCoupon) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACK_END}coupon?_id=${selectedCoupon?._id}`);
                    const favorites = response?.data?.coupons[0]?.userFavorite || [];
                    const Likes = response?.data?.coupons[0]?.userLiked || [];
                    const DisLikes = response?.data?.coupons[0]?.userDisLiked || [];
                    setUserFavorites(favorites);
                    setUserLiked(Likes)
                    setUserDisLiked(DisLikes)
                    setCountLiked(response?.data?.coupons[0]?.likeCount)
                    setCountDisLiked(response?.data?.coupons[0]?.dislikeCount)

                } catch (error) {
                    // console.error("Error fetching single coupon data:", error);
                }
            }
        };

        fetchSingleCoupon();
    }, [selectedCoupon, userDisLiked, userLiked]);

    useEffect(() => {
        if (userFavorites.includes(decoded?.id)) {
            setAddFavorite(true);
        } else {
            setAddFavorite(false);
        }

        if (userLiked.includes(decoded?.id)) {
            setAddLike(true);
        } else {
            setAddLike(false);
        }

        if (userDisLiked.includes(decoded?.id)) {
            setAddDisLike(true);
        } else {
            setAddDisLike(false);
        }
    }, [userFavorites, userLiked, userDisLiked, decoded]);

    const handleFavorite = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFavoriteLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/favorite/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserFavorites((prev) => [...prev, decoded.id]);
                await couponData?.refetch();
                toast.success(t("AddFavoriteTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to favorite", error);
        } finally {
            setAddFavoriteLoading(false);
        }
    };

    const handleRemoveFavorite = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFavoriteLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/favorite/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserFavorites((prev) => prev.filter((id) => id != decoded.id));
                await couponData?.refetch();
                toast.success(t("RemoveFavoriteTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while removing from favorite", error);
        } finally {
            setAddFavoriteLoading(false);
        }

    };

    // user Like coupon
    const handleLike = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddLikeLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/like/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserLiked((prev) => prev.filter((id) => id != decoded.id));
                await couponData?.refetch();
                toast.success(t("AddLikedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to favorite", error);
        } finally {
            setAddLikeLoading(false);
        }
    };

    const handleDisLike = async (couponId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddDisLikeLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}coupon/${couponId}/like/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserDisLiked((prev) => [...prev, decoded.id]);
                await couponData?.refetch();
                toast.success(t("AddDisLikedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to favorite", error);
        } finally {
            setAddDisLikeLoading(false);
        }
    };

    // Follow for select coupon
    // user followed brand
    const [addFollowed, setAddFollowed] = useState(false);
    const [addFollowedLoading, setAddFollowedLoading] = useState(false);
    const [userFollowed, setUserFollowed] = useState([]);


    useEffect(() => {
        const fetchSingleBrand = async () => {
            if (selectedCoupon) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACK_END}brand?_id=${selectedCoupon?.brand[0]?._id}`);
                    const followed = response?.data?.brands[0]?.userFollowed || [];
                    setUserFollowed(followed);
                } catch (error) {
                    // console.error("Error fetching single coupon data:", error);
                }
            }
        };

        fetchSingleBrand();
    }, [selectedCoupon]);

    useEffect(() => {
        if (userFollowed.includes(decoded?.id)) {
            setAddFollowed(true);
        } else {
            setAddFollowed(false);
        }
    }, [userFollowed, decoded]);

    const handleFollowForSelected = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFollowedLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/add`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });


            if (response?.data?.message === 'success') {
                setUserFollowed((prev) => [...prev, decoded?.id]);
                await couponData?.refetch();
                toast.success(t("AddFollowedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while adding to followed", error);
        } finally {
            setAddFollowedLoading(false);
        }
    };

    const handleRemoveFollowForSelected = async (brandId) => {
        if (!tokenInUser) {
            navigate('/login');
            return;
        }

        try {
            setAddFollowedLoading(true);
            const response = await axios.patch(`${process.env.REACT_APP_BACK_END}brand/${brandId}/follow/remove`, {}, {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                },
            });

            if (response?.data?.message === 'success') {
                setUserFollowed((prev) => prev.filter((id) => id != decoded.id));
                await couponData?.refetch();
                toast.success(t("RemoveFollowedTitle"));
            }
        } catch (error) {
            // console.error("An error occurred while removing from favorite", error);
        } finally {
            setAddFollowedLoading(false);
        }
    };

    // model coupons
    const toggleModal = (coupon) => {

        setSelectedCoupon(coupon)

        const modal = document.getElementById('crud-modal');

        modal?.classList.toggle('hidden');
        modal?.setAttribute('aria-hidden', modal?.classList?.contains('hidden'));
    };

    // Function to close modal if ESC key is pressed
    const closeModalOnEsc = (event) => {
        const modal = document.getElementById('crud-modal');

        if (event?.key === 'Escape' && !modal?.classList?.contains('hidden')) {
            toggleModal();
            setSelectedCoupon()
        }
    };

    // Adding event listener to the document for keydown events
    document.addEventListener('keydown', closeModalOnEsc);


    // icons
    const [showIcons, setShowIcons] = useState(false);

    const toggleIcons = () => {
        setShowIcons(!showIcons);
    };

    const formatTimeDifference = (createdAt) => {
        // Convert ISO string to Date object
        const createdAtDate = new Date(createdAt);

        // Get current time
        const currentTime = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - createdAtDate;

        // Convert milliseconds to hours
        const hoursDifference = Math.round(timeDifference / (1000 * 60 * 60));

        // Define the display message based on the time difference
        let displayMessage;

        if (hoursDifference === 1) {
            displayMessage = t("LessAnHourTitle");
        } else {
            displayMessage = t("AnHourTitle");
        }

        return displayMessage;
    }


    const shareUrl = "http://github.com";
    const title = isRtl ? selectedCoupon?.description + " من خلال :" + selectedCoupon?.code : selectedCoupon?.description + " Code: " + selectedCoupon?.code;


    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>

        <Helmet>
            <meta name='Store Details' content='Store Details Couponesta Page' charSet="utf-8" />
            <title>Store Details Couponesta</title>
        </Helmet>

        <div className="pt-14 pb-20 bg-[#5B7B94] container ">
            <img src={logo} alt="" className='w-48 rounded-xl mx-auto text-center ' />
        </div>

        <div className="mx-2">
            <div className="mx-auto w-fit px-5 py-6 bg-white -translate-y-10 rounded-3xl">

                <div className="flex flex-row justify-start items-center gap-1 xl:gap-2">
                    <img src={singleBrand?.image || brand} alt="brand img" className='w-20 xl:w-28 rounded-full border border-neutral-100 ltr:mr-3 rtl:ml-3' />

                    <div className="md:ms-3 ms-1">
                        <h4 className="mb-2 font-head font-extrabold text-xl text-neutral-1000">{singleBrand?.name}</h4>
                        <div className="">
                            <p
                                className={`text-sm font-semibold mb-2.5 text-wrap w-[125px] sm:w-[260px] md:w-[450px] text-neutral-650 ${isExpanded ? '' : 'line-clamp-2 xl:line-clamp-0'}`}
                            >{singleBrand?.description} </p>
                            <span onClick={toggleExpand} className='text-teal-600 mt-1 text-sm cursor-pointer font-bold mx-auto p-1 xl:p-2 border-2 border-teal-600 rounded-xl'>{isExpanded ? t("ReadLessTitle") : t("ReadMoreTitle")}</span>
                        </div>
                    </div>

                    <div className="flex xl:flex-row flex-col gap-2 xl:gap-0 items-center ms-1 xl:ms-4">

                        <Link
                            to={singleBrand?.link}
                            target='_blank'
                            className="flex items-center text-wrap justify-normal ms-1 xl:ms-3 text-sm xl:text-base font-semibold cursor-pointer border-2 border-[#fdcd00] rounded-3xl py-1.5 px-1.5 xl:py-2.5 xl:px-2 text-[#fdcd00] transition-all ease-out focus:translate-y-1 focus:shadow-[0_1.5px_0px_rgb(0,0,0)] hover:translate-y-1 hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">
                            <button className='ms-1'>
                                {t("StartShoppingTitle")}
                            </button>
                        </Link>

                        {
                            loadingBrands[singleBrand?._id] ?
                                <MutatingDots
                                    visible={true}
                                    height="100"
                                    width="100"
                                    color="#1a56db"
                                    secondaryColor="#1a56db"
                                    radius="12.5"
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                :
                                singleBrand?.userFollowed?.includes(decoded?.id) ?
                                    <div className="flex items-center"
                                        onClick={() => handleRemoveFollow(singleBrand?._id)}
                                    >
                                        <div className="flex items-center justify-center w-36 text-sm md:text-base md:w-40 ms-1 py-3 px-2 md:px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-white bg-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                            <i className="fa-solid fa-star"></i>
                                            <button className='ms-1'>
                                                {t('IsFollowTitle')}
                                            </button>
                                        </div>

                                    </div>
                                    :
                                    <div className="flex items-center"
                                        onClick={() => handleFollow(singleBrand?._id)}
                                    >
                                        <div className="flex items-center justify-center ms-2 py-2.5 px-2 md:px-5 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl text-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                            <i className="fa-regular fa-star"></i>
                                            <button className='ms-1'>
                                                {t('FollowTitle')}
                                            </button>
                                        </div>

                                    </div>

                        }

                    </div>


                </div>
            </div>
        </div>


        {/* All Coupons */}
        <div className="xl:px-44 px-2 md:px-4 mt-1 mb-10 pb-8">

            <h3 className="mb-10 text-xl font-extrabold md:text-header-5">
                {isRtl ? (
                    <>
                        كل اكواد الخصم <span className="text-teal-700">{singleBrand?.name}</span>
                    </>
                ) : (
                    <>
                        All <span className="text-teal-700">{singleBrand?.name}</span> discount codes
                    </>
                )}
            </h3>


            {
                couponData?.isLoading ? (
                    <div className="loading">
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#199A8E"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : allCoupon?.length === 0 ? (
                    <div className='flex justify-center align-middle p-12 text-teal-800'>
                        <h1 className='font-bold text-center'>{t("NoFoundCouponTitle")}</h1>
                    </div>
                ) :

                    // coupon
                    <div className="px-6 xl:px-0 grid place-items-center grid-cols-1 mx-auto md:grid-cols-2 2xl:grid-cols-3 gap-4 xl:gap-10">

                        {
                            allCoupon?.map((coupon, ind) => <div key={ind}
                                className="cursor-pointer border-2 border-neutral-200 py-5 px-3.5 flex flex-row justify-around w-fit h-fit rounded-3xl"
                            >

                                {/* one side */}
                                <div
                                    className="flex h-full flex-col"
                                    onClick={() => toggleModal(coupon)}
                                    data-modal-target="crud-modal"
                                    data-modal-toggle="crud-modal"
                                >
                                    <div className="ltr:text-left rtl:text-right">

                                        {/* brand */}
                                        <div className="mb-[12px] flex items-center">
                                            {
                                                isRtl ? <>
                                                    <img src={`${process.env.REACT_APP_BACK_END}${coupon?.brand[0]?.image}` || brand} alt="brand img" className='w-8 rounded-full border border-neutral-100 ltr:mr-3 rtl:ml-3' />
                                                    <h6 className="w-24 truncate text-nowrap ltr:ms-2 rtl:me-2">{isRtl ? coupon?.brand[0]?.name?.ar : coupon?.brand[0]?.name?.en}</h6>

                                                </>
                                                    : <>

                                                        <img src={`${process.env.REACT_APP_BACK_END}${coupon?.brand[0]?.image}` || brand} alt="brand img" className='w-8 rounded-full border border-neutral-100 ltr:mr-3 rtl:ml-3' />
                                                        <h6 className="w-24 truncate text-nowrap ltr:ms-2 rtl:me-2">{isRtl ? coupon?.brand[0]?.name?.ar : coupon?.brand[0]?.name?.en}</h6>
                                                    </>

                                            }
                                        </div>

                                        {/* code */}
                                        <h5 className="mb-2 text-xl font-bold w-40">
                                            {
                                                coupon?.code ?
                                                    coupon?.code
                                                    :
                                                    <div>
                                                        <Link
                                                            target='_blank'
                                                            to={`${coupon?.link ? coupon?.link : coupon?.brand[0]?.link}`}
                                                            className='text-blue-700 underline font-semibold font-serif'>
                                                            {
                                                                isRtl ? <>
                                                                    <i className={`text-blue-600 fa-solid ${isRtl ? 'fa-arrow-left rotate-45' : 'fa-arrow-right -rotate-45'}`}></i>
                                                                    {t("ShopNowTitle")}
                                                                </>
                                                                    :
                                                                    <>
                                                                        {t("ShopNowTitle")}
                                                                        <i className={`text-blue-600 fa-solid ${isRtl ? 'fa-arrow-left rotate-45' : 'fa-arrow-right -rotate-45'}`}></i>
                                                                    </>
                                                            }
                                                        </Link>
                                                    </div>
                                            }
                                        </h5>

                                        {/* description */}
                                        <p className="mb-5 line-clamp-1 max-w-[150px]">
                                            {coupon?.description}
                                        </p>

                                        <div className="mt-auto flex items-center text-green-700">

                                            {
                                                isRtl ? <>
                                                    <i className="fa-solid fa-arrow-up-right-from-square me-1"></i>
                                                    <p className="truncate text-wrap xl:text-nowrap text-caption-1">اخر استخدام: {formatTimeDifference(coupon.createdAt)}</p>
                                                </>
                                                    :
                                                    <>
                                                        <i className="fa-solid fa-arrow-up-right-from-square me-1"></i>
                                                        <p className="truncate text-wrap xl:text-nowrap text-caption-1">Last Used: {formatTimeDifference(coupon.createdAt)}</p>
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>


                                {/* tow side */}
                                <div className="relative ms-3 me-5  h-auto"
                                    onClick={() => toggleModal(coupon)}
                                    data-modal-target="crud-modal"
                                    data-modal-toggle="crud-modal"
                                >

                                    {/* border */}
                                    <div className="w-7 my-2 h-[90%] border-r-4 border-dashed border-neutral-200"></div>

                                    {/* top curve */}
                                    <div className="absolute -top-[19px] h-6 w-12 -translate-y-0.5 transform rounded-bl-full rounded-br-full border-b-2 border-l-2 border-r-2 border-neutral-200 ltr:-ml-[21px] rtl:-mr-[21px] bg-white"></div>

                                    {/* button curve */}
                                    <div className="absolute -bottom-[23px] h-6 w-12 -translate-y-0.5 rotate-180 transform rounded-bl-full rounded-br-full border-b-2 border-l-2 border-r-2 border-neutral-200 ltr:-ml-[21px] rtl:-mr-[21px] bg-white"></div>
                                </div>


                                {/* three side */}
                                <div className="flex flex-col items-center">
                                    <div className="mb-1 flex flex-col items-center"
                                    >

                                        <span
                                            className="mb-1 flex items-center text-[26px] font-bold"
                                            onClick={() => toggleModal(coupon)}
                                            data-modal-target="crud-modal"
                                            data-modal-toggle="crud-modal"
                                        >
                                            {
                                                isRtl ? <>
                                                    <span className="truncate text-lg me-1 text-grey-500 ltr:pr-2 rtl:pl-2">
                                                        اعلي من
                                                    </span>
                                                    {coupon.amount}
                                                    <span className="pr-1">
                                                        %
                                                    </span>
                                                </>
                                                    : <>
                                                        <span className="truncate text-lg me-1 text-grey-500 ltr:pr-2 rtl:pl-2">
                                                            Up To
                                                        </span>
                                                        {coupon.amount}
                                                        <span className="pl-1">
                                                            %
                                                        </span>
                                                    </>
                                            }
                                        </span>

                                        {/* discount */}
                                        <div
                                            className={`mb-2 mt-2 flex w-fit max-w-[122px] rounded-3xl bg-opacity-25 p-2 text-sm font-semibold ${coupon.status === 'discount' ? 'bg-[#1B998B]  text-[#1B998B]' : 'bg-[#E58B66] text-[#E58B66] '}`}
                                            onClick={() => toggleModal(coupon)}
                                            data-modal-target="crud-modal-from-another-store"
                                            data-modal-toggle="crud-modal-from-another-store"
                                        >
                                            {
                                                coupon.status === 'discount' ?

                                                    <div className=" flex items-center">
                                                        <img src="	https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fdiscount.svg&w=1536&q=100" className='w-6' />

                                                        <span className='ms-1'> {coupon.status}</span>
                                                    </div>

                                                    :
                                                    <div className=" flex items-center">
                                                        <img src="https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fcashback.svg&w=1536&q=100" className='w-6' />

                                                        <span className='ms-1'> {coupon.status}</span>
                                                    </div>
                                            }

                                        </div>

                                        {/* copy */}
                                        <Link
                                            target='_blank'
                                            to={`${coupon?.link ? coupon?.link : coupon?.brand[0]?.link}`}
                                            className='mt-3'
                                        >
                                            <CopyToClipboard text={coupon?.code ? coupon?.code : "No Code"} onCopy={() => handleCopy(coupon?._id)}>
                                                <button
                                                    className={`rounded-3xl py-2 px-5 transform transition-all ease-out hover:translate-y-1 hover:shadow-[0_1.5px_0px_rgb(0,0,0)] active:scale-x-75 font-bold ${copied ? 'bg-green-500 text-white' : 'bg-[#2761FF] text-white'
                                                        }`}
                                                >
                                                    {copied ? t("CopiedTitle") : t("CopyTitle")}
                                                </button>
                                            </CopyToClipboard>
                                        </Link>

                                    </div>
                                </div>

                            </div>)
                        }

                    </div>
            }


        </div >


        {/* CouponsFromOtherStores */}
        <CouponsFromOtherStores nameContent={t("MostCouponTitle")} />

        {/* Discover More Stores */}
        <AllStores />


        {/* coupon model */}
        {
            <div
                id="crud-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="hidden fixed z-50 inset-0 pb-7 flex items-center justify-center bg-gray-900 bg-opacity-50"
            >

                <div className="relative overflow-y-auto bg-white pt-2 pb-7 px-2 border-1 border-neutral-200 rounded-3xl shadow-lg mt-9 w-full max-w-sm sm:max-w-md xl:max-w-xl max-h-full">

                    {/* Modal content */}
                    <div className="relative bg-white dark:bg-gray-700">

                        {/* Modal header */}
                        <button
                            onClick={() => toggleModal(null)}
                            type="button"
                            className="absolute top-0 right-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <i className="fa-solid fa-xmark text-xl"></i>
                        </button>

                        <div className="flex items-center justify-between pt-8 px-2 pb-5">

                            <div className="flex flex-row justify-start items-center">
                                <img src={`${process.env.REACT_APP_BACK_END}${selectedCoupon?.brand[0]?.image}` || brand} alt="brand img" className='w-20 rounded-full border border-neutral-200 ltr:mr-3 rtl:ml-3' />
                                <h4 className="ms-2 font-head font-extrabold text-xl text-neutral-1000">{isRtl ? selectedCoupon?.brand[0]?.name?.ar : selectedCoupon?.brand[0]?.name?.en}</h4>

                            </div>

                            {
                                addFollowedLoading ?
                                    <FallingLines
                                        color="#1a56db"
                                        width="60"
                                        visible={true}
                                        ariaLabel="falling-circles-loading"
                                    />
                                    :
                                    addFollowed ?
                                        <div className="flex items-center ms-4"
                                            onClick={() => handleRemoveFollowForSelected(selectedCoupon?.brand[0]?._id)}
                                        >
                                            <div className="flex items-center justify-center ms-3 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl py-2.5 px-5 text-white bg-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                <i className="fa-solid fa-star"></i>
                                                <button className='ms-1'>
                                                    {t('IsFollowTitle')}
                                                </button>
                                            </div>

                                        </div>
                                        :
                                        <div className="flex items-center ms-4"
                                            onClick={() => handleFollowForSelected(selectedCoupon?.brand[0]?._id)}
                                        >
                                            <div className="flex items-center justify-center ms-3 text-center mx-auto cursor-pointer border-2 border-[#2761ff] rounded-3xl py-2.5 px-5 text-[#2761ff] hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">

                                                <i className="fa-regular fa-star"></i>
                                                <button className='ms-1'>
                                                    {t('FollowTitle')}
                                                </button>
                                            </div>

                                        </div>


                            }


                        </div>

                        {/* Modal body */}
                        <div className=" bg-white px-5 pb-4">

                            {/* section 1 */}
                            <div className="border-y-2 border-neutral-200 bg-white p-4 text-lg font-medium">

                                {/* discount */}
                                <div className="flex flex-col items-center justify-center">
                                    <div
                                        className={`mb-2 mt-2 flex w-fit max-w-[122px] rounded-3xl bg-opacity-25 p-2 text-sm font-semibold ${selectedCoupon?.status === 'discount' ? 'bg-[#1B998B]  text-[#1B998B]' : 'bg-[#E58B66] text-[#E58B66] '}`}
                                    >
                                        {
                                            selectedCoupon?.status === 'discount' ?

                                                <div className=" flex items-center">
                                                    <img src="	https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fdiscount.svg&w=1536&q=100" className='w-6' />

                                                    <span className='ms-1'> {selectedCoupon?.status}</span>
                                                </div>

                                                :
                                                <div className=" flex items-center">
                                                    <img src="https://www.sahseh.co/_vercel/image?url=%2Fassets%2Fcashback.svg&w=1536&q=100" className='w-6' />

                                                    <span className='ms-1'> {selectedCoupon?.status}</span>
                                                </div>
                                        }

                                    </div>

                                    <span
                                        className="mb-1 mt-3 flex items-center text-[26px] font-bold">
                                        {
                                            isRtl ? <>
                                                <span className="pr-2 text-lg text-gray-400 ltr:pr-2 rtl:pl-2">
                                                    اعلي من
                                                </span>
                                                {selectedCoupon?.amount}
                                                <span className="pr-1">
                                                    %
                                                </span>
                                            </>
                                                :
                                                <>
                                                    <span className="pr-2 text-lg text-gray-400 ltr:pr-2 rtl:pl-2">
                                                        Up To
                                                    </span>
                                                    {selectedCoupon?.amount}
                                                    <span className="pl-1">
                                                        %
                                                    </span>
                                                </>
                                        }
                                    </span>

                                </div>
                            </div>

                            {/* section 2 */}
                            <div className="border-b-2 border-neutral-200 bg-white py-5 text-lg text-neutral-800">
                                <h6 className="mb-2.5 font-medium">{t("DetailsTitle")}</h6>

                                <p
                                    className="font-normal ps-2">
                                    {selectedCoupon?.description}
                                </p>


                                <div className="truncate text-caption-1 flex items-center mt-4 ">
                                    {
                                        isRtl ? <>
                                            <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                                            <p className="truncate text-caption-1 text-wrap ms-1 text-green-700" > <span className='text-black'>اخر استخدام:</span> {formatTimeDifference(selectedCoupon?.createdAt)}</p>
                                        </>
                                            :
                                            <>
                                                <i className="fa-solid fa-arrow-up-right-from-square me-1"></i>
                                                <p className="truncate text-caption-1 text-wrap ms-1 text-green-700"> <span className='text-black'>Last Used:</span> {formatTimeDifference(selectedCoupon?.createdAt)}</p>

                                            </>
                                    }
                                </div>
                            </div>

                            {/* section 3 */}
                            <div className="cursor-pointer bg-white py-2 xl:p-5 xl:pb-6 text-lg font-medium text-neutral-800">

                                <div className="flex items-center justify-between">

                                    {/* Share */}
                                    <div onClick={toggleIcons}
                                        className="flex flex-col items-center hover:text-blue-700 align-middle justify-center">

                                        {/* icon */}
                                        <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                            <i className='fa-solid fa-share-from-square text-xl p-4'></i>
                                        </div>

                                        <h3>{t("ShareTitle")}</h3>
                                    </div>

                                    {/* favorite */}
                                    {
                                        addFavoriteLoading ?
                                            <FallingLines
                                                color="#1a56db"
                                                width="60"
                                                visible={true}
                                                ariaLabel="falling-circles-loading"
                                            />
                                            :
                                            addFavorite ?
                                                <div
                                                    onClick={() => handleRemoveFavorite(selectedCoupon?._id)}
                                                    className="flex flex-col items-center text-blue-700 align-middle justify-center">

                                                    {/* icon */}
                                                    <div className={`relative rounded-full bg-neutral-200 text-blue-700`}>
                                                        <i className='fa-solid fa-heart text-xl p-4 text-blue-700'></i>
                                                    </div>

                                                    <h3>{t("FavoriteTitle")}</h3>
                                                </div>
                                                :
                                                <div
                                                    onClick={() => handleFavorite(selectedCoupon?._id)}
                                                    className="flex flex-col items-center hover:text-blue-700 align-middle justify-center">

                                                    {/* icon */}
                                                    <div className={`relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300`}>
                                                        <i className='fa-regular fa-heart text-xl p-4'></i>
                                                    </div>

                                                    <h3>{t("FavoriteTitle")}</h3>
                                                </div>


                                    }

                                    {/* Shop */}
                                    <Link
                                        target='_blank'
                                        to={selectedCoupon?.brand[0]?.link}
                                        className="flex flex-col items-center hover:text-blue-700 align-middle justify-center">

                                        {/* icon */}
                                        <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                            <i className='fa-solid fa-shop text-xl p-4'></i>
                                        </div>

                                        <h3>{t("ShopTitle")}</h3>
                                    </Link>

                                    {
                                        addLikeLoading ?
                                            <FallingLines
                                                color="#1a56db"
                                                width="60"
                                                visible={true}
                                                ariaLabel="falling-circles-loading"
                                            />
                                            :
                                            addLike ?

                                                <div className="flex flex-col items-center text-blue-700 align-middle justify-center" onClick={() => handleLike(selectedCoupon._id)}>
                                                    {/* Like Icon */}
                                                    <div className="relative rounded-full bg-neutral-200 text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-solid fa-thumbs-up text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("LikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countLiked || selectedCoupon?.likeCount}</span></h3>
                                                </div>
                                                :

                                                <div className="flex flex-col items-center hover:text-blue-700 align-middle justify-center" onClick={() => handleLike(selectedCoupon._id)}>
                                                    {/* Like Icon */}
                                                    <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-regular fa-thumbs-up text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("LikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countLiked || selectedCoupon?.likeCount}</span></h3>
                                                </div>
                                    }

                                    {
                                        addDisLikeLoading ?
                                            <FallingLines
                                                color="#1a56db"
                                                width="60"
                                                visible={true}
                                                ariaLabel="falling-circles-loading"
                                            />
                                            :
                                            addDisLike ?

                                                <div className="flex flex-col items-center hover:text-blue-700 align-middle justify-center" onClick={() => handleDisLike(selectedCoupon._id)}>
                                                    {/* Dislike Icon */}
                                                    <div className="relative rounded-full bg-neutral-200 text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-solid fa-thumbs-down text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("DislikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countDisLiked || selectedCoupon?.dislikeCount}</span></h3>
                                                </div>
                                                :

                                                <div className="flex flex-col items-center hover:text-blue-700 align-middle justify-center" onClick={() => handleDisLike(selectedCoupon._id)}>
                                                    {/* Dislike Icon */}
                                                    <div className="relative rounded-full text-gray-500 bg-neutral-200 hover:text-blue-700 hover:bg-neutral-300">
                                                        <i className='fa-regular fa-thumbs-down text-xl p-4'></i>
                                                    </div>
                                                    <h3>{t("DislikeTitle")}: <span className='text-blue-700 font-serif font-extrabold'>{countDisLiked || selectedCoupon?.dislikeCount}</span></h3>
                                                </div>
                                    }

                                </div>
                            </div>

                            {/* icons */}
                            {showIcons && (
                                <div className="cursor-pointer bg-white px-4 pt-1 pb-2 text-lg font-medium text-neutral-800">
                                    <div className="flex items-center justify-between">

                                        {/* whats app */}
                                        <WhatsappShareButton title={title} url={window.location.href}
                                        >
                                            <div
                                                className='flex justify-center items-center w-12 h-12  rounded-full text-white bg-green-500 hover:bg-green-600'
                                            >
                                                <i className='fa-brands fa-whatsapp text-2xl'></i>
                                            </div>
                                        </WhatsappShareButton>

                                        {/* telegram*/}
                                        <TelegramShareButton title={title} url={window.location.href}>
                                            <div
                                                className='flex justify-center items-center w-12 h-12  rounded-full text-white bg-[#26A5E4] hover:bg-teal-500'
                                            >
                                                <i className='fa-brands fa-telegram text-2xl'></i>
                                            </div>
                                        </TelegramShareButton>

                                        {/* twitter*/}
                                        <TwitterShareButton title={title} url={window.location.href}>
                                            <div className="flex justify-center items-center w-12 h-12  rounded-full text-white bg-neutral-900 hover:bg-neutral-950">
                                                <i className='fa-brands fa-x-twitter text-2xl'></i>
                                            </div>
                                        </TwitterShareButton>

                                        {/* facebook */}
                                        <FacebookMessengerShareButton title={title} url={window.location.href}>
                                            <div className="flex justify-center items-center w-12 h-12  rounded-full text-white bg-blue-500 hover:bg-blue-600">
                                                <i className='fa-brands fa-facebook-messenger text-2xl'></i>
                                            </div>
                                        </FacebookMessengerShareButton>

                                        {/* Tiktok */}
                                        <Link
                                            to={`https://www.tiktok.com/@`}
                                            target='_blank'
                                        >
                                            <div className="flex justify-center items-center w-12 h-12  rounded-full text-white bg-rose-500 hover:bg-rose-600">
                                                <i className='fa-brands fa-tiktok text-2xl'></i>
                                            </div>
                                        </Link>

                                    </div>
                                </div>
                            )}

                            {/* section 4 */}
                            <div className="relative flex flex-row ms-3 me-5 mb-4">

                                {/* border */}
                                <div className="my-2 w-full border-t-4 border-dashed border-neutral-200"></div>

                                {/* left curve */}
                                <div className="absolute -left-[52px] h-6 w-12 -translate-y-0.5 -rotate-90 transform rounded-bl-full rounded-br-full border-b-2 border-l-2 border-r-2 border-neutral-200 ltr:-ml-[21px] rtl:-mr-[21px] bg-neutral-400"></div>

                                {/* right curve */}
                                <div className="absolute -right-[60px] h-6 w-12 -translate-y-0.5 rotate-90 transform rounded-bl-full rounded-br-full border-b-2 border-l-2 border-r-2 border-neutral-200 ltr:-ml-[21px] rtl:-mr-[21px] bg-neutral-400"></div>
                            </div>

                            {/* section 5 */}
                            <div className=" rounded-full bg-neutral-200 text-2xl py-2 font-bold">

                                <div className="flex justify-between items-center">
                                    <h6 className="my-auto flex-grow text-center tracking-[0.2rem] sm:tracking-[0.4rem]">
                                        {selectedCoupon?.code ? selectedCoupon?.code : ""}
                                    </h6>

                                    {
                                        selectedCoupon?.code === "" ?

                                            <div className='w-full text-start'>
                                                <Link
                                                    target='_blank'
                                                    to={`${selectedCoupon?.link ? selectedCoupon?.link : selectedCoupon?.brand[0]?.link}`}
                                                    className='text-white text-sm xl:text-xl ltr:ms-2 rtl:me-2 underline bg-blue-700 font-serif rounded-3xl py-2 px-5 transform transition-all ease-out hover:translate-y-1 hover:shadow-[0_1.5px_0px_rgb(0,0,0)] active:scale-x-75 font-bold'>
                                                    {/* className='text-white underline bg-blue-700  font-bold w-full'> */}
                                                    {
                                                        isRtl ? <>
                                                            {t("ShopNowTitle")}
                                                            <i className={` fa-solid ${isRtl ? 'fa-arrow-left rotate-45 me-1' : 'fa-arrow-right -rotate-45 ms-1'}`}></i>
                                                        </>
                                                            :
                                                            <>
                                                                {t("ShopNowTitle")}
                                                                <i className={` fa-solid ${isRtl ? 'fa-arrow-left rotate-45 me-1' : 'fa-arrow-right -rotate-45 ms-1'}`}></i>
                                                            </>
                                                    }
                                                </Link>

                                            </div>
                                            :
                                            <Link
                                                target='_blank'
                                                to={`${selectedCoupon?.link ? selectedCoupon?.link : selectedCoupon?.brand[0]?.link}`}
                                                className='me-2'>
                                                <CopyToClipboard text={selectedCoupon?.code ? selectedCoupon?.code : "No Code"} onCopy={() => handleCopy(selectedCoupon?._id)}>
                                                    <button
                                                        className={`rounded-3xl py-2 px-5 transform transition-all ease-out hover:translate-y-1 hover:shadow-[0_1.5px_0px_rgb(0,0,0)] active:scale-x-75 font-bold ${copied ? 'bg-green-500 text-white' : 'bg-[#2761FF] text-white'}`}
                                                    >
                                                        {copied ? t("CopiedTitle") : t("CopyTitle")}
                                                    </button>
                                                </CopyToClipboard>
                                            </Link>
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div >

        }






    </>
}
