import React, { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import logo from '../../Assets/images/couponesta.jpg'
import ForgetPasswordEmail from '../ForgetPasswordEmail/ForgetPasswordEmail.jsx';
import { Helmet } from 'react-helmet';

export default function ForgetPassword() {

    const location = useLocation();

    const isActiveEmail = location.pathname === '/forgetPassword';
    const isActivePhone = location.pathname === '/forgetPassword/withPhone';


    return <>

        <Helmet>
            <meta name='Forget Password' content='Forget Password Couponesta Page' charSet="utf-8" />
            <title>Forget Password Couponesta</title>
        </Helmet>

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">


            <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                <Link
                    to={'/'}
                    className='cursor-pointer'
                >
                    <img
                        className="mx-auto h-20 rounded-xl  w-auto"
                        src={logo}
                        alt="Your Company"
                    />
                </Link>

                <h1 className='text-center my-2 md:pt-10 mb-5 md:mb-20 md:text-3xl text-2xl font-bold text-teal-600'>Forget Password</h1>

                <div className="flex justify-center align-middle gap-x-4 mt-5 font-semibold">

                    <Link to={'/forgetPassword'}>
                        <button
                            className={`border-2 rounded-xl px-4 py-3 border-gray-500 ${isActiveEmail ? 'bg-[#3b35b4] border-[#3b35b4] text-white' : ''}`}
                        >
                            With Email
                        </button>
                    </Link>

                    <Link to={'/forgetPassword/withPhone'}>
                        <button
                            className={`border-2 rounded-xl px-4 py-3 border-gray-500 ${isActivePhone ? 'bg-[#3b35b4] border-[#3b35b4] text-white' : ''}`}
                        >
                            With Phone
                        </button>
                    </Link>

                </div>
            </div>



            <Outlet>
                <ForgetPasswordEmail />
            </Outlet>

        </div>
    </>
}
