import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import FeaturedStores from '../FeaturedStores/FeaturedStores.jsx';
import RecentlyAdded from '../RecentlyAdded/RecentlyAdded.jsx';
import HighestDiscountsCoupons from '../HighestDiscountsCoupons/HighestDiscountsCoupons.jsx';
import DiscoverCategories from '../DiscoverCategories/DiscoverCategories.jsx';
import AllCoupons from '../AllCoupons/AllCoupons.jsx';
import CouponsFromOtherStores from '../CouponsFromOtherStores/CouponsFromOtherStores.jsx';
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


export default function CategoryDetails() {

    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    // Get the id from the URL parameters
    const { id } = useParams();

    // category display
    async function getCategory() {
        return await axios.get(`${process.env.REACT_APP_BACK_END}category/${lng}?_id=${id}`);
    }

    let { data: categoryData } = useQuery(['singleCategory', id, lng], getCategory, {
        cacheTime: 9000000
    });

    let singleCategory = {};
    if (categoryData?.data?.message === "success") {
        singleCategory = categoryData?.data?.categories[0];
    }

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>

        <Helmet>
            <meta name='Category Details' content='Category Details Couponesta Page' charSet="utf-8" />
            <title>Category Details Couponesta</title>
        </Helmet>

        <div
            style={{ backgroundColor: `${singleCategory?.color ? singleCategory.color : "#1595AE"}` }}

            className="w-full text-white mo:text-center md:text-start pt-8 xl:pt-14 px-20 xl:px-36 pb-6">

            <div className="flex flex-wrap w-full items-center justify-center md:justify-between">
                <div>
                    <h2 className="mb-4 text-header-2 font-bold text-5xl">{singleCategory?.name}</h2>
                    <p className="w-[350px] xl:w-96 text-xl font-normal">{t("CategoryTitleHeader1")} <span>4</span> {t("CategoryTitleHeader2")}</p>
                </div>

                <img src={singleCategory?.image} alt="category-logos" className="my-auto w-48 mt-5 rounded-xl" />
            </div>

        </div>

        <FeaturedStores />

        <CouponsFromOtherStores nameContent={t("MostCouponTitle")} />

        <RecentlyAdded nameContent={t('RecentlyAddedTitle')} />

        {/* Highest Discounts Coupons */}
        <HighestDiscountsCoupons nameContent={t("DiscountCouponTitle")} />

        {/* Discover By Categories */}
        <DiscoverCategories />

        {/* All Coupons */}
        <AllCoupons />


    </>
}
