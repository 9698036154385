import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MutatingDots } from 'react-loader-spinner';
import { UserContext } from '../../Context/UserContext.js';
import { Helmet } from 'react-helmet';

export default function AccountDetails() {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const tokenInUser = localStorage.getItem('refresh_token_website');

    async function getAccountUserDetails() {
        if (tokenInUser) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_END}user/loggedUser/data`, {
                    headers: {
                        authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                    }
                });
                return response?.data;
            } catch (error) {
                // console.error("Failed to fetch user details:", error);
            }
        } else {
            navigate('/login');
        }
    }

    const userDataAccount = useQuery(['userAccDetails', tokenInUser], getAccountUserDetails, {
        cacheTime: 900,
        refetchInterval: 1000
    });

    const userAccount = userDataAccount?.data?.message === "success" ? userDataAccount?.data?.user[0] : {};

    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [changeDataLoading, setChangeDataLoading] = useState(false);

    const validationSchema = Yup.object({
        fullName: Yup.string().min(1).max(100),
        email: Yup.string().email('Invalid email format'),
        password: Yup.string().min(6, 'Password must with character and minLength 6'),
        phoneNumber: Yup.string(),
        countryCode: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            fullName: userAccount.fullName || '',
            email: userAccount.email || '',
            phoneNumber: userAccount.phoneNumber || '',
            countryCode: userAccount.countryCode || '',
            password: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const changedValues = {};
                for (let key in values) {
                    if (values[key] != formik.initialValues[key]) {
                        changedValues[key] = values[key];
                    }
                }

                const formData = new FormData();
                for (let key in changedValues) {
                    formData.append(key, changedValues[key]);
                }

                const response = await axios.put(
                    `${process.env.REACT_APP_BACK_END}user/${userAccount?._id}`,
                    formData,
                    {
                        headers: {
                            authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                        },
                    }
                );

                setChangeDataLoading(true);

                if (response?.data?.message === 'success') {
                    await userDataAccount?.refetch();
                    toast.success(t("Data_updated_successfully") || "Data updated successfully!");
                    setChangeDataLoading(false);
                } else {
                    toast.error(t("failed_to_update_Data") || "Failed to update Data!");
                    setChangeDataLoading(false);
                }
            } catch (error) {
                toast.error(t("error_updating_Data") || "Error updating Data");
                setChangeDataLoading(false);
            }
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (userDataAccount?.data) {
            formik.setValues({
                fullName: userAccount.fullName || '',
                email: userAccount.email || '',
                phoneNumber: userAccount.phoneNumber || '',
                countryCode: userAccount.countryCode || '',
                password: '',
            });
        }
    }, [userDataAccount?.data]);

    useEffect(() => {
        const initialValues = {
            fullName: userAccount.fullName || '',
            email: userAccount.email || '',
            phoneNumber: userAccount.phoneNumber || '',
            countryCode: userAccount.countryCode || '',
            password: '',
        };
        const isFormChanged = Object.keys(initialValues).some(
            key => formik.values[key] != initialValues[key]
        );
        setIsSaveDisabled(!isFormChanged);
    }, [formik.values]);

    const handleChange = (fieldName, value) => {
        formik.setFieldValue(fieldName, value);
    };

    // Sample country code data
    const countryCodes = [
        { code: '+20', countryCode: 'EG', country: 'Egypt' },
        { code: '+971', countryCode: 'AE', country: 'United Arab Emirates' },
        { code: '+1', countryCode: 'US', country: 'United States' },
        { code: '+7', countryCode: 'RU', country: 'Russia' },
        { code: '+33', countryCode: 'FR', country: 'France' },
        { code: '+44', countryCode: 'GB', country: 'United Kingdom' },
        { code: '+49', countryCode: 'DE', country: 'Germany' },
        { code: '+81', countryCode: 'JP', country: 'Japan' },
        { code: '+86', countryCode: 'CN', country: 'China' },
        { code: '+91', countryCode: 'IN', country: 'India' },
        { code: '+92', countryCode: 'PK', country: 'Pakistan' },
        { code: '+254', countryCode: 'KE', country: 'Kenya' },
        { code: '+355', countryCode: 'AL', country: 'Albania' },
        { code: '+213', countryCode: 'DZ', country: 'Algeria' },
        { code: '+376', countryCode: 'AD', country: 'Andorra' },
        { code: '+244', countryCode: 'AO', country: 'Angola' },
        { code: '+672', countryCode: 'AQ', country: 'Antarctica' },
        { code: '+54', countryCode: 'AR', country: 'Argentina' },
        { code: '+374', countryCode: 'AM', country: 'Armenia' },
        { code: '+297', countryCode: 'AW', country: 'Aruba' },
        { code: '+61', countryCode: 'AU', country: 'Australia' },
        { code: '+43', countryCode: 'AT', country: 'Austria' },
        { code: '+994', countryCode: 'AZ', country: 'Azerbaijan' },
        // Add more country codes as needed
    ];


    // delete user

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }

    const [deleteUserId, setDeleteUserId] = useState(null);
    let { setRefresh_token_website } = useContext(UserContext)

    // Function to handle update button click
    const handleDeleteClick = (user) => {
        setDeleteUserId(user?._id); // Set the ID of the user being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update user
    async function submitDeleteUser() {

        try {
            await axios.delete(
                `${process.env.REACT_APP_BACK_END}user/${deleteUserId}`,
                {
                    headers: {
                        authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            localStorage.removeItem('refresh_token_website');
            setRefresh_token_website(null)
            navigate('/login')
            toggleModelDel()
            toast.success('delete Account successfully!');

        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }


    function logOut() {
        localStorage.removeItem('refresh_token_website');
        setRefresh_token_website(null)
        navigate('/login')
    }

    return <>
        <div className="container pb-8">

            <Helmet>
                <meta name='Account Details' content='Account Details Couponesta Page' charSet="utf-8" />
                <title>Account Details Couponesta</title>
            </Helmet>

            <h1 className='mb-8 font-bold text-3xl'>{t("AccountDetailsTitle")}</h1>
            <div className="w-full rounded-2xl border-solid border-neutral-300 p-4 md:border md:p-8">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4 flex flex-col gap-7">

                        {/* Full Name */}
                        <div className="w-full">
                            <label htmlFor="fullName" className="block text-sm font-bold leading-6 text-gray-900 cursor-pointer">
                                {t("FullNameTitle")}
                            </label>
                            <div className="mt-2">
                                <input
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => handleChange('fullName', e.target.value)}
                                    value={formik.values.fullName}
                                    id='fullName'
                                    name='fullName'
                                    type='text'
                                    autoComplete="text"
                                    className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                />
                                {formik.errors.fullName && formik.touched.fullName && (
                                    <div className="py-3 mt-1.5 px-2 md:px-6 bg-red-400 text-red-100 rounded-3xl">
                                        {formik.errors.fullName}
                                    </div>
                                )}
                            </div>
                        </div>

                        {
                            userAccount?.email ? <>
                                {/* email */}
                                <div className="w-full">
                                    <label htmlFor="email" className="block text-sm font-bold leading-6 text-gray-900 cursor-pointer">
                                        {t("EmailTitle")}
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                            value={formik.values.email}
                                            id='email'
                                            name='email'
                                            type='text'
                                            autoComplete="text"
                                            className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                        />
                                        {formik.errors.email && formik.touched.email && (
                                            <div className="py-3 mt-1.5 px-2 md:px-6 bg-red-400 text-red-100 rounded-3xl">
                                                {formik.errors.email}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                                :
                                <>

                                    {/* phone number */}
                                    <div className="flex justify-start align-middle mt-1 gap-x-1 w-full">

                                        <div className='basis-1/3'>

                                            <label htmlFor="countryCode" className="block text-sm font-bold leading-6 text-gray-900 cursor-pointer">
                                                {t("CountryCodeTitle")}
                                            </label>
                                            <div className="mt-2">
                                                <select
                                                    id="countryCode"
                                                    name="countryCode"
                                                    value={formik.values.countryCode}
                                                    onChange={(e) => handleChange('countryCode', e.target.value)}
                                                    onBlur={formik.handleBlur}
                                                    className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                                >
                                                    <option value={formik?.countryCode || "Egypt"} disabled>{t("SelectCountryTitle")}</option>

                                                    {countryCodes.map((country, index) => (
                                                        <option key={index} value={country?.countryCode}>{country?.country} ({country?.code})</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className='basis-3/4'>
                                            <label htmlFor="phoneNumber" className="block text-sm font-bold leading-6 text-gray-900 cursor-pointer">
                                                {t("PhoneNumberTitle")}
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => handleChange('phoneNumber', e.target.value)}
                                                    value={formik.values.phoneNumber}
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    type='number'
                                                    autoComplete="phoneNumber"
                                                    placeholder={userAccount?.phoneNumber || "not exist Phone Number"}
                                                    className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    {formik.errors.phoneNumber && formik.touched.phoneNumber ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.phoneNumber}</div> : ''}
                                    {formik.errors.countryCode && formik.touched.countryCode ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.countryCode}</div> : ''}

                                </>
                        }

                        <div className="w-full">
                            <label htmlFor="password" className="block mb-1.5 text-sm font-bold leading-6 text-gray-900 cursor-pointer">
                                {t("PasswordTitle")}
                            </label>
                            <input
                                onBlur={formik.handleBlur}
                                onChange={(e) => handleChange('password', e.target.value)}
                                value={formik.values.password}
                                id='password'
                                name='password'
                                type='text'
                                placeholder={'Type password hear...'}
                                className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                            />
                            {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.password}</div> : ''}
                        </div>

                    </div>

                    {
                        changeDataLoading ?
                            <div className="w-full mx-auto flex justify-center items-center">
                                <MutatingDots
                                    visible={true}
                                    height="100"
                                    width="100"
                                    color="#5850EC"
                                    secondaryColor="#5850EC"
                                    radius="12.5"
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                            :
                            <div className='flex justify-between items-center gap-5 md:gap-x-48'>
                                <button
                                    type="submit"
                                    className="basis-1/2 hover:translate-y-1 mt-10 border-[1px] border-gray-300 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] flex w-full justify-center cursor-pointer rounded-3xl bg-indigo-600 p-3 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-indigo-400 disabled:text-gray-200 text-white shadow-sm active:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    disabled={isSaveDisabled}
                                >
                                    {t("SaveChangesTitle")}
                                </button>

                                <button
                                    className="basis-1/2 hover:translate-y-1 mt-10 border-[1px] border-gray-300 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] flex w-full justify-center cursor-pointer rounded-3xl bg-red-600 p-3 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-red-400 disabled:text-gray-200 text-white shadow-sm active:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    onClick={logOut}
                                >
                                    {t("LogoutTitle")}
                                </button>
                            </div>
                    }
                </form>
            </div>

            <div className="w-full rounded-2xl border-solid border-neutral-300 p-4 mt-3 md:border md:p-8">

                <h3 className='mb-4 font-bold text-2xl'>{t("DeleteAccountTitle")}</h3>

                <p>{t("DeleteAccountDescTitle")}</p>

                <button
                    // onClick={toggleModelDel}
                    onClick={() => handleDeleteClick(userAccount)}
                    data-modal-target="popup-modal"
                    data-modal-toggle="popup-modal"
                    type="button"
                    className="md:px-16 hover:translate-y-1 mt-10 border-[1px] border-gray-300 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] flex justify-center cursor-pointer rounded-3xl bg-red-600 p-3 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-red-400 disabled:text-gray-200 text-white shadow-sm active:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                    {t("FinallyDelete")}
                </button>

            </div>
        </div>


        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t("PermanentlyDeleteAccount")}
                        </h3>
                        <div className="flex justify-between pt-5">
                            <button
                                data-modal-hide="popup-modal"
                                type="button"
                                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                                onClick={submitDeleteUser}
                            >
                                {t("YesImSure")}
                            </button>
                            <button
                                data-modal-hide="popup-modal"
                                type="button"
                                onClick={toggleModelDel}
                                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                                {t("NoCancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>
}
