import React from 'react'
import Slider from "react-slick";

import ads from '../../Assets/images/Amazon_banner.jpg'
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';

export default function Ads() {

    let locDetails = JSON.parse(localStorage.getItem('locCouponesta'))

    async function getAds() {

        return await axios.get(`${process.env.REACT_APP_BACK_END}ads?location=${locDetails._id}`)
    }

    let { isLoading, data, refetch } = useQuery(['Ads'], () => getAds(), {
        cacheTime: 9000000
    })

    let allAds = []

    if (data?.data?.message === "success") {

        allAds = data?.data?.ads
    }

    let isRtl = document.documentElement.dir === 'rtl';


    // slider
    let settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: isRtl ? 2 : 1,
        slidesToScroll: 1,
        variableWidth: !isRtl,
        focusOnSelect: true,
        arrows: false,
        autoplay: true,
        pauseOnHover: true,
        autoplaySpeed: 4000,
        focusOnSelect: true,
        rtl: isRtl,
        responsive: [
            {
                breakpoint: 1521,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,
                    dots: false

                }
            }
        ]
    }

    return <>

        <div className="pt-6 py-8 2xl:pb-2 container">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> :

                    <Slider
                        {...settings}
                    >
                        {/* ads */}
                        {allAds.map((ads, ind) => {
                            return <Link to={`${ads.link}`}
                                target='_blank'
                                key={ind}
                                className="xl:px-4 px-2 container"
                            >
                                <img
                                    src={ads.image}
                                    alt={ads.name}
                                    className='object-cover mx-auto max-h-[100px] sm:max-h-[150px] xl:max-h-[240px] w-[250px] md:w-[450px] xl:w-[800px]   rtl:w-[100%] cursor-pointer rounded-xl'
                                // style={{ width: 800 }}
                                />
                            </Link>
                        })}

                    </Slider>
            }


        </div>
    </>
}
