import React from 'react'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';

export default function Notification() {

    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    let isRtl = document.documentElement.dir === 'rtl';

    let navigate = useNavigate()

    async function getNotification() {

        try {

            if (localStorage.getItem('refresh_token_website') != null &&
                localStorage.getItem('refresh_token_website') != undefined &&
                localStorage.getItem('refresh_token_website').trim() != '') {

                try {

                    let response = await axios.get(`${process.env.REACT_APP_BACK_END}notification/${lng}`, {
                        headers: {
                            authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                        }
                    });

                    if (response?.data?.message === "success") {

                        return response?.data?.notification
                    } else {
                        return []
                    }

                } catch (error) {

                }

            } else {

                navigate('/login');
            }


        } catch (error) {

        }
    }

    let { data, isLoading } = useQuery(['getNotification', lng], () => getNotification(), {
        cacheTime: 9000000
    })


    return <>

        <Helmet>
            <meta name='Notification' content='Notification Couponesta Page' charSet="utf-8" />
            <title>Notification Couponesta</title>
        </Helmet>

        <div className="">
            {
                isLoading ? (
                    <div className="loading">
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#199A8E"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : data?.length === 0 ? (
                    <div className='flex justify-center align-middle p-12 text-teal-800'>
                        <h1 className='font-bold'>{t("NoFoundNotificationTitle")}</h1>
                    </div>
                ) : <div className='grid place-items-center grid-cols-1 sm:grid-cols-1 xl:grid-cols-1 gap-y-2 mt-5'>
                    {
                        data?.map((notification, ind) => {
                            return <div
                                key={ind}
                                className="px-4 py-5 hover:shadow-md mb-6 cursor-pointer rounded-2xl border border-neutral-300 hover:border-neutral-400 bg-white w-full"
                            >
                                <Link to={"/coupons/All-Coupons"}
                                    className='flex items-center justify-between'
                                >

                                    <div className="ms-3">
                                        <h4 className="mb-2 font-head text-base font-bold text-neutral-1000">{notification?.header}</h4>
                                        <div className="flex items-center">
                                            <p className="text-sm font-normal text-neutral-600">{notification?.body}</p>
                                        </div>
                                    </div>

                                    <button
                                        className="text-nowrap hover:translate-y-1 border-[1px] border-gray-300 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] flex justify-center cursor-pointer rounded-3xl bg-green-500 p-3 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-green-400 disabled:text-gray-200 text-white shadow-sm active:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"

                                    >
                                        {t("GoTitle")}
                                    </button>
                                </Link>

                            </div>
                        })
                    }
                </div>
            }
        </div>



    </>
}
