import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function TermsAndConditions() {

    const { i18n } = useTranslation();

    let lng = i18n.language

    return <>

        <Helmet>
            <meta name='Terms & Conditions' content='Terms & Conditions Couponesta Page' charSet="utf-8" />
            <title>Terms & Conditions Couponesta</title>
        </Helmet>

        <div className="container mx-auto py-20 bg-[#F6F6F6] pt-20 mb-8">
            <div className="text-center">
                <h2 className="mb-4 text-xl md:text-3xl font-bold">{lng != "en" ? "الشروط والأحكام" : "Terms and Conditions"}</h2>
            </div>
        </div>

        {
            lng != "en" ?

                <div className="container mx-auto w-full -translate-y-14 transform rounded-2xl border-2 border-solid border-neutral-100 bg-white p-8 shadow-xl md:w-3/6">
                    <div className="mt-9">
                        <h2 className="mb-3 text-[28px] font-normal">المقدمة</h2>
                        <p className="text-lg font-normal">
                            الشروط والأحكام المكتوبة على هذه الصفحة تدير استخدامك لموقعنا وتطبيقنا. سيتم تطبيق هذه الشروط بالكامل وستؤثر على استخدامك لهذا الموقع الإلكتروني أو تطبيقنا. باستخدام هذا الموقع الإلكتروني أو التطبيق، فإنك توافق على قبول جميع الشروط والأحكام المكتوبة هنا. يجب عليك عدم استخدام هذا الموقع الإلكتروني أو التطبيق إذا لم تكن موافقًا على أي من الشروط والأحكام المنصوص عليها هنا.
                        </p>
                    </div>
                    <div className="mt-9">
                        <h2 className="mb-3 text-[28px] font-normal">حقوق الملكية الفكرية</h2>
                        <p className="text-lg font-normal">
                            بخلاف المحتوى الذي تمتلكه، تمتلك شركة "كوبونستا" بموجب هذه الشروط جميع حقوق الملكية الفكرية والمواد الواردة في هذا الموقع الإلكتروني أو التطبيق الخاص بنا. يتم منحك ترخيص محدود فقط لغرض عرض المواد على هذا الموقع الإلكتروني أو التطبيق.
                        </p>
                    </div>
                    <div className="mt-9">
                        <h2 className="mb-3 text-[28px] font-normal">القيود</h2>
                        <p className="text-lg font-normal">
                            أنت مقيد تعبيرًا وبشكل قاطع من جميع ما يلي:
                        </p>
                        <ul className="mt-12 text-lg font-normal">
                            <li>1. بيع أو ترخيص أو التجارة بأي مواد من الموقع؛</li>
                            <li>2. استخدام هذا الموقع بطريقة تكون أو قد تكون ضارة بالموقع؛</li>
                            <li>3. استخدام هذا الموقع بطريقة تؤثر على وصول المستخدم إلى الموقع؛</li>
                            <li>4. استخدام هذا الموقع بطريقة تتعارض مع القوانين واللوائح السارية، أو بطريقة تسبب أو قد تسبب ضررًا للموقع أو لأي شخص أو كيان تجاري؛</li>
                            <li>5. الانخراط في أي تعدين للبيانات أو جمع للبيانات أو استخراج للبيانات أو أي نشاط مماثل آخر فيما يتعلق بهذا الموقع أو أثناء استخدام هذا الموقع؛</li>
                            <li className="mt-4">يتم تقييد الوصول إلى بعض مناطق هذا الموقع عندك، ويمكننا تقييد وصولك إلى أي مناطق من هذا الموقع في أي وقت، بمحض إرادتنا الوحيدة المطلقة.</li>
                        </ul>
                    </div>
                </div>


                :
                <div className="container mx-auto w-full -translate-y-14 transform rounded-2xl border-2 border-solid border-neutral-100 bg-white p-8 shadow-xl md:w-3/6">
                    <div className="mt-9">
                        <h2 className="mb-3 text-[28px] font-normal">Introduction</h2>
                        <p className="text-lg font-normal">
                            The terms and conditions written on this webpage manage your use of our site and application. These terms will be fully applied and will affect your use of this website or our application. By using this site or application, you agree to accept all terms and conditions written here. You must not use this site or application if you do not agree to any of the terms and conditions set forth here.
                        </p>
                    </div>
                    <div className="mt-9">
                        <h2 className="mb-3 text-[28px] font-normal">Intellectual Property Rights</h2>
                        <p className="text-lg font-normal">
                            Other than the content that you own, under these terms, "Couponesta" Company owns all intellectual property rights and materials contained in this website or our application. You are granted a limited license only for the purpose of displaying material on this website or application.
                        </p>
                    </div>
                    <div className="mt-9">
                        <h2 className="mb-3 text-[28px] font-normal">Restrictions</h2>
                        <p className="text-lg font-normal">
                            You are expressly and emphatically restricted from all of the following:
                        </p>
                        <ul className="mt-12 text-lg font-normal">
                            <li>1. selling, sublicensing and/or otherwise commercializing any Site material;</li>
                            <li>2. using this Site in any way that is, or may be, damaging to this Site;</li>
                            <li>3. using this Site in any way that impacts user access to this Site;</li>
                            <li>4. using this Site contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Site, or to any person or business entity;</li>
                            <li>5. engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Site, or while using this Site;</li>
                            <li className="mt-4">Certain areas of this Site are restricted from access by you, and we may further restrict access by you to any areas of this Site, at any time, in its sole and absolute discretion.</li>
                        </ul>
                    </div>
                </div>

        }

    </>
}
