import React, { useState } from 'react'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import account from '../../Assets/images/account.svg'
import { useQuery } from 'react-query';
import axios from 'axios';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { MutatingDots } from 'react-loader-spinner';

export default function Profile() {

    const { t, i18n } = useTranslation();
    let lng = cookies.get("i18next") || "en"
    let isRtl = document.documentElement.dir === 'rtl';

    const location = useLocation();

    const isActiveProfile = location.pathname === '/profile';
    const isActiveFavorite = location.pathname === '/profile/favorite';
    const isActiveFollow = location.pathname === '/profile/follow';
    const isActiveNotification = location.pathname === '/profile/notification';

    // Account
    let navigate = useNavigate()
    let tokenInUser = localStorage.getItem('refresh_token_website')

    async function getAccountUser() {

        try {

            if (localStorage.getItem('refresh_token_website') != null &&
                localStorage.getItem('refresh_token_website') != undefined &&
                localStorage.getItem('refresh_token_website').trim() != '') {

                try {

                    let response = await axios.get(`${process.env.REACT_APP_BACK_END}user/loggedUser/data`, {
                        headers: {
                            authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token_website')}`,
                        }
                    });

                    return response?.data;

                } catch (error) {

                }

            } else {
                navigate('/login');
            }

        } catch (error) {

        }
    }

    let userDataAccount = useQuery(['userAcc', tokenInUser], () => getAccountUser(), {
        cacheTime: 900,
        refetchInterval: 1000
    })

    let userAccount = {}

    if (userDataAccount?.data?.message === "success") {

        userAccount = userDataAccount?.data?.user[0]
    }

    const formatDate = (isoDate, isRtl) => {
        const date = new Date(isoDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const locale = isRtl ? 'ar' : 'en-US';
        return date.toLocaleDateString(locale, options);
    };



    // dropdown lang
    const [isOpenAcc, setIsOpenAcc] = useState(false);

    const handleMouseEnterPic = () => {
        setIsOpenAcc(true);
    };

    const handleMouseLeavePic = () => {
        setIsOpenAcc(false);
    };


    const ToggleClickPic = () => {
        setIsOpenAcc(!isOpenAcc);
    };

    const toggleModelImg = () => {
        const modalImage = document.getElementById('popup-modal-image');
        modalImage.classList.toggle('hidden');
        modalImage.setAttribute('aria-hidden', modalImage.classList.contains('hidden'));
    }

    // change picture
    const [selectedFile, setSelectedFile] = useState(null);
    const [changeImageLoading, setChangeImageLoading] = useState(false);

    const toggleModelChangeImg = () => {
        const modalChangeImage = document.getElementById('popup-modal-change-image');
        modalChangeImage.classList.toggle('hidden');
        modalChangeImage.setAttribute('aria-hidden', modalChangeImage.classList.contains('hidden'));
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        setChangeImageLoading(true)

        event.preventDefault();
        if (!selectedFile) {
            toast.error(t("no_file_selected") || "No file selected!");
            setChangeImageLoading(false)
            return;
        }


        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}user/${userAccount?._id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: `${process.env.REACT_APP_BEARER_KEY}${tokenInUser}`,
                    },
                }
            );

            setChangeImageLoading(true)

            if (response?.data?.message === 'success') {
                toast.success(t("image_updated_successfully") || "Image updated successfully!");
                await userDataAccount?.refetch();
                setSelectedFile(null)
                toggleModelChangeImg();
                setChangeImageLoading(false)
            } else {
                toast.error(t("failed_to_update_image") || "Failed to update image!");
                setChangeImageLoading(false)
            }
        } catch (error) {
            // console.error("Error updating image", error);
            toast.error(t("error_updating_image") || "Error updating image");
            setChangeImageLoading(false)
        }
    };


    return <>

        {/* large screen */}
        <div className="container mx-auto mt-8 px-3 md:px-5 xl:px-20 pb-6 overflow-auto mb-16 pt-14">

            <div className="flex flex-row justify-between items-start gap-x-4 md:gap-x-14">

                {/* nav bar */}
                <div className="md:basis-1/4 bg-[#d5c9c948] pt-3 pb-8 ps-4 rounded-xl border border-neutral-300 shadow-md">

                    {/* account */}
                    <div className="px-2 mx-auto text-center py-8 relative">
                        <div
                            onMouseEnter={handleMouseEnterPic}
                            onMouseLeave={handleMouseLeavePic}
                            onClick={ToggleClickPic}
                            className="rounded-full bg-neutral-200 p-3 w-fit mx-auto"
                            id="dropdownDividerButton"
                        >
                            <img src={userAccount?.image || account} alt={userAccount?.fullName || "account"} className='flex h-32 w-32 items-center object-cover rounded-full p-1 ltr:mr-1 rtl:ml-1' />
                        </div>

                        {/* Dropdown menu */}
                        {isOpenAcc && (
                            <div
                                id="dropdownDivider"
                                className="z-10 absolute -left-10 bottom-3 translate-x-full mt-0.5 w-42 bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600"
                                onMouseEnter={handleMouseEnterPic}
                                onMouseLeave={handleMouseLeavePic}
                            >
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">
                                    <li>
                                        <button
                                            onClick={toggleModelImg}
                                            data-modal-target="popup-modal-image"
                                            data-modal-toggle="popup-modal-image"
                                            className={`w-full text-start flex justify-start align-middle px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white`}
                                        >
                                            <i className="fa-solid fa-expand text-xl rounded-md me-2 text-blue-700"></i>
                                            <span className='font-semibold'>{t("ViewPicTitle")}</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={toggleModelChangeImg}
                                            data-modal-target="popup-modal-change-image"
                                            data-modal-toggle="popup-modal-change-image"
                                            className={`w-full flex justify-start align-middle text-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white`}
                                        >
                                            <i className="fa-regular fa-hand-pointer text-xl rounded-md me-2 text-blue-700"></i>
                                            <span className='font-semibold'>{t("ChangePicTitle")}</span>
                                        </button>
                                    </li>
                                </ul>

                            </div>
                        )}

                        <h6 className='mb-1 line-clamp-1 text-start text-lg font-bold md:text-center md:text-xl'>{userAccount?.fullName || "couponesta"}</h6>

                        <p className='line-clamp-1 text-start text-sm text-neutral-500 opacity-70 md:text-center'>{isRtl ? `انضم ${formatDate(userAccount.joined, isRtl)}` : `Joined ${formatDate(userAccount.joined, isRtl)}`}</p>
                    </div>

                    <ul className='space-y-2 text-sm transition delay-150 duration-300 ease-in-out'>

                        <Link to={'/profile'}
                            className={`ps-2 border-r-4 cursor-pointer rounded-b-x rounded-l-0 mb-2 ml-2 flex h-12 items-center rounded-l-xl py-3.5 ltr:pl-3 rtl:pr-4 
                                ${isActiveProfile ?
                                    'border-blue-600 text-blue-600 bg-white' :
                                    'hover:border-blue-600 hover:text-blue-600 hover:bg-white text-blue-500 border-transparent'
                                }`}
                        >
                            <i className="fa-solid fa-user-gear text-xl ltr:ps-2 rtl:pe-2"></i>
                            <h5 className="ltr:ml-3 rtl:mr-3 text-base font-medium ms-2">{t("AccountDetailsTitle")}</h5>
                        </Link>

                        <Link to={'/profile/favorite'}
                            className={`ps-2 border-r-4 cursor-pointer rounded-b-x rounded-l-0 mb-2 ml-2 flex h-12 items-center rounded-l-xl py-3.5 ltr:pl-3 rtl:pr-5 
                                ${isActiveFavorite ?
                                    'border-blue-600 text-blue-600 bg-white' :
                                    'hover:border-blue-600 hover:text-blue-600 hover:bg-white text-blue-500 border-transparent'
                                }`}
                        >
                            {/* <i className="fa-solid fa-heart text-xl ltr:ps-2 rtl:pe-2"></i> */}
                            <i className='fa-solid fa-heart fa-xl relative ltr:ps-2 rtl:pe-2 text-xl'>
                                <span className='absolute -top-[18px] ltr:-left-[3px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.favorite?.length || 0}</span>
                            </i>
                            <h5 className="ltr:ml-3 rtl:mr-3 text-base font-medium ms-2">{t("FavoriteCouponsTitle")}</h5>
                        </Link>

                        <Link to={'/profile/follow'}
                            className={`ps-2 border-r-4 cursor-pointer rounded-b-x rounded-l-0 mb-2 ml-2 flex h-12 items-center rounded-l-xl py-3.5 ltr:pl-3 rtl:pr-5
                                ${isActiveFollow ?
                                    'border-blue-600 text-blue-600 bg-white' :
                                    'hover:border-blue-600 hover:text-blue-600 hover:bg-white text-blue-500 border-transparent'
                                }`}
                        >
                            {/* <i className="fa-solid fa-user-gear text-xl ltr:ps-2 rtl:pe-2"></i> */}
                            <i className='fa-solid fa-user-plus fa-xl relative text-xl ltr:ps-2 rtl:pe-2'>
                                <span className='absolute -top-[18px] ltr:-left-[3px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.follow?.length || 0}</span>
                            </i>
                            <h5 className="ltr:ml-3 rtl:mr-3 text-base font-medium ms-2">{t("FavoriteStoreTitle")}</h5>
                        </Link>

                        <Link to={'/profile/notification'}
                            className={`ps-2 border-r-4 cursor-pointer rounded-b-x rounded-l-0 mb-2 ml-2 flex h-12 items-center rounded-l-xl py-3.5 ltr:pl-3 rtl:pr-5
                                ${isActiveNotification ?
                                    'border-blue-600 text-blue-600 bg-white' :
                                    'hover:border-blue-600 hover:text-blue-600 hover:bg-white text-blue-500 border-transparent'
                                }`}
                        >
                            {/* <i className="fa-solid fa-bell text-xl ltr:ps-2 rtl:pe-2"></i> */}
                            <i className='fa-solid fa-bell fa-xl relative text-xl ltr:ps-2 rtl:pe-2'>
                                <span className='absolute -top-[18px] ltr:-left-[3px] rtl:-right-[11px] text-sm text-red-600'>{userAccount?.notification?.length || 0}</span>
                            </i>
                            <h5 className="ltr:ml-3 rtl:mr-3 text-base font-medium ms-2">{t("NotificationsTitle")}</h5>
                        </Link>


                    </ul>

                </div>

                {/* details page */}
                <div className="md:basis-3/4 pt-4 pb-14 ps-4 rounded-2xl border-solid border-neutral-300 shadow-xl p-4 md:border">
                    {<Outlet />}
                </div>

            </div>
        </div>

        {/* small screen */}
        <div className="block xl:hidden container">

        </div>



        {/* image model */}
        <div
            id="popup-modal-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-full max-w-md max-h-full">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-image"
                        onClick={toggleModelImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">{t("closeTitle")}</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">

                        <img src={userAccount?.image || account || ""} alt="" className='w-full h-full p-3 py-14' />



                        <button
                            data-modal-hide="popup-modal-image"
                            type="button"
                            onClick={toggleModelImg}
                            className="absolute bottom-0.5 right-2 py-2 px-4 ms-3 text-md font-medium text-white focus:outline-none bg-teal-500 hover:bg-teal-700 focus:bg-teal-700 rounded-xl border border-gray-200 focus:z-10 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            {t("closeTitle")}
                        </button>
                    </div>

                </div>
            </div>
        </div>

        {/* Change Image Modal */}
        <div
            id="popup-modal-change-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-full max-w-md max-h-full">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-change-image"
                        onClick={toggleModelChangeImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">{t("closeTitle")}</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">
                        <div className="py-10">
                            <label htmlFor="image" className="block mb-4 text-xl font-serif font-bold text-teal-900">{t("UpdatePictureTitle")}</label>
                            <input
                                id="image"
                                name="image"
                                type="file"
                                // accept="image/*"
                                // capture="environment"
                                onChange={handleFileChange}
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            />
                        </div>

                        <div className="flex justify-between items-center w-full me-6">

                            {
                                changeImageLoading ?
                                    <MutatingDots
                                        visible={true}
                                        height="100"
                                        width="100"
                                        color="#cfba2d96"
                                        secondaryColor="#cfba2d96"
                                        radius="12.5"
                                        ariaLabel="mutating-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    /> :
                                    <button
                                        onClick={handleSubmit}
                                        className="text-white inline-flex items-center bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                                    >
                                        {t("UpdatePictureTitle")}
                                    </button>
                            }

                            <button
                                data-modal-hide="popup-modal-change-image"
                                type="button"
                                onClick={toggleModelChangeImg}
                                className="text-white inline-flex items-center bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                {t("closeTitle")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}
