import React from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { BallTriangle } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

export default function DiscoverCategories() {

    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    // slider
    let settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: true,
        pauseOnHover: true,
        autoplaySpeed: 4000,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1521,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                    dots: false

                }
            }
        ]
    }


    let locDetails = JSON.parse(localStorage.getItem('locCouponesta'))

    async function getCategories() {

        let response = await axios.get(`${process.env.REACT_APP_BACK_END}category/${lng}?location=${locDetails._id}`)
        return response
    }

    let categoryData = useQuery(['categories', lng], () => getCategories(), {
        cacheTime: 9000000
    })

    let allCategories = []

    if (categoryData?.data?.data?.message === "success") {

        allCategories = categoryData?.data?.data?.categories
    }


    return <>

        <div className="bg-[#f3f5f8] container w-full py-12 my-10">

            <div className="text-center">
                <p className='mb-5 text-xl font-bold'>{t('DiscoverByCategories')}</p>
                <p className='font-semibold'>{t('SaveWithCouponesta')}</p>
            </div>

            {/* categories */}
            {/* slider */}
            <div className="mt-6 mo:px-8 xl:px-0 slikkk">

                {
                    categoryData.isLoading ? <div className="loading">
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#199A8E"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassNameclassName=""
                            visible={true}
                        />
                    </div>
                        :
                        <Slider
                            className='flex flex-nowrap justify-center items-center'
                            {...settings}
                        >
                            {/* category */}
                            {allCategories.map((category, ind) => {
                                {/* category */ }
                                return <Link
                                    to={`/categories/${category._id}`}
                                    key={ind}
                                    className="grid place-items-center text-center grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 xl:gap-x-16">

                                    <div
                                        style={{ backgroundColor: `${category?.color ? category.color : "#1595AE"}` }}
                                        className='text-white w-fit px-6 py-5 rounded-2xl flex flex-col items-center me-1 ms-12 xl:me-3'
                                    >

                                        <h1 className='text-center mb-5 font-extrabold text-xl font-serif'>{category?.name}</h1>

                                        <img src={category?.image} alt={category?.name} className='w-48 h-48 md:h-40 rounded-2xl' />

                                    </div>

                                </Link>
                            })}

                        </Slider>

                }

            </div >

            <div className="cursor-pointer w-fit mt-5 mx-auto text-center">
                <Link
                    to={'/store'}
                    className="flex items-center text-nowrap border-2 border-neutral-400 rounded-3xl mr-6 py-1 px-3  text-black bg-white hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">
                    <button className='font-semibold'>
                        {t("ViewAllTitle")}
                    </button>
                </Link>
            </div>

        </div>

    </>
}
