import React, { useRef } from 'react'
import Slider from 'react-slick';
import feature from '../../Assets/images/__ملحقات-المنزل_-(1).png'

import { Link } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { BallTriangle } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'

export default function FeaturedStores() {

    const { t } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    // slider
    let settings = {
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        pauseOnHover: true,
        autoplaySpeed: 4000,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1521,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: false,
                    dots: false

                }
            }
        ]
    }

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };


    let locDetails = JSON.parse(localStorage.getItem('locCouponesta'))

    async function getBrand() {

        let response = await axios.get(`${process.env.REACT_APP_BACK_END}brand/${lng}?location=${locDetails._id}`)
        return response
    }

    let BrandData = useQuery(['brands', lng], () => getBrand(), {
        cacheTime: 9000000
    })

    let allBrand = []

    if (BrandData?.data?.data?.message === "success") {

        allBrand = BrandData?.data?.data?.brands
    }

    let isRtl = document.documentElement.dir === 'rtl';

    return <>

        <div className="container px-6 sm:px-6 md:px-10 xl:px-40 mt-2 bg-[#fff] mb-8 mo:py-8 xl:py-10 " >

            <div className="flex flex-row flex-wrap justify-between items-center w-full">
                <h2 className='text-xl font-bold md:text-header-5'>{t("FeaturedStoresTitle")}</h2>

                <div className="flex items-center">
                    <Link
                        to={'/store'}
                        className="flex items-center text-nowrap justify-normal text-center mx-auto cursor-pointer border-2 border-neutral-400 rounded-3xl ltr:mr-6 rtl:me-6 py-1 px-3  text-black bg-white hover:translate-y-1 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] ">
                        <button className='font-semibold'>
                            {t("ViewAllTitle")}
                        </button>
                    </Link>

                    <div className="hidden xl:flex justify-between items-center gap-x-4">

                        <button
                            onClick={previous}
                            className='flex justify-center items-center border-2 border-grey-200 rounded-full w-10 h-10 bg-neutral-200'>
                            <i className={`fa-solid text-xl ${isRtl ? 'fa-angle-right' : 'fa-angle-left'}`}></i>
                        </button>

                        <button
                            onClick={next}
                            className='flex justify-center items-center border-2 border-grey-200 rounded-full w-10 h-10 bg-neutral-200'>
                            <i className={`fa-solid text-xl ${isRtl ? 'fa-angle-left' : 'fa-angle-right'}`}></i>
                        </button>

                    </div>
                </div>

            </div>

            {/* slider */}
            <div className=" bg-[#fff] mt-6 mo:px-8 xl:px-0">

                {
                    BrandData.isLoading ? <div className="loading">
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#199A8E"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassNameclassName=""
                            visible={true}
                        />
                    </div>
                        :

                        <Slider
                            ref={slider => {
                                sliderRef = slider;
                            }}
                            className='flex flex-nowrap justify-center items-center'
                            {...settings}
                        >
                            {/* brand */}
                            {allBrand.map((brand, ind) => {
                                {/* brand */ }
                                return <div key={ind} className="grid place-items-center grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 xl:gap-x-16">

                                    <div className="h-fit pb-4 mt-8 mo:mx-1 md:me-4 rounded-3xl overflow-hidden border border-gray-300">

                                        <Link to={`/store/${brand?._id}`}>
                                            {/* img */}
                                            <div className="w-full h-full">
                                                <img src={`${process.env.REACT_APP_BACK_END}${brand?.category[0]?.image}` || feature} alt="" className='h-[264px] w-full rounded-t-3xl object-fill' />
                                            </div>

                                            {/* brand */}
                                            <div className="flex items-center px-4 py-2">
                                                <img src={brand?.image || brand} alt="" className='h-12 w-12 rounded-full object-cover' />
                                                <h4 className='ml-3 text-body-4 font-medium'>{brand?.name}</h4>
                                            </div>
                                        </Link>

                                    </div>

                                </div>
                            })}

                        </Slider>

                }

                <div className="flex xl:hidden justify-center items-center py-4 gap-x-6">

                    <button
                        onClick={previous}
                        className='flex justify-center items-center border-2 border-grey-200 rounded-full w-10 h-10 bg-neutral-200'>
                        <i className='fa-solid fa-angle-left text-xl'></i>
                    </button>

                    <button
                        onClick={next}
                        className='flex justify-center items-center border-2 border-grey-200 rounded-full w-10 h-10 bg-neutral-200'>
                        <i className='fa-solid fa-angle-right text-xl'></i>
                    </button>

                </div>

            </div >

        </div >


    </>
}
