import React from 'react'
import logo from '../../Assets/images/couponesta.jpg'
import google from '../../Assets/images/google icon.png'
import apple from '../../Assets/images/apple.jpg'
import { Link } from 'react-router-dom'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';

export default function Footer() {

    const { t, i18n } = useTranslation();
    let lng = cookies.get("i18next") || "en"

    let isRtl = i18n.language === "en" ? false : true;

    return <>

        <div className='container px-4 xl:px-32 pt-4 pb-24 mx-auto'>

            <div className="grid place-items-center grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-3">
                <div className='w-full h-full flex-col justify-start items-start align-top px-1'>
                    <Link to={"/"} >
                        <img src={logo} className='w-32 h-20 rounded-2xl' alt="" />
                    </Link>
                    <p className='my-5 text-justify text-neutral-600'>
                        {t("footerDesc")}
                    </p>
                    <div className="flex items-center gap-x-1.5">
                        <Link>
                            <i className="fa-brands fa-instagram"></i>
                        </Link>
                        <Link>
                            <i className="fa-brands fa-x-twitter"></i>
                        </Link>
                        <Link>
                            <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                    </div>
                </div>
                <div className='w-full h-full flex-col justify-start items-start align-text-top px-1'>
                    <h1 className='font-bold mb-20'>{isRtl ? "كوبونستا" : "Couponesta"}</h1>

                    <Link to={'/about'} className='underline underline-offset-2 text-blue-800'>
                        {isRtl ? "عن كوبونستا" : "About Us"}
                    </Link>
                </div>
                <div className='w-full h-full flex flex-col justify-start items-start align-middle px-1'>
                    <h1 className='font-bold mb-20'>{isRtl ? "مساعدة" : "Help"}</h1>

                    <Link to={'/privacy-policy'} className='mb-4 underline underline-offset-2 text-blue-800'>
                        {lng === "en" ? ' Privacy Policy' : 'سياسة الخصوصية '}
                    </Link>

                    <Link to={'/terms-and-conditions'} className=' underline underline-offset-2 text-blue-800'>
                        {lng === "en" ? 'Terms and Conditions' : 'شروط وأحكام'}
                    </Link>

                </div>
                <div className='w-full flex-col justify-start items-center align-middle'>
                    <h1 className='font-bold mb-20'>{isRtl ? "احصل علي التطبيق" : "Get The App"}</h1>

                    <Link>
                        <img src={google} alt="" className='h-14 hover:shadow-2xl rounded-2xl border-2 border-teal-800 ' />
                    </Link>

                    <Link>
                        <img src={apple} alt="" className='h-14 mt-3 hover:shadow-2xl rounded-2xl border-2 border-teal-800 ' />
                    </Link>
                </div>
            </div>

            <p className='mt-8 text-teal-600 font-semibold'>{isRtl ? "كل الحقوق محفوظة لدي كوبونستا 2024 ©" : "@ 2024 Couponesta. All rights reserved"}</p>
        </div>
    </>
}
