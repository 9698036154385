import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import UserContextProvider from './Context/UserContext.js';
import Layout from './components/Layout/Layout.jsx';
import Error from './components/Error/Error.jsx';
import Stores from './components/Stores/Stores.jsx';
import StoreDetails from './components/StoreDetails/StoreDetails.jsx';
import Home from './components/Home/Home.jsx';
import CategoryDetails from './components/CategoryDetails/CategoryDetails.jsx';
import Coupons from './components/Coupons/Coupons.jsx';
import Login from './components/Login/Login.jsx';
import LoginWithEmail from './components/LoginWithEmail/LoginWithEmail.jsx';
import LoginWithPhone from './components/LoginWithPhone/LoginWithPhone.jsx';
import Register from './components/Register/Register.jsx';
import RegisterWithPhone from './components/RegisterWithPhone/RegisterWithPhone.jsx';
import RegisterWithEmail from './components/RegisterWithEmail/RegisterWithEmail.jsx';
import ConfirmationEmail from './components/ConfirmationEmail/ConfirmationEmail.jsx';
import Notification from './components/Notification/Notification.jsx';
import Profile from './components/Profile/Profile.jsx';
import FollowPage from './components/Follow/Follow.jsx';
import ProtectedRoute from './components/CheackUser/CheckUser.jsx';
import FavoritePage from './components/Favorite/Favorite.jsx';
import AccountDetails from './components/AccountDetails/AccountDetails.jsx';
import cookies from 'js-cookie'
import ForgetPassword from './components/ForgetPassword/ForgetPassword.jsx';
import PrivacyPolicy from './components/Privacy_Policy/Privacy_Policy.jsx';
import TermsAndConditions from './components/Terms_And_Conditions/Terms_And_Conditions.jsx';
import About from './components/About/About.jsx';
import NewPassword from './components/NewPassword/NewPassword.jsx';
import ForgetPasswordEmail from './components/ForgetPasswordEmail/ForgetPasswordEmail.jsx';
import ForgetPasswordPhone from './components/ForgetPasswordPhone/ForgetPasswordPhone.jsx';


let routers = createHashRouter([

  {
    path: 'login', element: <Login />, children: [
      { index: true, element: <LoginWithPhone /> },
      { path: 'loginWithEmail', element: <LoginWithEmail /> },
    ],

  },

  {
    path: 'register', element: <Register />, children: [
      { index: true, element: <RegisterWithPhone /> },
      { path: 'registerWithEmail', element: <RegisterWithEmail /> },

    ],
  },


  { path: 'newPassword/:email', element: <NewPassword /> },

  {
    path: 'forgetPassword', element: <ForgetPassword />, children: [
      { index: true, element: <ForgetPasswordEmail /> },
      { path: 'withPhone', element: <ForgetPasswordPhone /> },
    ],

  },

  { path: 'confirmationEmail/:email', element: <ConfirmationEmail /> },

  {
    path: '/', element: <Layout />, children: [
      { index: true, element: <Home /> },

      { path: 'store', element: <Stores /> },

      { path: 'store/:id', element: <StoreDetails /> },

      { path: 'categories/:id', element: <CategoryDetails /> },

      { path: 'coupons/:name', element: <Coupons /> },


      { path: 'privacy-policy', element: <PrivacyPolicy /> },

      { path: 'terms-and-conditions', element: <TermsAndConditions /> },

      { path: 'about', element: <About /> },


      { path: 'notification', element: <ProtectedRoute> <Notification /> </ProtectedRoute> },
      { path: 'favorite', element: <ProtectedRoute> <FavoritePage /> </ProtectedRoute> },
      { path: 'follow', element: <ProtectedRoute> <FollowPage /> </ProtectedRoute> },
      {
        path: 'profile', element: <ProtectedRoute> <Profile /> </ProtectedRoute>, children: [
          { index: true, element: <ProtectedRoute> <AccountDetails /> </ProtectedRoute> },
          { path: 'favorite', element: <ProtectedRoute> <FavoritePage /> </ProtectedRoute> },
          { path: 'follow', element: <ProtectedRoute> <FollowPage /> </ProtectedRoute> },
          { path: 'notification', element: <ProtectedRoute> <Notification /> </ProtectedRoute> },
        ]
      },


      { path: '*', element: <Error /> },
    ]
  }
])


function App() {

  if (cookies.get('i18next') === "dev") {
    cookies.set('i18next', 'en');
  }

  if (localStorage.getItem('locCouponesta') === null || localStorage.getItem('locCouponesta') === "") {

    localStorage.setItem('locCouponesta', {
      name: 'egypt', locationCode: 'eg', image: `${process.env.REACT_APP_BACK_END}uploads/location/tF0aJkSKylDvsMDo0Twk1_eg.jpg`
    });
  }


  return <UserContextProvider>

    <RouterProvider router={routers}></RouterProvider>

  </UserContextProvider>

}

export default App;
