import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locale/en.json'
import translationAr from './locale/ar.json'

const resources = {
    en: {
        translation: translationEN
    },
    ar: {
        translation: translationAr
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en', // default language
        supportedLang: ['en', 'ar'],
        detection: {
            order: ['cookie', 'htmlTag', 'path', 'localStorage', 'subdomain'],
            caches: ['cookie']
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        },

        react: {
            useSuspense: false
        }
    });


export default i18n;