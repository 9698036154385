import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FallingLines } from 'react-loader-spinner'
import { UserContext } from '../../Context/UserContext.js';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function LoginWithEmail() {

    const { t, i18n } = useTranslation();

    let { setRefresh_token_website, setUserData } = useContext(UserContext)
    let navigate = useNavigate()
    const [error, setError] = useState(null)
    const [logging, setLogging] = useState(false)


    async function submitLogin(value) {

        try {

            setLogging(true)

            let { data } = await axios.post(`${process.env.REACT_APP_BACK_END}auth/loginEmail`, value)
                .catch((err) => {
                    setError(err.response.data.message)
                    setLogging(false)
                })

            if (data.message === 'success') {
                setLogging(false)
                localStorage.setItem('refresh_token_website', data.refresh_token)
                setRefresh_token_website(data.refresh_token)
                setUserData(data.refresh_token)

                toast.success(t("LoginSuccessTitle"))

                navigate('/')
            }

        } catch (error) {

        }

    }

    let validateSchema = Yup.object({
        email: Yup.string().email('email is invalid').required('email is required'),
        password: Yup.string().min(6, 'Password must with character and minLength 6').required('password is required'),
    })

    let formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        }, validationSchema: validateSchema,
        onSubmit: submitLogin
    })


    // to show text whe type password
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    return <>

        <Helmet>
            <meta name='description' content='Register With Email Page' charSet="utf-8" />
            <title>Register With Email</title>
        </Helmet>

        <div className="px-2">

            <form className="space-y-5" onSubmit={formik.handleSubmit}>

                <div>
                    <label htmlFor="email" className="block text-sm font-bold leading-6 text-gray-900 cursor-pointer">
                        {t("EmailTitle")} <span className='text-red-600 font-extrabold'>*</span>
                    </label>
                    <div className="mt-2">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            id='email'
                            name='email'
                            type='email'
                            autoComplete="email"
                            className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                        />
                        {formik.errors.email && formik.touched.email ? <div className="py-3 mt-1.5 px-2 md:px-6 bg-red-400 text-red-100 rounded-3xl">{formik.errors.email}</div> : ''}

                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-bold  leading-6 text-gray-900 cursor-pointer">
                            {t("PasswordTitle")} <span className='text-red-600 font-extrabold'>*</span>
                        </label>
                        <div className="text-sm">
                            <Link to={'/forgetPassword'} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                {t("ForgotPassTitle")}
                            </Link>
                        </div>
                    </div>
                    <div className="mt-2 relative">

                        <i
                            className={`absolute ltr:right-3 rtl:left-4 top-4 ${showPassword ? "fa-solid fa-eye text-gray-700 cursor-pointer"
                                : "fa-solid fa-eye-slash cursor-pointer"}`}
                            onClick={togglePasswordVisibility}
                        ></i>

                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            className="block w-full rounded-3xl text-sm xl:text-base border-[1px] border-gray-400 p-3 px-2 md:px-6 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                        />
                        {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 md:px-6 bg-red-400 text-red-100 rounded-3xl">{formik.errors.password}</div> : ''}

                    </div>
                </div>

                {logging ? <button type='button' className='flex w-full justify-center rounded-3xl'>

                    <FallingLines
                        width="50"
                        radius="9"
                        color="#3b35b4"
                        visible={true}
                        ariaLabel="falling-circles-loading"
                    />
                </button> :
                    <div>
                        <button

                            // disabled
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="hover:translate-y-1 border-[1px] border-gray-300 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] flex w-full justify-center cursor-pointer rounded-3xl bg-indigo-600 p-3 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-indigo-400 disabled:text-gray-200 text-white shadow-sm active:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {t("SingInTitle")}
                        </button>
                    </div>
                }

                {error ? <div className="py-3 px-2 bg-red-400 text-red-50 rounded-3xl text-center mx-auto">{error}</div> : ''}



            </form>

        </div>
    </>

}
